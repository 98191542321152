import { nameBodyTemplate } from "@/utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { JDashboardDestination } from "../Destinations/types";
import { useDestinationsContext } from "../Destinations";
import { Checkbox } from "primereact/checkbox";
import { useEffect } from "react";
import { useField } from "formik";
import React from "react";

const HealthCheckRecipientsNotMemo = () => {
  const { destinations, getDestinations } = useDestinationsContext();

  useEffect(() => {
    getDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const healthCheckBodyTemplate = ({ id }: JDashboardDestination) => {
    return (
      <div key={id} className="flex gap-4">
        <div className="flex gap-2">
          <CheckboxFieldMemo id={id} />
        </div>
      </div>
    );
  };

  return (
    <div className="max-h-[360px] overflow-auto rounded border border-[--color-dark]">
      <DataTable showHeaders={false} sortField="name" sortOrder={1} value={destinations as JDashboardDestination[]}>
        <Column className="pr-0" key={"id"} body={healthCheckBodyTemplate}></Column>
        <Column sortable field="name" header="Name" body={nameBodyTemplate}></Column>
        {/* 
        <Column sortable field="_dateOfBirth" header="Birthday" body={birthdayCellRender}></Column>
        <Column sortable field="_anniversaryDate" header="Anniversary" body={anniversaryCellRender}></Column>
        <Column sortable field="role" header="Role"></Column>
        <Column sortable field="project" header="Project"></Column> 
        */}
      </DataTable>
    </div>
  );
};

export default React.memo(HealthCheckRecipientsNotMemo);

export const CheckboxField = ({ id }: { id: string }) => {
  const [input] = useField({
    name: "destinationIds",
    type: "checkbox",
    value: id,
  });
  return <Checkbox {...input} checked={!!input.checked} />;
};

const CheckboxFieldMemo = React.memo(CheckboxField);
