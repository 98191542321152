import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import { ModalOperationType } from "../../types";
import { toast } from "react-toastify";
import JInputText from "../../base/JInputText";
import { errorOrDefault, getFormikFromRef } from "@/utils";
import JDropdown from "@/base/JDropdown/JDropdown";
import { TalkType } from "./types";
import { talkCreateSchema } from "../../schemas";
import { useTalksContext } from "./TalksContext";

type FormikValues = {
  title: string;
  type: TalkType;
};

const TalkAddModal = ({ onClose }: { onClose: () => void; formType?: ModalOperationType }) => {
  const { addTalk, isFetching } = useTalksContext();

  const formikRef = useRef(null);

  const onSubmit = async (values: FormikValues) => {
    try {
      await addTalk({ ...values });
      toast.success("Talk added");
      onClose();
      return;
    } catch (err) {
      console.error(err);
      toast.error(errorOrDefault(err, "Error adding new talk"));
    }
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        label={"Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = getFormikFromRef<FormikValues>(formikRef);
          formik.submitForm();
        }}
        loading={isFetching}
      />
    </div>
  );

  return (
    <Formik<FormikValues>
      innerRef={formikRef}
      initialValues={{
        title: "",
        type: TalkType.TTN,
      }}
      validationSchema={talkCreateSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Dialog header={"Create talk"} visible={true} onHide={onClose} footer={footerContent}>
          <Form>
            <div className="py-4">
              <JInputText id="title" title="Title" className="w-full" />

              <JDropdown
                id="type"
                title="Type"
                options={[
                  { label: "Tracking Number", value: TalkType.TTN },
                  { label: "Health Check", value: TalkType.HEALTH_CHECK },
                  { label: "Other", value: TalkType.OTHER },
                ]}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  props.setFieldValue("type", e.value);
                }}
              />
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default TalkAddModal;
