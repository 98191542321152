import { imageCellRender } from "@/utils";
import { HealthCheck, HealthCheckAnswerOptions } from "./HealthCheck.types";

export const moodToEmojiMap: Record<HealthCheckAnswerOptions | string, string> = {
  awful: "😩",
  bad: "😟",
  normal: "😐",
  good: "😊",
  awesome: "😁",
};

const MAX_RECIPIENTS_TO_SHOW = 10;

export const upcomingHealthCheckFor7Days = ({ allHealthChecks }: { allHealthChecks?: HealthCheck[] | null }) => {
  if (!allHealthChecks) return [];

  const upcomingHealthChecks: {
    title: string;
    date: string;
  }[] = [];

  for (let i = 1; i < 7; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    const day = date.getDay();
    const healthCheck = allHealthChecks.find((hc) => hc.days.includes(day));
    if (healthCheck && healthCheck.isActive) {
      upcomingHealthChecks.push({
        title: healthCheck.title,
        date: date.toDateString(),
      });
    }
  }

  return upcomingHealthChecks;
};

export const recipientAvatarsBody = ({ destinations }: HealthCheck) => {
  let i = 0;
  return (
    <div className="flex items-center">
      {destinations.map((destination) => {
        const { imageUrl, name } = destination?.destinationData || {};
        i += 1;

        if (i > MAX_RECIPIENTS_TO_SHOW) {
          return null;
        }

        if (i === MAX_RECIPIENTS_TO_SHOW) {
          return (
            <div
              style={{
                zIndex: i,
                position: "relative",
                marginLeft: i > 1 ? "-10px" : "0",
              }}
              key={destination.id}
            >
              {imageCellRender({
                counter: `+${destinations.length}`,
              })}
            </div>
          );
        }

        return (
          <div
            style={{
              zIndex: i,
              position: "relative",
              marginLeft: i > 1 ? "-10px" : "0",
            }}
            key={destination.id}
          >
            {imageCellRender({
              imageUrl,
              name,
            })}
          </div>
        );
      })}
    </div>
  );
};
