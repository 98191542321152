import AutocompleteDropdown from "@/base/AutocompleteDropdown";
import { DropdownOption } from "@/types";
import { useDestinationsContext } from "../containers/Destinations";
import { useCallback, useMemo } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Field, FieldInputProps, FieldMetaProps } from "formik";

interface Props {
  setChannelData: (channelData: { channelId: string; channelTitle: string; description: string }) => void;
  initialChannelData: {
    channelId: string;
    channelTitle?: string;
    description?: string;
  };
  currentChannelId?: string;
  id?: string;
}

export default function SlackChannelAutocompleteInput({
  setChannelData,
  initialChannelData,
  currentChannelId,
  id,
}: Props) {
  const { channels, isFetching } = useDestinationsContext();

  const fallbackChannelTitle = useMemo(() => {
    return channels.find((channel) => channel.id === currentChannelId)?.name ?? "";
  }, [channels, currentChannelId]);

  const mappedChannels = useMemo(
    () => [
      ...channels.map((channel) => ({
        id: channel.id,
        name: channel.name,
      })),
      ...(currentChannelId
        ? [
            {
              id: currentChannelId,
              name: initialChannelData?.channelTitle ?? (fallbackChannelTitle || ""),
            },
          ]
        : []),
    ],
    [channels, currentChannelId, fallbackChannelTitle, initialChannelData?.channelTitle]
  );

  const currentOption = useMemo(
    () =>
      currentChannelId
        ? mappedChannels.find((channel) => channel.id === currentChannelId)
        : {
            id: "",
            name: "",
          },
    [currentChannelId, mappedChannels]
  );

  const handleSetValue = useCallback(
    (value: DropdownOption) => {
      const curChannel = channels.filter((searchChannel) => searchChannel.id === value.id)[0];
      setChannelData({
        channelId: value.id,
        channelTitle: value.name,
        description: curChannel?.purpose?.value ?? "",
      });
    },
    [channels, setChannelData]
  );

  if (isFetching) {
    return <ProgressSpinner />;
  }

  return (
    <Field name={id}>
      {({ meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
        <>
          <AutocompleteDropdown
            classes={{
              root: "w-full",
              input: "w-full",
            }}
            value={currentOption as DropdownOption}
            setValue={handleSetValue}
            options={mappedChannels}
          />
          {meta.touched && meta.error && <sub className="p-1 text-pink-600">{meta.error}</sub>}
        </>
      )}
    </Field>
  );
}
