import React from "react";

interface Props {
  row1: React.ReactNode;
  row2?: React.ReactNode;
}

export default function PageTitleWrapper({ row1, row2 }: Props) {
  return (
    <div
      className="mb-4 flex w-full justify-between overflow-hidden"
      style={{
        display: "grid",
        gridAutoColumns: "1fr max-content",
        gridAutoFlow: "column",
      }}
    >
      <div
        className="size-full gap-6 overflow-hidden"
        style={{
          display: "grid",
          gridAutoColumns: "max-content 1fr",
          gridAutoFlow: "column",
        }}
      >
        {row1}
      </div>
      {row2 && <div className="flex flex-col items-end justify-end gap-2">{row2}</div>}
    </div>
  );
}
