import { Column } from "primereact/column";
import { confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { useCallback, useEffect, useState } from "react";
import { useDashboardContext } from "../DashboardContext";
import { useNotificationsContext } from "../../Notifications/NotificationContext";
import { EventType, JTodayGreetings } from "../../../types";
import { imageCellRender } from "../../../utils";
import NotificationEditModal from "./NotificationEditModal/NotificationEditModal";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import clsx from "clsx";
import EventTypeIcon from "@/components/EventTypeIcon";
import { useDestination } from "@/containers/Destinations";

function getDateFromApproxDate(details?: string) {
  const approxStart = details?.match(/DETAILS>>>(approx start (.*?)),/)?.[2];
  const date = approxStart ? new Date(approxStart).toLocaleString() : null;

  if (!date || !approxStart) return null;

  return date;
}

function sortByApproxDate(a: JTodayGreetings, b: JTodayGreetings) {
  const aDate = getDateFromApproxDate(a.details);
  const bDate = getDateFromApproxDate(b.details);

  if (a.status === "DECLINED" || a.status === "FAILED" || a.status === "WAITING") return -1;

  if (!aDate || !bDate) return 0;

  return aDate > bDate ? -1 : 1;
}

const PlannedNotifications = ({ notifications }: { notifications: JTodayGreetings[] }) => {
  const { cancelNotification } = useNotificationsContext();
  const { getDashboard } = useDashboardContext();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { getSlackChannelsInfo, channels } = useDestination();

  useEffect(() => {
    getSlackChannelsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const [item, setItem] = useState<JTodayGreetings | null>(null);

  const confirmCancel = (id: string, channelId: string) => {
    const confirmationMessageRef = document.getElementById("confirmMessage");
    if (!confirmationMessageRef) {
      console.error("No confirmationMessageRef found");
      return;
    }

    confirmPopup({
      target: confirmationMessageRef,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        await cancelNotification(id, channelId);
        await getDashboard();
      },
      reject: () => null,
    });
  };

  const statusBodyTemplate = ({ status }: { status: string }) => {
    return <span>{status}</span>;
  };

  const destinationBodyTemplate = (rawData: JTodayGreetings) => {
    const { email, name, event } = rawData;

    if (!event || !rawData) {
      return null;
    }

    if (rawData?.event?.type === EventType.GLOBAL) {
      return <div className="flex gap-2">{rawData.event.title}</div>;
    }

    if (rawData.isGrouped) {
      const peopleToNotify = rawData.details?.match(/GROUP>>>(.*?)<<<GROUP/)?.[1] ?? "";
      const peopleToNotifyList = peopleToNotify.split(", ");

      return (
        <div className="flex flex-col gap-1">
          {peopleToNotifyList.map((person) => (
            <span>{person}</span>
          ))}
        </div>
      );
    }

    if (email && name) {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            {imageCellRender(rawData)}
            <a href={`mailto:${email}`}>{name}</a>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">{rawData.event.description}</div>
      </div>
    );
  };

  const eventBodyTemplate = (rawData: JTodayGreetings) => {
    const { event } = rawData;
    return (
      <>
        <EventTypeIcon eventType={event.type} personalEventType={event.personalizedEventType} />
      </>
    );
  };

  const channelsBodyTemplate = (rawData: JTodayGreetings) => {
    const isRebroadcastOnly = rawData.rebroadcastNotifyOnly;
    const rebroadcastId = rawData.rebroadcastSlackChannelId;
    const rebroadcastChannelTitle = channels.find((channel) => channel.id === rebroadcastId)?.name;

    if (isRebroadcastOnly && rebroadcastId) {
      return <>{rebroadcastChannelTitle}</>;
    }

    if (rebroadcastId) {
      return (
        <div className="flex flex-col gap-2">
          <div className="">{rawData.channelTitle}</div>
          <div className="">{rebroadcastChannelTitle}</div>
        </div>
      );
    }

    return <>{rawData.channelTitle}</>;
  };

  const messageTitleBodyTemplate = ({
    messageTitle,
    customMessageContent,
    resourceUrlMin,
  }: {
    resourceUrlMin?: string;
    messageTitle: string;
    customMessageContent?: string;
  }) => {
    const asset = resourceUrlMin ? (
      <Image src={resourceUrlMin} preview />
    ) : (
      // eslint-disable-next-line tailwindcss/no-custom-classname
      <div className="gif-placeholder"></div>
    );

    let message = <>{messageTitle}</>;

    if (customMessageContent) {
      message = (
        <>
          <div className="font-bold">Custom message</div>
          <div className="text-sm">
            {customMessageContent?.length > 30 ? customMessageContent.slice(0, 30) + "..." : customMessageContent}
          </div>
        </>
      );
    }
    return (
      <div className="flex items-center gap-2">
        <div className="flex size-[50px] items-center overflow-hidden rounded-full">{asset}</div>
        <div className="">{message}</div>
      </div>
    );
  };

  const dateBodyTemplate = ({ details }: { details: string }) => {
    const date = getDateFromApproxDate(details);
    return <>{date && <div className="text-sm">{date}</div>}</>;
  };

  const operationsBodyTemplate = (rawData: JTodayGreetings) => {
    const disabled = !["PLANNED", "WAITING"].includes(rawData.status);
    return (
      <div className="flex gap-4 ">
        <Edit
          className={clsx("", disabled ? "opacity-50" : "cursor-pointer")}
          onClick={() => !disabled && setItem(rawData)}
        />
        <Trash className={clsx("", "cursor-pointer")} onClick={() => confirmCancel(rawData.id, rawData.channelId)} />
      </div>
    );
  };

  const sortNotifs = useCallback((notifs: JTodayGreetings[]): JTodayGreetings[] => {
    return notifs
      .map((n) => ({
        ...n,
        approxStart: getDateFromApproxDate(n.details),
      }))
      .sort(sortByApproxDate)
      .reverse();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex cursor-pointer items-center gap-4" onClick={handleCollapse}>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <span className={clsx("pi !text-[var(--color-orange)]", isCollapsed ? "pi-plus" : "pi-minus")} />
        <span className="font-bold">Today's events</span>
      </div>
      {!isCollapsed && (
        <DataTable
          sortOrder={1}
          value={sortNotifs(notifications)}
          sortMode="single"
          sortField="event.type"
          scrollable
          scrollHeight="400px"
        >
          <Column sortable header="Team member(s)/Title" body={destinationBodyTemplate}></Column>
          <Column field="status" header="Status" body={statusBodyTemplate}></Column>
          <Column sortable field="approxStart" header="Notify at" body={dateBodyTemplate}></Column>
          <Column header="Message" body={messageTitleBodyTemplate}></Column>
          <Column header="Channel" field="channelTitle" body={channelsBodyTemplate}></Column>
          <Column header="Event" body={eventBodyTemplate}></Column>
          <Column
            header="Grouped"
            field="isGrouped"
            body={(rawData) => {
              return rawData?.isGrouped ? "Yes" : "No";
            }}
          ></Column>
          <Column header="Operations" body={operationsBodyTemplate}></Column>
        </DataTable>
      )}
      {!!item && <NotificationEditModal item={item} onClose={() => setItem(null)} cb={getDashboard} />}
    </div>
  );
};

export default PlannedNotifications;
