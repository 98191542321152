import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import useHealthCheckContext from "./useHealthCheck";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HealthCheckNotification, HealthCheckNotificationDetailed, HealthCheckStatus } from "./HealthCheck.types";
import { ModalOperationType } from "@/types";
import Settings from "@/assets/edit-settings.svg?react";
import Trash from "@/assets/trash.svg?react";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";
import HealthCheckNotificationEdit from "./HealthCheckNotificationEdit";
import { Dialog } from "primereact/dialog";
import { IconSend } from "@tabler/icons-react";
import { IconEye } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import HealthCheckNotificationsReport from "./HealthCheckNotificationsReport";
import Loader from "@/base/Loader";
import { Tooltip } from "primereact/tooltip";
import Status from "@/components/Status";

type Props = {
  filterStatus?: HealthCheckStatus[];
  healthCheckId?: string;
};

export default function HealthCheckNotificationsList({ filterStatus, healthCheckId }: Props) {
  const {
    allHealthCheckNotifications,
    getHealthCheckNotifications,
    notificationUpdate,
    sendHealthCheckMessages,
    deleteHealthCheckNotification,
    isFetching,
  } = useHealthCheckContext();
  const [showModal, setShowModal] = useState<ModalOperationType>(null);
  const [curModalData, setCurModalData] = useState<HealthCheckNotificationDetailed | null>(null);
  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState<HealthCheckNotificationDetailed | null>(null);

  useEffect(() => {
    getHealthCheckNotifications(filterStatus);
  }, [filterStatus, getHealthCheckNotifications]);

  const handleModalClose = () => {
    setShowModal(null);
    getHealthCheckNotifications();
  };

  const formatTimeBody = useCallback(
    (start?: boolean) =>
      ({ startAt, endAt }: HealthCheckNotification) => {
        const time = start ? startAt : endAt;
        if (!time) {
          return null;
        }
        return new Date(time).toLocaleString();
      },
    []
  );

  const controlBodyTemplate = useCallback(
    (healthCheckNotification: HealthCheckNotificationDetailed) => {
      const showEdit = healthCheckNotification.status === HealthCheckStatus.PLANNED;
      const showCancel = [HealthCheckStatus.PLANNED, HealthCheckStatus.ACTIVE].includes(healthCheckNotification.status);
      const showReport = [HealthCheckStatus.ACTIVE, HealthCheckStatus.COMPLETED, HealthCheckStatus.CANCELED].includes(
        healthCheckNotification.status
      );
      const showSend = [HealthCheckStatus.PLANNED, HealthCheckStatus.ACTIVE].includes(healthCheckNotification.status);
      const showDelete = [HealthCheckStatus.COMPLETED, HealthCheckStatus.CANCELED].includes(
        healthCheckNotification.status
      );

      return (
        <div className="group flex items-center gap-4">
          {showEdit && (
            <>
              <Tooltip target="#edit" position="bottom" content="Edit" />
              <Settings
                id="edit"
                className="cursor-pointer"
                onClick={() => {
                  setCurModalData(healthCheckNotification);
                  setTimeout(() => setShowModal("update"), 100);
                }}
              />
            </>
          )}
          {showCancel && (
            <>
              <Tooltip target="#cancel" position="bottom" content="Cancel" />
              <IconX
                id="cancel"
                className="size-[16px] cursor-pointer text-[var(--color-fg-darker)]"
                onClick={async () => {
                  if (window.confirm("Are you sure you want to cancel this notification?")) {
                    try {
                      await notificationUpdate(healthCheckNotification.id, {
                        status: HealthCheckStatus.CANCELED,
                        endAt: new Date().toISOString(),
                      });
                      toast.success("Notification canceled");
                      await getHealthCheckNotifications();
                    } catch (e) {
                      toast.error(errorOrDefault(e, "Error canceling notification"));
                    }
                  }
                }}
              />
            </>
          )}
          {showReport && (
            <>
              <Tooltip target="#showReport" position="bottom" content="Show report" />
              <IconEye
                id="showReport"
                className="size-[16px] cursor-pointer text-[var(--color-fg-darker)]"
                onClick={() => {
                  setReportData(healthCheckNotification);
                  setTimeout(() => setShowReport(true), 100);
                }}
              />
            </>
          )}
          {showDelete && (
            <>
              <Tooltip target="#delete" position="bottom" content="Delete" />
              <div id="send">
                <Trash
                  id="delete"
                  className="cursor-pointer"
                  onClick={async () => {
                    if (window.confirm("Are you sure you want to delete this notification?")) {
                      try {
                        await deleteHealthCheckNotification(healthCheckNotification.id);
                        toast.success("Notification deleted");
                        await getHealthCheckNotifications();
                      } catch (e) {
                        toast.error(errorOrDefault(e, "Error deleting notification"));
                      }
                    }
                  }}
                />
              </div>
            </>
          )}
          {showSend && (
            <>
              <Tooltip target="#send" position="bottom" content="Send notification" />
              <div id="send">
                <IconSend
                  className="size-[16px] cursor-pointer text-[var(--color-fg-darker)] group-hover:visible"
                  onClick={async () => {
                    try {
                      await sendHealthCheckMessages(healthCheckNotification.id);
                      toast.success("Messages sent successfully");
                      await getHealthCheckNotifications();
                    } catch (e) {
                      toast.error(errorOrDefault(e, "Error sending messages"));
                    }
                  }}
                ></IconSend>
              </div>
            </>
          )}
        </div>
      );
    },
    [deleteHealthCheckNotification, getHealthCheckNotifications, notificationUpdate, sendHealthCheckMessages]
  );

  const statusBody = useCallback(({ status }: { status: HealthCheckStatus }) => {
    return <Status status={status} />;
  }, []);

  const filteredNotifications = useMemo(() => {
    if (healthCheckId) {
      return allHealthCheckNotifications?.filter((notification) => notification.healthCheck.id === healthCheckId);
    }

    if (filterStatus) {
      return allHealthCheckNotifications?.filter((notification) => filterStatus.includes(notification.status));
    }

    return allHealthCheckNotifications;
  }, [allHealthCheckNotifications, filterStatus, healthCheckId]);

  if (!isFetching && !filteredNotifications) {
    return null;
  }

  return (
    <div>
      <Loader isLoading={isFetching} />
      <DataTable
        sortField="createdAt"
        sortOrder={-1}
        value={filteredNotifications || []}
        emptyMessage={isFetching ? "Loading..." : "No notification yet"}
      >
        {/* <Column field="healthCheck.title" header="Title"></Column> */}
        <Column className="max-w-[300px]" field="welcomeMessage" header="Message"></Column>
        <Column field="startAt" header="Start At" body={formatTimeBody(true)}></Column>
        <Column field="endAt" header="End At" body={formatTimeBody()}></Column>
        {/* <Column header="Recipients/Answered" body={recipientsBody}></Column> */}
        <Column field="status" header="Status" body={statusBody}></Column>
        <Column header="" body={controlBodyTemplate}></Column>
      </DataTable>
      {showModal === "update" && curModalData && (
        <Dialog header="Edit notification" visible={true} onHide={handleModalClose}>
          <HealthCheckNotificationEdit notification={curModalData} onClose={handleModalClose} />
        </Dialog>
      )}
      {showReport && reportData && (
        <Dialog header="Notification report" visible={true} onHide={() => setShowReport(false)}>
          <HealthCheckNotificationsReport reportData={reportData} />
        </Dialog>
      )}
    </div>
  );
}
