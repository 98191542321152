import { Link, Navigate, Outlet } from "react-router-dom";
import "./Layout.scss";
import { useUserContext } from "../../Users/UserContext";
import TopBgElem from "./topBgElem.svg?react";
import BottomBgElem from "./bottomBgElem.svg?react";
import LogoIcon from "@/assets/logo-icon.svg?react";
import Logo from "@/assets/logo.svg?react";

const LayoutAuth = () => {
  const { user } = useUserContext();

  const currentYear = new Date().getFullYear();

  return user ? (
    // <Navigate to={state?.returnUrl || "/"} replace />
    <Navigate to={"/"} replace />
  ) : (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="main-app relative flex h-full min-h-screen flex-col overflow-hidden bg-[--color-bg]">
      <div className="z-10 flex w-full items-center justify-center p-4">
        <Link to={`/`}>
          <Logo />
        </Link>
      </div>
      <div className="z-10 flex  flex-1 items-center justify-center">
        <div
          style={{ backdropFilter: "blur(4px)" }}
          className="flex size-[400px] flex-col bg-[#1F1B1C]/80 px-[60px] py-[50px]"
        >
          <Outlet />
        </div>
      </div>
      <div className="z-10 flex items-center justify-start gap-4 p-4 text-sm text-[--color-fg] ">
        <LogoIcon /> © {currentYear} JoeyBot / Terms & Conditions / Privacy Policy
      </div>
      <TopBgElem className="pointer-events-none absolute left-0 top-0 z-0" />
      <BottomBgElem className="pointer-events-none absolute bottom-0 right-0 z-0" />
    </div>
  );
};

export default LayoutAuth;
