import JInputText from "@/base/JInputText";
import Loader from "@/base/Loader";
import { Formik } from "formik";
import NavigationButtons from "./NavigationButtons";
import { useUserContext } from "@/containers/Users/UserContext";
import { useContext, useEffect, useState } from "react";
import { OrganizationSettingKey } from "@/types";
import { context } from "./SetupWizard";
import {
  batchOrgSettingsUpdate,
  getNotificationsTimeInitialValues,
  setChannelNotificationsValues,
  setNotificationsTimeValues,
} from "@/containers/Settings/utils";
import SlackChannelAutocompleteMultipleSelectInput from "@/components/SlackChannelAutocompleteMultipleInput";
import { useDestinationsContext } from "@/containers/Destinations";

export default function SlackChannelsAndNotificationTime() {
  const { setModelTitle } = useContext(context);
  const [isLoading, setIsLoading] = useState(false);
  const {
    saveOrgSettings,
    getUserData,
    orgSettings,
    addChannel,
    deleteChannel,
    channels,
    isChannelFetching,
    getChannels,
  } = useUserContext();
  const { channels: slackChannels } = useDestinationsContext();
  const [channelsIds, setChannelsIds] = useState<string[]>(channels.map((c) => c.channelId) || []);

  const {
    schedule_notification_from_hour: orgFromHour,
    schedule_notification_from_minute: orgFromMin,
    schedule_notification_to_hour: orgToHour,
    schedule_notification_to_minute: orgToMin,
    schedule_notification_update_hour: orgUpdateHour,
    schedule_notification_update_minute: orgUpdateMin,
  } = orgSettings;

  useEffect(() => {
    setModelTitle("Notification time");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveChanges = async (formValues: {
    schedule_notification_from: string;
    schedule_notification_to: string;
    schedule_notification_update: string;
  }) => {
    const { schedule_notification_from, schedule_notification_to, schedule_notification_update } = formValues;

    const notificationsValues = setNotificationsTimeValues({
      schedule_notification_from,
      schedule_notification_to,
      schedule_notification_update,
    });

    const values: Record<OrganizationSettingKey, string> = { ...notificationsValues } as Record<
      OrganizationSettingKey,
      string
    >;

    await batchOrgSettingsUpdate({
      getUserData,
      setIsLoading,
      saveOrgSettings,
      values,
      orgSettings,
      showSuccessToast: false,
    });

    const channelNotificationValue = setChannelNotificationsValues({
      schedule_notification_from,
      schedule_notification_to,
      schedule_notification_update,
    });

    for (const channel of channels) {
      // delete channel if it's not in the selected channels
      if (!channelsIds.includes(channel.channelId)) {
        await deleteChannel(channel.id);
      }
    }

    for (const channelId of channelsIds) {
      const channelData = slackChannels.find((c) => c.id === channelId);

      if (!channelData) {
        continue;
      }

      if (channels.find((c) => c.channelId === channelId)) {
        continue;
      }

      // add channel if it's not in the selected channels
      await addChannel({
        channelId,
        channelTitle: channelData.name,
        isNotificationsGrouped: false,
        managers: [],
        useTemplatesForGroupCongrats: false,
        ...channelNotificationValue,
      });
    }
  };

  return (
    <>
      <Loader isLoading={isLoading || isChannelFetching} />
      <Formik
        initialValues={{
          ...getNotificationsTimeInitialValues({
            orgFromHour,
            orgFromMin,
            orgToHour,
            orgToMin,
            orgUpdateHour,
            orgUpdateMin,
          }),
        }}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.schedule_notification_from) {
            errors.schedule_notification_from = "Required";
          }
          if (!values.schedule_notification_to) {
            errors.schedule_notification_to = "Required";
          }
          if (!values.schedule_notification_update) {
            errors.schedule_notification_update = "Required";
          }
          return errors;
        }}
        onSubmit={handleSaveChanges}
        enableReinitialize
      >
        {({ submitForm, isValid }) => (
          <>
            <Loader isLoading={isLoading} />
            <div className="flex flex-col gap-2 font-bold">
              <label>
                Select Channels <span className="text-red-500">*</span>
              </label>
              <SlackChannelAutocompleteMultipleSelectInput
                onChange={(option) => {
                  setChannelsIds(option.map((o) => o.id));
                }}
                currentChannelIds={channelsIds}
              />
              <span className="flex items-center gap-2 font-normal text-[--color-fg-darkest]">
                <span className="flex size-[16px] items-center justify-center rounded-full bg-[--color-orange] p-2 text-[8px] font-bold text-black">
                  i
                </span>
                JoeyBot will be able to send messages to the chosen Slack channels
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex gap-4">
                <div className="flex flex-col gap-4">
                  <label className="font-bold">
                    Show notifications <span className="text-red-500">*</span>
                  </label>
                  <div className="flex gap-4">
                    <div className="flex flex-col gap-2">
                      <label className="text-[--color-fg-darker]">From</label>
                      <JInputText id="schedule_notification_from" title="" className="" type="time" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className="text-[--color-fg-darker]">To</label>
                      <JInputText id="schedule_notification_to" title="" className="" type="time" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-4">
                  <label className="font-bold">
                    Update notifications queue at <span className="text-red-500">*</span>
                  </label>
                  <div className="flex flex-col justify-between gap-2">
                    <label className="text-[--color-fg-darker]"> </label>
                    <JInputText id="schedule_notification_update" title="" className="" type="time" />
                  </div>
                </div>
              </div>
              <span className="flex items-center gap-2 font-normal text-[--color-fg-darkest]">
                <span className="flex size-[16px] items-center justify-center rounded-full bg-[--color-orange] p-2 text-[8px] font-bold text-black">
                  i
                </span>
                You can update notifications settings for each channel separately later
              </span>
            </div>
            <NavigationButtons isValid={channelsIds.length > 0 && isValid} noSkip onNext={submitForm} />
          </>
        )}
      </Formik>
    </>
  );
}
