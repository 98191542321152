import { Destination } from "@/types";
import useTalks from "./useTalks";

export interface Talk {
  id: string;
  title: string;
  type: TalkType;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isActive: boolean;
}

export enum TalkType {
  OTHER = "other",
  TTN = "ttn",
  HEALTH_CHECK = "health_check",
}
export interface TalkAdd {
  title: string;
  type: TalkType;
}

export interface TtnUpdate {
  status: TtnStatus;
  personalMessageStatus: TtnPersonalMessageStatus;
}

export interface TalkUpdate {
  title: string;
  type: TalkType;
  isActive?: boolean;
}

export enum TtnPersonalMessageStatus {
  NEW = "NEW",
  SENT = "SENT",
}

export enum TtnStatus {
  RECEIVED = "RECEIVED",
  SHIPPED = "SHIPPED",
}

export interface Ttn {
  id: string;
  provider: string;
  number: TtnStatus;
  personalMessageStatus: TtnPersonalMessageStatus;
  destination: Destination;
  status: TtnStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isActive: boolean;
}

export type TalksContextProps = ReturnType<typeof useTalks>;
