import { useMemo, useState } from "react";
import MessagesService from "./api";
import { JMessage, JMessageAddParams, JMessageUpdateParams } from "./types";

const useMessage = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [messages, setMessages] = useState<JMessage[] | null>(null);
  const [alreadyUsed, setAlreadyUsed] = useState<number>(0);

  const getMessages = async (event: string, page = 0, limit = 200, search = "") => {
    try {
      setIsFetching(true);
      const response = await MessagesService.get(event, page, limit, search);
      setMessages(response.data.messages);
      setAlreadyUsed(response.data.alreadyUsed);
    } finally {
      setIsFetching(false);
    }
  };

  const postMessage = async (message: JMessageAddParams) => {
    try {
      setIsFetching(true);
      await MessagesService.add(message);
    } finally {
      setIsFetching(false);
    }
  };

  const patchMessage = async (message: JMessageUpdateParams) => {
    try {
      setIsFetching(true);
      await MessagesService.edit(message);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteMessage = async (id: string) => {
    try {
      setIsFetching(true);
      await MessagesService.delete(id);
      setMessages((message) => (message ? message.filter(({ id: messageId }) => messageId !== id) : null));
    } finally {
      setIsFetching(false);
    }
  };

  return useMemo(
    () => ({
      isFetching,
      messages,
      getMessages,
      postMessage,
      patchMessage,
      deleteMessage,
      alreadyUsed,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, messages]
  );
};

export default useMessage;
