import { RouterProvider, createBrowserRouter } from "react-router-dom";
import NotFound from "../../components/NotFound/NotFound";
import Auth from "../Auth/Auth";
import Forgot from "../Auth/Forgot";
import Dashboard from "../Dashboard/Dashboard";
import DestinationList from "../Destinations/DestinationList/DestinationList";
import MessageList from "../Messages/MessageList/MessageList";
import OrgList from "../Organizations/OrgList/OrgList";
import UserList from "../Users/UserList/UserList";
import LayoutApp from "./Layout/LayoutApp";
import LayoutAuth from "./Layout/LayoutAuth";
import AssetList from "../Assets/AssetList/AssetList";
import "react-toastify/dist/ReactToastify.css";
import ActivateFromInvite from "../Auth/ActivateFromInvite";
import { useUserContext } from "../Users/UserContext";
import { useEffect } from "react";
import PromptList from "../Prompts/PromptList";
import Settings from "../Settings";
import Loader from "@/base/Loader";
import EventsList from "../Events/EventsList";
import SlackRedirect from "../SlackRedirect/SlackRedirect";
import ChannelList from "../Channels/ChannelList";
import ResetPassword from "../Auth/ResetPassword";
import { HealthCheck } from "../HealthCheck";
import TalksList from "../Talks/TalksList";
import TTnsList from "../Talks/TtnsList";

const routes = [
  {
    path: "auth",
    element: <LayoutAuth />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      {
        path: "forgot",
        element: <Forgot />,
      },
      {
        path: "reset",
        element: <ResetPassword />,
      },
      {
        path: "invite",
        element: <ActivateFromInvite />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutApp />,
    protected: true,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "team",
        element: <DestinationList />,
      },
      {
        path: "users",
        element: <UserList />,
      },
      {
        path: "orgs",
        element: <OrgList />,
      },
      {
        path: "prompts",
        element: <PromptList />,
      },
      {
        path: "events",
        element: <EventsList />,
      },
      {
        path: "messages",
        element: <MessageList />,
      },
      {
        path: "gifs",
        element: <AssetList />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "slack-redirect",
        element: <SlackRedirect />,
      },
      {
        path: "channels",
        element: <ChannelList />,
      },
      {
        path: "health-check",
        element: <HealthCheck />,
      },
      {
        path: "talks",
        element: <TalksList />,
      },
      {
        path: "talks/:id",
        element: <TTnsList />,
      },
      {
        path: "talks/:id/ttns",
        element: <TTnsList />,
      },
    ],
  },
  {
    path: "/*",
    element: <NotFound />,
  },
];

const router = createBrowserRouter(routes);

const AppRouter = () => {
  const { getUserData, isInitLoading } = useUserContext();

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isInitLoading) {
    return <Loader isLoading={true} />;
  }

  return <RouterProvider router={router} />;
};

export default AppRouter;
