import { useContext, useEffect } from "react";
import { context } from "./SetupWizard";
import SlackIntegrationSvg from "./slackIntegration.svg?react";
import { Button } from "primereact/button";

export default function SlackIntegration() {
  const { setModelTitle, onNextStep, onPrevStep } = useContext(context);

  useEffect(() => {
    setModelTitle("Slack");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="w-[514px] text-[#969595]">
          You’ve just added the Slack channels. To allow JoeyBot to send messages to these channels, you need to add the
          JoeyBot app to each of the channels in Slack. See an example below.
        </div>
        <SlackIntegrationSvg className="w-[400px]" />
      </div>

      <div className="mt-0 flex justify-end gap-4">
        <Button
          text
          label="Back"
          onClick={() => {
            onPrevStep();
          }}
        />
        <Button
          onClick={() => {
            onNextStep();
          }}
          label="Next"
        />
      </div>
    </>
  );
}
