import BirthdaySvg from "@/assets/events/birthday.svg?react";
import AnniversarySvg from "@/assets/events/anniversary.svg?react";
import CustomSvg from "@/assets/events/custom.svg?react";
import HealthCheckSvg from "@/assets/events/healthCheck.svg?react";
import { EventType, PersonalizedEventType } from "@/types";
import { useMemo } from "react";

interface EventTypeIcon {
  eventType?: EventType;
  customEventType?: string;
  personalEventType?: PersonalizedEventType;
}

export default function EventTypes({ eventType, personalEventType, customEventType }: EventTypeIcon) {
  const content = useMemo(() => {
    if (eventType === EventType.GLOBAL) {
      return (
        <>
          <CustomSvg /> Custom
        </>
      );
    }

    if (personalEventType === PersonalizedEventType.BIRTHDAY) {
      return (
        <>
          <BirthdaySvg /> Birthday
        </>
      );
    }

    if (personalEventType === PersonalizedEventType.ANNIVERSARY) {
      return (
        <>
          <AnniversarySvg /> Anniversary
        </>
      );
    }

    if (customEventType === "HealthCheck") {
      return (
        <>
          <HealthCheckSvg /> Health Check
        </>
      );
    }

    return null;
  }, [customEventType, eventType, personalEventType]);

  return <div className="flex gap-2">{content}</div>;
}
