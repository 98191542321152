import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const AppMenuitem = ({
  item,
}: {
  item: {
    to: string;
    class?: string;
    icon: string;
    label: string;
  };
}) => {
  let isCurrentRoute = item.to !== "/" && Boolean(window.location.pathname.match(item.to));
  if (item.to === "/" && window.location.pathname === "/") {
    isCurrentRoute = true;
  }

  const navigate = useNavigate();
  return (
    <>
      <div
        className={clsx(
          "flex cursor-pointer items-center justify-center rounded-full p-2 px-4",
          isCurrentRoute && "bg-white text-black",
          !isCurrentRoute && "text-[var(--color-fg)]"
        )}
        onClick={() => {
          navigate(item.to);
        }}
      >
        {item.label}
      </div>
    </>
  );
};

export default AppMenuitem;
