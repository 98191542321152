import JoeyBotWelcome from "@/assets/joey-welcome.svg?react";
import SetupWizard from "./SetupWizard";

export default function Setup({ step }: { step?: number }) {
  return (
    <div className="flex h-[80vh] flex-col items-center justify-center gap-4">
      <div className="ml-[100px]">
        <JoeyBotWelcome />
      </div>
      <h2 className="text-[40px] font-extralight">Welcome to JoeyBot</h2>
      <div className="mb-[20px] w-[350px] text-center text-[15px] text-[#B5B5B5]">
        Before you start using JoeyBot we need some information to be added by you
      </div>
      <SetupWizard initStep={step} />
    </div>
  );
}
