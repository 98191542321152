import Logo from "@/assets/logo.svg?react";

import clsx from "clsx";
import { PropsWithChildren, useEffect, useState } from "react";

interface Props {
  isLoading: boolean;
}

export default function Loader({ isLoading, children }: PropsWithChildren<Props>) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
      return;
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 0);
    return () => clearTimeout(timer);
  }, [isLoading]);

  return (
    <>
      <div
        className={clsx(
          "fixed left-0 top-0 z-50 flex size-full h-screen w-screen items-center justify-center bg-black/50  !transition-all !duration-500 !ease-in-out",
          loading ? "pointer-events-auto overflow-hidden opacity-100" : "pointer-events-none opacity-0"
        )}
      >
        <div className="absolute">
          <Logo />
        </div>
      </div>
      {children}
    </>
  );
}
