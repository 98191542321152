import { CustomOccurrenceProps, CustomOccurrenceRepeatType } from "./types";
import WeekDaysCheckboxes from "@/components/WeekDaysCheckboxes";

interface Props {
  initProps?: Partial<CustomOccurrenceProps>;
  onChange: (props: Partial<CustomOccurrenceProps>) => void;
}

const today = new Date();
const dayOfWeekNumber = today.getDay();

const defaultInitProps: CustomOccurrenceProps = {
  repeatType: "WEEK",
  repeatOnWeekDays: [dayOfWeekNumber],
  repeatEvery: 1,
  repeatEachMonthDay: undefined,
};

const optionStyle = { background: "transparent !import", border: "none", outline: "none", color: "black" };

export default function OccurrenceTypeCustomDates({ onChange, initProps = defaultInitProps }: Props) {
  const {
    repeatEvery = defaultInitProps.repeatEvery,
    repeatOnWeekDays = defaultInitProps.repeatOnWeekDays,
    repeatType = defaultInitProps.repeatType,
    // repeatEachMonthDay = defaultInitProps.repeatEachMonthDay,
  } = initProps;

  const handleRepeatOnWeekDays = (day: number) => {
    if (repeatOnWeekDays.includes(day)) {
      onChange({
        ...initProps,
        repeatOnWeekDays: repeatOnWeekDays.filter((d) => d !== day),
      });
    } else {
      onChange({
        ...initProps,
        repeatOnWeekDays: [...repeatOnWeekDays, day],
      });
    }
  };

  return (
    <div className="flex flex-col gap-4 font-bold">
      <div className="flex flex-row gap-4">
        <span className="">Repeat every</span>
        <input
          style={{
            width: "40px",
            background: "transparent",
            border: "none",
            outline: "none",
          }}
          className="font-bold"
          type="number"
          min={1}
          max={20}
          value={repeatEvery}
          onChange={(e) =>
            onChange({
              ...initProps,
              repeatEvery: Number(e.target.value),
            })
          }
        />
        <select
          className="font-bold"
          style={{ background: "transparent", border: "none", outline: "none" }}
          value={repeatType}
          onChange={(e) => {
            onChange({
              ...initProps,
              repeatType: e.target.value as CustomOccurrenceRepeatType,
            });
          }}
        >
          <option style={optionStyle} value="WEEK">
            Week
          </option>
          <option style={optionStyle} value="MONTH">
            Month
          </option>
          <option style={optionStyle} value="YEAR">
            Year
          </option>
          <option style={optionStyle} value="DAY">
            Day
          </option>
        </select>
      </div>
      {repeatType === "WEEK" && (
        <WeekDaysCheckboxes repeatOnWeekDays={repeatOnWeekDays} onChangeRepeatOnWeekDays={handleRepeatOnWeekDays} />
      )}
    </div>
  );
}
