import { classNames } from "primereact/utils";
import React from "react";
import styles from "../base.module.scss";

interface Props {
  error?: boolean;
  className?: string;
  title: string;
  sublabel?: string;
  id?: string;
  noLabel?: boolean;
  htmlFor?: string;
}

export default function Label(props: React.PropsWithChildren<Props>) {
  return (
    <span
      id={props.id}
      className={classNames(styles.input, "field", props.className, {
        error: props.error,
      })}
    >
      {props.title && !props.noLabel && <label htmlFor={props.htmlFor}>{props.title}</label>}
      {props.sublabel && <span className={classNames("sublabel")}>{props.sublabel}</span>}
      {props.children}
    </span>
  );
}
