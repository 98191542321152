import { useDestinationsContext } from "@/containers/Destinations";
import { useUserContext } from "@/containers/Users/UserContext";
import { showError } from "@/utils";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function ImportDestinationsFromBamboo() {
  const { getDestinations, importDestinationsFromBambooHr } = useDestinationsContext();
  const { user, getUserData, isUserDataFetching, orgSettings } = useUserContext();

  const isBambooImported = orgSettings.is_bamboo_already_imported;
  const isBambooActive = user?.isBambooAvailable;

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        loading={isUserDataFetching}
        tooltipOptions={{ position: "bottom" }}
        label={isBambooImported ? "Refresh list from BambooHR" : "Import From BambooHR"}
        icon={isBambooImported ? "pi pi-refresh" : "pi pi-plus"}
        onClick={async () => {
          if (!isBambooActive) {
            return;
          }
          try {
            await importDestinationsFromBambooHr();
            toast.success("Imported from BambooHR");
            await getDestinations();
          } catch (e) {
            showError(e, "Failed to import from BambooHR");
          }
        }}
        className={"ml-5"}
        tooltip={!isBambooActive ? "Please setup Bamboo and Slack API first" : undefined}
      />
    </>
  );
}
