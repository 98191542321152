import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useState } from "react";
import { useUserContext } from "../Users/UserContext";
import { userSignInSchema } from "../../schemas";
import JInputText from "../../base/JInputText";
import { Link } from "react-router-dom";

const Auth = () => {
  const { login, isAuthFetching } = useUserContext();
  const [error, setError] = useState<string | null>(null);

  async function handleLogin({ username, password }: { username: string; password: string }) {
    setError(null);

    try {
      await login(username.trim(), password);
    } catch (err) {
      console.error(err);
      setError("Wrong credentials");
    }
  }

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={userSignInSchema}
      onSubmit={handleLogin}
    >
      {() => (
        <Form>
          <h1 className="mb-4 text-left text-[40px] ">Sign In</h1>
          <JInputText id="username" title="Email" placeholder="Email" className="w-full" noLabel />
          <JInputText
            inputType="password"
            id="password"
            title="Password"
            placeholder="Password"
            className="w-full"
            noLabel
          />
          {!!error && (
            // eslint-disable-next-line tailwindcss/no-custom-classname
            <div className="field">
              <Message severity="error" text={error} />
            </div>
          )}
          <div className="mt-[40px] text-left">
            <Button label="Sign In" type="submit" loading={isAuthFetching} />
          </div>
          <div className="mt-4 text-left text-[--color-fg]">
            <Link to="forgot">Forgot password?</Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Auth;
