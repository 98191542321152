import TypeSelector from "@/base/TypeSelector";
import { HealthCheck } from "./HealthCheck.types";
import { useState } from "react";
import HealthCheckNotificationsList from "./HealthCheckNotificationsList";
import HealthCheckReport from "./HealthCheckReport";

interface Props {
  healthCheck: HealthCheck;
}

export default function HealthCheckItemDetails({ healthCheck }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <TypeSelector key={1} isActive={activeIndex === 0} onClick={() => setActiveIndex(0)}>
          Messages
        </TypeSelector>
        <TypeSelector key={2} isActive={activeIndex === 1} onClick={() => setActiveIndex(1)}>
          Reports
        </TypeSelector>
      </div>
      {activeIndex === 0 && <HealthCheckNotificationsList healthCheckId={healthCheck.id} />}
      {activeIndex === 1 && <HealthCheckReport healthCheckId={healthCheck.id} />}
    </div>
  );
}
