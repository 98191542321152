import { Button } from "primereact/button";
import SlackIcon from "@/assets/slack.svg?react";

const SLACK_ID_PROD = "6188462386880.6449188397639";
const SLACK_ID_LOCAL = "6188462386880.7197686399798";
const SLACK_ID_QA = "6188462386880.7206782189892";

const redirect_url = (slackId: string) =>
  `https://slack.com/oauth/v2/authorize?client_id=${slackId}&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,emoji:read,im:history,im:read,im:write,im:write.invites,im:write.topic,incoming-webhook,links.embed:write,links:read,links:write,metadata.message:read,reactions:read,team.preferences:read,team:read,users.profile:read,users:read,users:read.email&user_scope=channels:history,channels:read,chat:write,im:history,im:read,im:write`;

export default function SlackIntegrationButton() {
  const isQa = window.location.hostname.includes("qa.bot.eteam.io");
  const isLocal = window.location.hostname.includes("localhost") || window.location.hostname.includes("ngrok-free.app");
  let slackId = SLACK_ID_PROD;
  if (isQa) slackId = SLACK_ID_QA;
  if (isLocal) slackId = SLACK_ID_LOCAL;

  return (
    <a href={redirect_url(slackId)}>
      <Button>
        <div className="flex gap-2">
          <SlackIcon width={20} height={20} />
          <div>Connect to Slack</div>
        </div>
      </Button>
    </a>
  );
}
