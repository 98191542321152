import { useUserContext } from "@/containers/Users/UserContext";
import { Button } from "primereact/button";
import ImportDestinationsModal from "./ImportDestinationsModal";
import { useState } from "react";

export default function ImportTtnsFromCSV() {
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);

  const { user } = useUserContext();

  const isSlackActive = user?.isSlackAvailable;

  return (
    <>
      <Button
        tooltipOptions={{ position: "bottom" }}
        label="Upload CSV"
        icon="pi pi-plus"
        onClick={() => {
          if (!isSlackActive) {
            return;
          }
          setShowMessageModal(true);
        }}
        className="ml-5"
        tooltip={!isSlackActive ? "Please setup Slack first" : undefined}
      />
      {showMessageModal && <ImportDestinationsModal onClose={() => setShowMessageModal(false)} />}
    </>
  );
}
