import { Image } from "primereact/image";
import "./ResourceCard.scss";

interface Props {
  id: string;
  url: string;
  loading?: boolean;
  onRemove?: ((id: string, ref: HTMLDivElement) => void) | null;
}

const ResourceCard = ({ id, url, onRemove }: Props) => {
  return (
    <div
      className="group relative size-[300px] overflow-hidden"
      style={{
        display: "grid",
        gridAutoFlow: "row",
        gridAutoRows: "220px 80px",
      }}
    >
      <div className="">
        <Image src={url} zoomSrc={url} preview />
      </div>
      {onRemove && (
        <div className="invisible absolute right-0 top-0 z-50 flex items-center justify-end rounded-lg bg-white p-2 group-hover:visible">
          <i
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="pi pi-trash cursor-pointer text-2xl text-red-500"
            onClick={(e) => {
              onRemove?.(id, e.target as HTMLDivElement);
            }}
          ></i>
        </div>
      )}
    </div>
  );
};

export default ResourceCard;
