import { Field, FieldInputProps, FieldMetaProps } from "formik";
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import styles from "../base.module.scss";

interface JTextarea extends InputTextareaProps {
  noLabel?: boolean;
  sublabel?: string;
  maxChars?: number;
  id: string;
  title: string;
  placeholder?: string;
  required?: boolean;
}

export default function JTextArea(props: JTextarea) {
  return (
    <Field name={props.id}>
      {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
        <div
          className={classNames(styles.input, "field", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.title && !props.noLabel && (
            <label htmlFor={props.id}>
              {props.title} {props.required && <span className="text-red-500">*</span>}{" "}
            </label>
          )}
          {props.sublabel && <span className={classNames("sublabel")}>{props.sublabel}</span>}
          <InputTextarea placeholder={props.placeholder} disabled={props.disabled} {...field} {...props} />
          {props.maxChars && (
            <div className={classNames("charCount p-text-right")}>
              {props.maxChars - field.value.length} characters left
            </div>
          )}
          {meta.touched && meta.error && <sub className="p-1 text-pink-600">{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
