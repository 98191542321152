import ThemeContextProvider from "../../theme/ThemeContext";
import AssetContextProvider from "../Assets/AssetContext";
import DestinationsContext from "../Destinations/DestinationsContext";
import MessagesContext from "../Messages/MessagesContext";
import NotificationContext from "../Notifications/NotificationContext";
import UserContext from "../Users/UserContext";
import DashboardContext from "../Dashboard/DashboardContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./AppRouter";
import EventsContextProvider from "../Events/EventsContext";
import { ConfirmPopup } from "primereact/confirmpopup";
import { PrimeReactProvider } from "primereact/api";
import React from "react";
import { HealthCheckProvider } from "../HealthCheck/useHealthCheck";
import { TalksContextProvider } from "../Talks/TalksContext";

const App = () => {
  return (
    <React.StrictMode>
      <PrimeReactProvider>
        <ThemeContextProvider>
          <UserContext>
            <TalksContextProvider>
              <HealthCheckProvider>
                <EventsContextProvider>
                  <AssetContextProvider>
                    <DashboardContext>
                      <NotificationContext>
                        <MessagesContext>
                          <DestinationsContext>
                            <AppRouter />
                            <ToastContainer theme="colored" position="top-center" />
                            <div
                              id="confirmMessageWrapper"
                              className="absolute left-0 top-0 flex size-full items-center justify-center"
                            >
                              <div
                                id="confirmMessage"
                                className=""
                                style={{
                                  position: "fixed",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                            </div>
                            <ConfirmPopup />
                          </DestinationsContext>
                        </MessagesContext>
                      </NotificationContext>
                    </DashboardContext>
                  </AssetContextProvider>
                </EventsContextProvider>
              </HealthCheckProvider>
            </TalksContextProvider>
          </UserContext>
        </ThemeContextProvider>
      </PrimeReactProvider>
    </React.StrictMode>
  );
};

export default App;
