import axios from "axios";
import { Talk, TalkAdd, TalkUpdate, Ttn, TtnUpdate } from "./types";

export default class TalksService {
  public static getAll = async () => axios.get<Talk[]>("/talks");
  public static getById = async (id: string) => axios.get<Talk>(`/talks/${id}`);
  public static add = async (talk: TalkAdd) => axios.post<Talk>(`/talks`, talk);
  public static update = async (id: string, talk: TalkUpdate) => axios.patch<Talk>(`/talks/${id}`, talk);
  public static delete = async (id: string) => axios.delete(`/talks/${id}`);

  public static getTtns = async (talkId: string) => axios.get<Ttn[]>(`/talks/${talkId}/ttns`);
  public static updateTtn = async ({ talkId, id, ttn }: { talkId: string; id: string; ttn: TtnUpdate }) =>
    axios.patch<Ttn>(`/talks/${talkId}/ttns/${id}`, ttn);

  public static sendAllTtnsMessages = async (talkId: string) => axios.post(`/talks/${talkId}/ttns/sendMessageToAll`);
  public static sendDirectTtnMessages = async (talkId: string, ttnId: string) =>
    axios.post(`/talks/${talkId}/ttns/${ttnId}/sendDirectTtnMessages`);

  public static importTtnsFromCsv = async (talkId: string, formData: FormData) => {
    return axios.post(`/talks/${talkId}/ttns/importCsv`, formData);
  };
}
