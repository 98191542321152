import axios from "axios";
import { isAbsoluteURLRegex } from "./constants";
import { removeToken } from "./utils";

const { VITE_API_URL } = import.meta.env;

axios.interceptors.request.use((config) => {
  if (config?.url && !isAbsoluteURLRegex.test(config.url)) {
    config.url = `${VITE_API_URL}${config.url}`;
  }

  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const errorMessage = error.response?.data;
    console.error(errorMessage);

    if (error?.response?.status === 401) {
      removeToken();
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        if (!window.location.pathname.match("/auth")) {
          window.location.href = "/auth";
        }
      }, 1000);
    }

    return Promise.reject(errorMessage ?? error);
  }
);
