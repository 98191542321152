import { useDestinationsContext } from "@/containers/Destinations";
import { showError } from "@/utils";
import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";

const ImportDestinationsModal = ({ onClose }: { onClose: () => void }) => {
  const { getDestinations, importDestinations, isFetching } = useDestinationsContext();

  const onSubmit = async (values: { importf?: File }) => {
    try {
      if (!values?.importf) {
        console.error("No file selected");
        return;
      }

      const body = new FormData();
      body.append("importf", values.importf);

      await importDestinations(body, "1");
      await getDestinations();
      toast.success("Destinations imported");
      onClose();
    } catch (e) {
      showError(e, "Error importing destinations from CSV");
    }
  };

  const renderFileName = (
    file: File,
    setFieldValue: (field: string, value: string | undefined, shouldValidate?: boolean | undefined) => void
  ) => (
    <div className={classNames("card-container yellow-container flex flex-wrap items-center")}>
      {!!file && (
        <Button
          className="  mr-2 flex"
          icon="pi pi-trash"
          onClick={(event) => {
            event.preventDefault();
            setFieldValue("importf", undefined);
          }}
        />
      )}
      <span className="flex">{file.name}</span>
    </div>
  );

  return (
    <Dialog header={"Import from CSV"} visible={true} onHide={onClose}>
      <Formik<{
        importf?: File;
      }>
        initialValues={{
          importf: undefined,
        }}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="flex flex-col gap-4">
              <span className="">CSV content example</span>
              <div className="flex flex-col gap-1">
                <code className="font-mono text-gray-500">
                  "Last name, First name",Work Email,Birth Date,Hire Date,Role,Project
                </code>
                <code className="text-gray-500">
                  "John, Smith",joe.smith@email.com,02/22/1985,02/22/2000,Developer,Best Project
                </code>
              </div>

              <div className="flex items-center gap-2 py-4">
                {!values.importf && (
                  <FileUpload
                    auto
                    customUpload
                    mode="basic"
                    name="importf"
                    accept="text/csv"
                    chooseLabel={"Select CSV file"}
                    uploadHandler={({ files }) => setFieldValue("importf", files[0])}
                  />
                )}
                <span className="flex items-center justify-center ">
                  {!!values.importf && renderFileName(values.importf, setFieldValue)}
                </span>
              </div>
              <div className="flex justify-end gap-2 text-right">
                <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
                <Button
                  label={"Start Import"}
                  icon="pi pi-check"
                  type="submit"
                  loading={isFetching}
                  disabled={!values?.importf}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ImportDestinationsModal;
