import axios from "axios";
import { JNotificationPatchBody } from "./types";
export default class NotificationsService {
  public static get = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: [
            {
              id: 1,
              text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum",
              status: "PLANNED",
            },
            {
              id: 2,
              text: "It look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text",
              status: "DONE",
            },
            {
              id: 3,
              text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
              status: "FAILED",
            },
            {
              id: 4,
              text: "Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
              status: "CANCELLED",
            },
          ],
        });
      }, 1500);
    });
  };
  public static getNotificationStatus = async () => await axios.get<boolean>("/notifications/notificationStatus");
  public static setNotificationStatus = async (notificationStatus: boolean) =>
    await axios.post("/notifications/notificationStatus", { notificationStatus });
  public static setCustomOrgNotificationStatus = async (notificationStatus: boolean, orgId: string) =>
    await axios.post("setOrgNotificationStatus", { notificationStatus, orgId });
  public static cancelNotification = async (id: string, channelId: string) =>
    await axios.post("/notifications/cancel", { id, channelId });
  public static updateNotification = async (id: string, body: JNotificationPatchBody) =>
    await axios.patch(`/notifications/${id}`, body);
}
