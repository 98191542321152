import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { JUser, OrgAddForm, OrganizationDTO, UserEditForm } from "./types";
import { getUserDataFromSession, removeToken, removeUserDataFromSession, setToken } from "../../utils";
import { OrganizationSettingDTO, OrganizationSettingsRecord } from "../../types";
import { PromptAddValues, PromptDTO, PromptUpdateValues } from "../Prompts/type";
import { ChannelAddProps, ChannelDTO, ChannelUpdateProps } from "../Channels/types";

const useUser = () => {
  const [user, setUser] = useState<JUser | null>(null);
  const [users, setUsers] = useState<JUser[]>([]);
  const [orgs, setOrgs] = useState<OrganizationDTO[]>([]);
  const [prompts, setPrompts] = useState<PromptDTO[]>([]);
  const [channels, setChannels] = useState<ChannelDTO[]>([]);
  const [isUserDataFetching, setIsUserDataFetching] = useState<boolean>(false);
  const [isOrgManagerFetching, setIsOrgManagersFetching] = useState<boolean>(false);
  const [isOrgsFetching, setIsOrgsFetching] = useState<boolean>(false);
  const [isAuthFetching, setIsAuthFetching] = useState<boolean>(false);
  const [isPromptFetching, setIsPromptFetching] = useState<boolean>(false);
  const [isInviteFetching, setIsInviteFetching] = useState<boolean>(false);
  const [isChannelFetching, setIsChannelFetching] = useState<boolean>(false);
  const [isOtherFetching, setIsOtherFetching] = useState<boolean>(false);
  const [isInitLoading, setIsInitLoading] = useState<boolean>(true);

  const isSuperAdmin = !isUserDataFetching && user?.type === "SUADMIN";
  const isOrgManager = !isUserDataFetching && user?.type === "ADMIN";
  const isChannelManager = !isUserDataFetching && user?.type === "USERS";

  useEffect(() => {
    const user = getUserDataFromSession();
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  /// Channels
  /// -----------------------------

  const getChannels = async () => {
    setIsChannelFetching(true);

    return await axios
      .get<ChannelDTO[]>("/channels")
      .then((res) => {
        setChannels(res.data);
        return res.data;
      })
      .finally(() => {
        setIsChannelFetching(false);
      });
  };

  const addChannel = async (channel: ChannelAddProps) => {
    setIsChannelFetching(true);

    return await axios
      .post("/channel", channel)
      .then(() => {
        getChannels();
      })
      .finally(() => {
        setIsChannelFetching(false);
      });
  };

  const updateChannel = async (channel: ChannelUpdateProps) => {
    setIsChannelFetching(true);

    return await axios
      .patch(`/channel/${channel.id}`, channel)
      .then(() => {
        getChannels();
      })
      .finally(() => {
        setIsChannelFetching(false);
      });
  };

  const deleteChannel = async (id: string) => {
    setIsChannelFetching(true);

    return await axios
      .delete(`/channel/${id}`)
      .then(() => {
        getChannels();
      })
      .finally(() => {
        setIsChannelFetching(false);
      });
  };

  const syncChannelMembers = async (id: string) => {
    setIsChannelFetching(true);

    return await axios
      .patch(`/channel/refresh/${id}`)
      .then(() => {
        getChannels();
      })
      .finally(() => {
        setIsChannelFetching(false);
      });
  };

  /// Admin
  /// -----------------------------

  const sendRandomMessage = async (channelId: string, eventId?: string) => {
    setIsOtherFetching(true);

    return await axios.post("/sendRandomMessage/" + channelId, { eventId }).finally(() => {
      setIsOtherFetching(false);
    });
  };

  const resetScheduler = async (params: {
    fromHour: number;
    toHour: number;
    fromMinute: number;
    toMinute: number;
    channelId: string;
    weekends?: number[];
    cleanPrevNotifications?: boolean;
  }) => {
    setIsOtherFetching(true);

    return await axios
      .post<{
        notifyFromHour: number;
        notifyToHour: number;
        notifyFromMin: number;
        notifyToMin: number;
        channelId: string;
        weekends?: number[];
        cleanPrevNotifications?: boolean;
      }>("/notifications/init", {
        notifyFromHour: params.fromHour,
        notifyToHour: params.toHour,
        notifyFromMin: params.fromMinute,
        notifyToMin: params.toMinute,
        channelId: params.channelId,
        weekends: params.weekends,
        cleanPrevNotifications: params.cleanPrevNotifications,
      })
      .finally(() => {
        setIsOtherFetching(false);
      });
  };

  /// Prompts
  /// -----------------------------

  const getPrompts = async ({ shared }: { shared?: boolean }) => {
    setIsPromptFetching(true);

    return await axios
      .get<PromptDTO[]>("/prompts", {
        params: {
          shared,
        },
      })
      .then((res) => {
        if (res?.data?.length === 0) {
          return [];
        }
        setPrompts(res.data);
        return res.data;
      })
      .finally(() => {
        setIsPromptFetching(false);
      });
  };

  const updatePrompt = async (prompt: PromptUpdateValues) => {
    setIsPromptFetching(true);
    const { id, ...restPrompt } = prompt;
    return await axios
      .patch<PromptDTO>(`/prompt/${id}`, restPrompt)
      .then((res) => {
        const data = res.data;
        const index = prompts.findIndex((p: PromptDTO) => p.id === data.id);
        prompts[index] = { ...data };
        setPrompts([...prompts]);
      })
      .finally(() => {
        setIsPromptFetching(false);
      });
  };

  const deletePrompt = async (id: string) => {
    setIsPromptFetching(true);

    return await axios
      .delete(`/prompt/${id}`)
      .then(() => {
        const index = prompts.findIndex((p: PromptDTO) => p.id === id);
        prompts.splice(index, 1);
        setPrompts([...prompts]);
      })
      .finally(() => {
        setIsPromptFetching(false);
      });
  };

  const addPrompt = async (prompt: PromptAddValues) => {
    setIsPromptFetching(true);

    return await axios
      .post<PromptDTO>(`/prompt`, prompt)
      .then((res) => {
        const data = res.data;
        setPrompts([...prompts, data]);
      })
      .finally(() => {
        setIsPromptFetching(false);
      });
  };

  /// Messages
  /// -----------------------------

  const generateContent = async ({
    eventId,
    role,
    isGroup,
  }: {
    eventId: string;
    role?: string | null;
    isGroup?: boolean;
  }) => {
    setIsPromptFetching(true);

    return await axios
      .post<string>("/generate", { eventId, role, isGroup })
      .then((res) => {
        return res?.data;
      })
      .finally(() => {
        setIsPromptFetching(false);
      });
  };

  /// Auth
  /// -----------------------------

  const sendInvite = async (email: string) => {
    setIsInviteFetching(true);

    return await axios.post("/invite", { email }).finally(() => {
      setIsInviteFetching(false);
    });
  };

  const activateFromInvite = async (token: string, password: string) => {
    setIsInviteFetching(true);

    return await axios.post("/activate", { token, password }).finally(() => {
      setIsInviteFetching(false);
    });
  };

  const login = async (username: string, password: string) => {
    setIsAuthFetching(true);

    return await axios
      .post<{ user: JUser; token: string }>("/auth", { email: username, password })
      .then((res) => {
        const data = res.data;
        setToken(data.token);
        setUser(data.user);
      })
      .finally(() => {
        setIsAuthFetching(false);
      });
  };

  const logout = async () => {
    setUser(null);
    removeUserDataFromSession();
    removeToken();
    localStorage.clear();
    setIsAuthFetching(false);
    axios.defaults.headers.common["Authorization"] = "";
    setTimeout(() => {
      if (!window.location.pathname.match("/auth")) {
        window.location.href = "/auth";
      }
    }, 1000);
    return;
  };

  const sendResetPassLink = async (email: string) => {
    try {
      setIsAuthFetching(true);
      await axios.post("/sendResetPasswordLink", { email });
    } finally {
      setIsAuthFetching(false);
    }
  };

  const resetPass = async ({ token, password }: { token: string; password: string }) => {
    setIsAuthFetching(true);

    return await axios
      .post("/resetPassword", { token, password })
      .then(() => {
        setIsAuthFetching(false);
      })
      .catch(() => {
        setIsAuthFetching(false);
      });
  };

  /// User Data
  /// -----------------------------

  const getUserData = async (onSuccess?: () => void) => {
    return await axios
      .get<{ user: JUser }>("/userData")
      .then(async (res) => {
        const data = res.data;
        setUser(data.user);
        onSuccess?.();
      })
      .catch(() => {
        logout();
      })
      .finally(() => {
        setIsUserDataFetching(false);
        setIsInitLoading(false);
      });
  };

  /// Org Managers
  /// -----------------------------

  const getOrgManagerList = async () => {
    setIsOrgManagersFetching(true);
    return await axios
      .get<JUser[]>("/users")
      .then((res) => {
        const data = res.data;
        setUsers(data);
      })
      .finally(() => {
        setIsOrgManagersFetching(false);
      });
  };

  const addOrgManager = async (user: UserEditForm) => {
    setIsOrgManagersFetching(true);
    return await axios
      .post<JUser>("/user", user)
      .then((res) => {
        const data = res.data;
        setUsers([...users, data]);
      })
      .finally(() => {
        setIsOrgManagersFetching(false);
      });
  };

  const updateOrgManager = async (user: JUser) => {
    setIsOrgManagersFetching(true);
    return await axios
      .patch<JUser>(`/user/${user.id}`, user)
      .then((res) => {
        const data = res.data;
        const index = users.findIndex((u: JUser) => u.id === data.id);
        users[index] = { ...data, organization: data.organization || null };
        setUsers([...users]);
      })
      .finally(() => {
        setIsOrgManagersFetching(false);
      });
  };

  const removeOrgManager = async (id: string) => {
    setIsOrgManagersFetching(true);
    return await axios
      .delete(`/user/${id}`)
      .then(() => {
        const index = users.findIndex((u: JUser) => u.id === id);
        users.splice(index, 1);
        setUsers([...users]);
      })
      .finally(() => {
        setIsOrgManagersFetching(false);
      });
  };

  /// Org Settings
  /// -----------------------------

  const saveOrgSettings = async (key: string, value: string, orgId?: string) => {
    return await axios.patch<OrganizationSettingDTO>(`/setting/${key}`, { value, orgId }).then((res) => {
      if (!user) {
        console.error("User is not defined");
        return;
      }
      const newUser: JUser = { ...user };
      const data = res.data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newUser.organization!.organizationSetting = newUser.organization!.organizationSetting!.map((s) => {
        if (s.key === data.key) {
          return { ...data };
        }
        return { ...s };
      });
      setUser(newUser);
    });
  };

  /// Orgs
  /// -----------------------------

  const getOrgs = async () => {
    setIsOrgsFetching(true);
    return await axios
      .get<OrganizationDTO[]>("/orgs")
      .then((res) => {
        const data = res.data;
        setOrgs(data);
      })
      .finally(() => {
        setIsOrgsFetching(false);
      });
  };

  const getOrgById = async (id: string) => {
    setIsOrgsFetching(true);
    return await axios
      .get<OrganizationDTO>(`/org/${id}`)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .finally(() => {
        setIsOrgsFetching(false);
      });
  };

  const addOrg = async (org: OrgAddForm) => {
    setIsOrgsFetching(true);
    return await axios
      .post<OrganizationDTO>("/org", org)
      .then((res) => {
        const data = res.data;
        setOrgs([...orgs, data]);
      })
      .finally(() => {
        setIsOrgsFetching(false);
      });
  };

  const updateOrg = async (org: OrganizationDTO) => {
    setIsOrgsFetching(true);
    return await axios
      .patch<OrganizationDTO>(`/org/${org.id}`, org)
      .then((res) => {
        const data = res.data;
        const index = orgs.findIndex((o: OrganizationDTO) => o.id === data.id);
        orgs[index] = { ...data };
        setOrgs([...orgs]);
      })
      .finally(() => {
        setIsOrgsFetching(false);
      });
  };

  return useMemo(
    () => ({
      getOrgById,
      getPrompts,
      deletePrompt,
      addPrompt,
      prompts,
      updatePrompt,
      user,
      login,
      logout,
      resetPass,
      sendResetPassLink,
      isInviteFetching,
      getUserData,
      getOrgManagerList,
      users,
      addOrg,
      getOrgs,
      updateOrg,
      isOrgsFetching,
      isUserDataFetching,
      orgs,
      updateOrgManager,
      addUser: addOrgManager,
      activateFromInvite,
      sendInvite,
      isAuthFetching,
      generateContent,
      isPromptFetching,
      removeOrgManager,
      saveOrgSettings,
      resetScheduler,
      sendRandomMessage,
      isSuperAdmin,
      isOrgManagerFetching,
      isOtherFetching,
      isInitLoading,
      /// Channels
      syncChannelMembers,
      updateChannel,
      addChannel,
      getChannels,
      deleteChannel,
      isChannelFetching,
      channels,
      setChannels,
      orgSettings: user?.formattedOrgSettings ?? ({} as OrganizationSettingsRecord),
      isChannelManager,
      isOrgManager,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isChannelFetching,
      isInitLoading,
      isOtherFetching,
      isInviteFetching,
      isOrgsFetching,
      isUserDataFetching,
      isAuthFetching,
      isOrgManagerFetching,
      isPromptFetching,
      isSuperAdmin,
      channels,
      orgs,
      user,
      users,
    ]
  );
};

export default useUser;
