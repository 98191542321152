import clsx from "clsx";
import { Dialog } from "primereact/dialog";
import { PropsWithChildren } from "react";

interface Props {
  onClose: () => void;
  visible: boolean;
  title: string;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
}

export default function Modal({
  title,
  visible,
  onClose,
  children,
  className,
  headerClassName,
  contentClassName,
}: PropsWithChildren<Props>) {
  return (
    <Dialog
      header={<div className={clsx("px-4 text-[24px] text-[#B5B5B5]", headerClassName)}>JoeyBot Setup / {title}</div>}
      onHide={onClose}
      visible={visible}
      className={`${className}`}
      contentClassName={contentClassName}
    >
      <div className="flex h-full flex-col">
        <div className="flex h-full flex-col justify-between overflow-hidden p-4">{children}</div>
      </div>
    </Dialog>
  );
}
