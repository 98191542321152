import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { useUserContext } from "../Users/UserContext";
import { passwordValidationSchema } from "../../schemas";
import JInputText from "../../base/JInputText";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { resetPass, isAuthFetching } = useUserContext();
  const token = new URLSearchParams(window.location.search).get("token");
  const navigate = useNavigate();

  async function handleActivateFromInvite({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) {
    try {
      if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }

      if (!token) {
        toast.error("Invalid token");
        return;
      }

      resetPass({ token, password });
      toast.success("New password set. Please login using your new password");
      navigate("/auth");
    } catch (err) {
      toast.error("Invalid token. Please try again");
      console.error(err);
    }
  }

  return (
    <Formik
      initialValues={{
        confirmPassword: "",
        password: "",
      }}
      validationSchema={passwordValidationSchema}
      onSubmit={handleActivateFromInvite}
    >
      <Form>
        <h1 className="mb-4 text-left text-[30px] ">Set new password</h1>
        <JInputText
          id="password"
          title="Password"
          placeholder="Password"
          className="w-full"
          inputType="password"
          noLabel
        />
        <JInputText
          id="confirmPassword"
          title="Repeat password"
          placeholder="Repeat password"
          inputType="password"
          className="w-full"
          noLabel
        />
        <div className="mt-[40px] text-left">
          <Button label="Reset" type="submit" loading={isAuthFetching} />
        </div>
      </Form>
    </Formik>
  );
};

export default ResetPassword;
