import WeekDaysCheckboxes from "@/components/WeekDaysCheckboxes";
import { Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { useRef } from "react";
import { useHealthCheckContext } from "./useHealthCheck";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";
import JTextArea from "@/base/JTextArea/JTextArea";
import { useUserContext } from "../Users/UserContext";
import { Navigate } from "react-router-dom";
import JInputText from "@/base/JInputText";
import HealthCheckRecipients from "./HealthCheckRecipients";
import { HealthCheck, HealthCheckAnswerType } from "./HealthCheck.types";
import { ModalOperationType } from "@/types";
import clsx from "clsx";

export interface HealthCheckCreateFormikProps {
  days: number[];
  time: string;
  format: HealthCheckAnswerType;
  prompt: string | null;
  welcomeMessage: string | null;
  goodbyeMessage: string | null;
  destinationIds: string[];
  title: string;
}

type Props = {
  onClose?: () => void;
  type?: ModalOperationType;
  healthCheck?: HealthCheck | null;
};

export const HealthCheckCreate = ({ onClose, type = "create", healthCheck }: Props) => {
  const { orgSettings } = useUserContext();
  const { createHealthCheck, updateHealthCheck, isFetching } = useHealthCheckContext();

  const formikRef = useRef(null);

  const handleRepeatOnWeekDays =
    (setFieldValue: FormikHelpers<HealthCheckCreateFormikProps>["setFieldValue"], value: number[]) => (day: number) => {
      if (value.includes(day)) {
        setFieldValue(
          "days",
          value.filter((d) => d !== day)
        );
      } else {
        setFieldValue("days", [...value, day]);
      }
    };

  const onSubmit = async (values: HealthCheckCreateFormikProps) => {
    const notifyMin = values.time.split(":")[1] as string;
    const notifyHour = values.time.split(":")[0] as string;
    try {
      if (type === "update") {
        if (!healthCheck) {
          throw new Error("Health check is not provided");
        }

        await updateHealthCheck(healthCheck.id, {
          ...values,
          notifyMin: parseInt(notifyMin),
          notifyHour: parseInt(notifyHour),
        });
        toast.success("Health check update successfully");
        onClose?.();
        return;
      }

      await createHealthCheck({
        ...values,
        notifyMin: parseInt(notifyMin),
        notifyHour: parseInt(notifyHour),
      });
      toast.success("Health check create successfully");
      onClose?.();
    } catch (error) {
      toast.error(errorOrDefault(error, "Failed to create health check"));
    }
  };

  if (orgSettings.feature_flag_health_check === false) {
    return <Navigate to="/" />;
  }

  return (
    <Formik<HealthCheckCreateFormikProps>
      innerRef={formikRef}
      initialValues={
        type === "update" && healthCheck
          ? {
              title: healthCheck.title,
              days: healthCheck.days,
              time:
                healthCheck.notifyHour.toString().padStart(2, "0") +
                ":" +
                healthCheck.notifyMin.toString().padStart(2, "0"),
              format: healthCheck.format,
              prompt: healthCheck.prompt,
              welcomeMessage: healthCheck.welcomeMessage,
              goodbyeMessage: healthCheck.goodbyeMessage,
              destinationIds: healthCheck.destinations.map((d) => d.id),
            }
          : {
              title: "",
              days: [],
              time: "",
              format: HealthCheckAnswerType.EMOJI,
              prompt: "",
              welcomeMessage: "",
              goodbyeMessage: "",
              destinationIds: [],
            }
      }
      validate={(values) => {
        const errors: Record<keyof HealthCheckCreateFormikProps, string> = {} as Record<
          keyof HealthCheckCreateFormikProps,
          string
        >;

        if (!values.title) {
          errors.title = "Title is required";
        }

        if (values.days.length === 0) {
          errors.days = "Select at least one day";
        }

        if (!values.time) {
          errors.time = "Time is required";
        }

        if (!values.prompt && !values.welcomeMessage) {
          errors.prompt = "Prompt or welcome message is required";
          errors.welcomeMessage = "Prompt or welcome message is required";
        }

        if (values.destinationIds.length === 0) {
          errors.destinationIds = "Select at least one recipient";
        }

        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({ dirty, submitForm, touched, values, setFieldValue, isSubmitting, errors }) => (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row-reverse gap-6">
            <div className="flex flex-col gap-4">
              <label className="relative flex flex-col gap-2 text-left font-bold">
                <span>
                  Recipients <span className="text-red-500">*</span>
                </span>
                <div>
                  {touched.destinationIds && errors.destinationIds && (
                    <small className="absolute bottom-[-16px] text-red-500">{errors.destinationIds}</small>
                  )}
                </div>
              </label>
              <HealthCheckRecipients />
            </div>
            <div className="flex w-full flex-col">
              <div>
                <JInputText
                  id="title"
                  title="Health check title"
                  placeholder="Enter a title of the health check"
                  required
                  className="text-white"
                />
              </div>
              <div className="mt-4 flex gap-6">
                <div className="flex flex-col gap-4">
                  <label className="relative flex flex-col gap-0 text-left font-bold">
                    <span>
                      Health check days <span className="text-red-500">*</span>
                    </span>
                    {touched.days && errors.days && (
                      <small className="absolute bottom-[-16px] text-red-500">{errors.days}</small>
                    )}
                  </label>
                  <WeekDaysCheckboxes
                    repeatOnWeekDays={values.days}
                    onChangeRepeatOnWeekDays={handleRepeatOnWeekDays(setFieldValue, values.days)}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <label className="text-left font-bold">
                    Health check time <span className="text-red-500">*</span>
                  </label>
                  <JInputText type="time" id="time" title="" className="w-[150px] text-white" />
                </div>
              </div>
              <div className="mt-2 flex h-[60px] gap-8">
                <div className="flex flex-col gap-4">
                  <label className="text-left font-bold">
                    Health check format <small className="text-red-500">*</small>
                  </label>
                  <div className="flex gap-6">
                    <div className="flex gap-3">
                      <div
                        className={clsx(
                          "size-6 rounded-full ",
                          values.format === HealthCheckAnswerType.EMOJI ? "bg-orange-500" : "bg-[--color-dark-easy]"
                        )}
                        onClick={() => {
                          setFieldValue("format", HealthCheckAnswerType.EMOJI);
                        }}
                      />
                      <span>Emoji</span>
                    </div>
                    <div className="flex gap-3">
                      <div
                        className={clsx(
                          "size-6 rounded-full ",
                          values.format === HealthCheckAnswerType.EMOJI ? "bg-[--color-dark-easy]" : "bg-orange-500"
                        )}
                        onClick={() => {
                          setFieldValue("format", HealthCheckAnswerType.TEXT);
                        }}
                      />
                      <span className="whitespace-nowrap">Plain Text</span>
                    </div>
                  </div>
                </div>
                <div className=" flex w-full items-center">
                  {values.format === HealthCheckAnswerType.EMOJI ? (
                    <div className="ml-6 pt-[36px]">
                      <img src="/emojies.png" alt="emojis" width="150px" />
                    </div>
                  ) : (
                    <div className="pt-6">
                      <span>Awful / bad / normal / good / awesome</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-6 flex gap-4">
                <JTextArea
                  title="Health check message text"
                  required
                  id="prompt"
                  rows={2}
                  className="w-full"
                  placeholder="Please add a prompt that will be used for the Health Check message. For example: 'Simple question: How are you today?'"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between ">
            <div className="flex w-full justify-end gap-4  pt-4">
              <div className="flex gap-4">
                <Button
                  label="Cancel"
                  text
                  onClick={() => {
                    onClose?.();
                  }}
                />
                <Button
                  label={type === "create" ? "Add Health Check" : "Update Health Check"}
                  icon=""
                  type="submit"
                  disabled={!dirty}
                  onClick={() => {
                    if (!dirty) return;

                    submitForm();
                  }}
                  loading={isSubmitting || isFetching}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
