import React, { createContext, useContext } from "react";
import useTheme from "./useTheme";
import { JThemeContext } from "./types";

export const ThemeContext = createContext(null as JThemeContext | null);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeContextProvider");
  }
  return context;
};

const ThemeContextProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <ThemeContext.Provider
      value={{
        ...useTheme(),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
