import { Formik } from "formik";
import useHealthCheckContext from "./useHealthCheck";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";
import JTextArea from "@/base/JTextArea/JTextArea";
import { Button } from "primereact/button";
import { HealthCheckNotificationDetailed } from "./HealthCheck.types";

interface Props {
  notification: HealthCheckNotificationDetailed;
  onClose?: () => void;
}

export default function HealthCheckNotificationEdit({ notification, onClose }: Props) {
  const { notificationUpdate } = useHealthCheckContext();
  return (
    <Formik
      validate={(values) => {
        const errors: Record<string, string> = {};
        if (!values.welcomeMessage) {
          errors.welcomeMessage = "Required";
        }
        return errors;
      }}
      initialValues={{ welcomeMessage: notification.welcomeMessage }}
      onSubmit={async (values) => {
        try {
          await notificationUpdate(notification.id, { welcomeMessage: values.welcomeMessage });
          toast.success("Notification updated");
          onClose?.();
        } catch (e) {
          toast.error(errorOrDefault(e, "Error updating notification"));
        }
      }}
    >
      {({ submitForm }) => (
        <div>
          <JTextArea rows={12} id="welcomeMessage" title="Welcome Message" />
          <div className="flex justify-end">
            <Button onClick={submitForm}>Save</Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
