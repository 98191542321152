import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import AssetModal from "./AssetModal/AssetModal";
import { useAssetContext } from "../AssetContext";
import { ModalOperationType } from "../../../types";
import ResourceCard from "../../../components/ResourceCard";
import { AssetDTO } from "../types";
import { confirmPopup } from "primereact/confirmpopup";
import { sortByCreated_at } from "../../../utils";
import Loader from "@/base/Loader";
import { useEventsContext } from "@/containers/Events/EventsContext";
import EventsNav from "@/components/EventsNav";
import { toast } from "react-toastify";
import { useUserContext } from "@/containers/Users/UserContext";
import PageTitleWrapper from "@/components/PageTitleWrapper";

const AssetList = () => {
  const [showModal, setShowModal] = useState<ModalOperationType>(null);
  const [eventId, setEventId] = useState<string>();
  const { getAssets, assets, deleteAsset, isAssetsFetching, alreadyUsed } = useAssetContext();
  const { events, isEventsFetching } = useEventsContext({
    onSetFirstEvent: setEventId,
  });
  const { isSuperAdmin, orgSettings } = useUserContext();
  let isLimitReached = alreadyUsed >= orgSettings.messages_free_limit;

  if (isSuperAdmin) {
    isLimitReached = false;
  }

  useEffect(() => {
    if (!eventId) {
      return;
    }

    getAssets(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return (
    <div className="flex flex-col gap-4">
      <Loader isLoading={isAssetsFetching || isEventsFetching} />

      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Assets</span>
            <EventsNav events={events} setEventId={setEventId} eventId={eventId} />
          </>
        }
        row2={
          <>
            <Button
              tooltipOptions={{ position: "bottom" }}
              tooltip={isLimitReached ? "You have reached your limit. Please reach out to support" : ""}
              label="Add new asset"
              icon="pi pi-plus"
              onClick={() => {
                if (isLimitReached) {
                  return;
                }

                setShowModal("create");
              }}
            />
            {!isSuperAdmin ? (
              <div className="text-sm">
                Already used assets: {alreadyUsed}/{orgSettings.gifs_free_limit}
              </div>
            ) : (
              <div>Assets loaded: {assets?.filter((asset: AssetDTO) => asset.event.id === eventId)?.length ?? 0}</div>
            )}
          </>
        }
      />

      {assets && assets.length > 0 && (
        <div className="grid gap-4 px-2">
          {assets
            ?.filter((asset: AssetDTO) => asset.event.id === eventId)
            ?.sort(sortByCreated_at<AssetDTO>)
            ?.map((item) => (
              <div className="" key={item.id}>
                <ResourceCard
                  id={item.id}
                  url={item.s3_url}
                  onRemove={
                    !item.isShared || isSuperAdmin
                      ? (id: string, ref: HTMLDivElement) => {
                          confirmPopup({
                            target: ref,
                            message: "Are you sure you want to delete this asset?",
                            icon: "pi pi-exclamation-triangle",
                            accept: () =>
                              deleteAsset(id)
                                .then(() => {
                                  getAssets(eventId);
                                  toast.success("Assets deleted successfully");
                                })
                                .catch(() => {
                                  toast.error("Error deleting asset. It may be in use.");
                                }),
                            reject: () => null,
                          });
                        }
                      : null
                  }
                />
              </div>
            ))}
        </div>
      )}

      {showModal && eventId && (
        <AssetModal
          onClose={(typeSelectedInsideAddDialog?: string) => {
            setShowModal(null);
            typeSelectedInsideAddDialog && setEventId(typeSelectedInsideAddDialog);
          }}
          initEventId={eventId}
        />
      )}
    </div>
  );
};

export default AssetList;
