import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import UserModal from "./UserModal/UserModal";
import { JUser } from "../types";
import { useUserContext } from "../UserContext";
import { toast } from "react-toastify";
import { confirmPopup } from "primereact/confirmpopup";
import { createdAtCellRender, sortByCreatedAt, updatedAtCellRender } from "../../../utils";
import { ModalOperationType } from "../../../types";
import { Navigate } from "react-router-dom";
import Loader from "@/base/Loader";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import PageTitleWrapper from "@/components/PageTitleWrapper";

const UserList = () => {
  const [showModal, setShowModal] = useState<ModalOperationType>(null);
  const [curUser, setCurUser] = useState<JUser | null>(null);
  const [isInviteFetching, setIsInviteFetching] = useState<string | null>(null);

  const {
    getOrgManagerList,
    users,
    sendInvite,
    isOrgManagerFetching,
    removeOrgManager,
    isChannelManager,
    user,
    isSuperAdmin,
  } = useUserContext();

  const handleSendInvite = async (email: string) => {
    try {
      setIsInviteFetching(email);

      await sendInvite(email);
      toast.success("Invite sent");
    } catch (err) {
      toast.error("Error sending invite");
      console.error(err);
    } finally {
      setIsInviteFetching(null);
    }
  };

  useEffect(() => {
    getOrgManagerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orgRender = (rawData: JUser) => {
    // eslint-disable-next-line tailwindcss/no-custom-classname
    return <div className=" flex">{rawData?.organization?.title}</div>;
  };

  const statusCellRender = (rawData: JUser) => {
    return <>{rawData.status === "INACTIVE" ? "Inactive" : "Active"}</>;
  };

  const operationsCellRender = (rawData: JUser) => {
    return (
      <div className="flex items-center gap-4">
        <Edit
          className="cursor-pointer"
          onClick={() => {
            setCurUser(rawData);
            setShowModal("update");
          }}
        />

        <Trash
          className="cursor-pointer"
          onClick={() => {
            const confirmationMessageRef = document.getElementById("confirmMessage");
            if (!confirmationMessageRef) {
              console.error("Confirmation message is not defined");
              return;
            }

            confirmPopup({
              target: confirmationMessageRef,
              message: "Are you sure you want to delete this manager?",
              icon: "pi pi-exclamation-triangle",
              acceptClassName: "p-button-danger",
              accept: async () => {
                try {
                  await removeOrgManager(rawData.id);
                  toast.success("Manager removed");
                } catch (err) {
                  toast.error("Error removing manager");
                  console.error(err);
                }
              },
            });
          }}
        />

        {rawData.status === "INACTIVE" && (
          <Button
            size="small"
            className="mr-2 w-[150px]"
            onClick={() => handleSendInvite(rawData.email)}
            loading={rawData?.email === isInviteFetching}
            severity="secondary"
          >
            Send invite
          </Button>
        )}
      </div>
    );
  };

  const roleRender = (rawData: JUser) => (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className=" flex">
      {rawData.type === "SUADMIN" && "Super Admin"}
      {rawData.type === "ADMIN" && "Org. manager"}
      {rawData.type === "USERS" && "Channel manager"}
    </div>
  );

  if (isChannelManager) {
    return <Navigate to={"/"} state={{ returnUrl: window.location.pathname }} replace />;
  }

  return (
    <div>
      <Loader isLoading={isOrgManagerFetching} />
      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Events</span>
          </>
        }
        row2={
          !isChannelManager && (
            <Button
              label={"Add new " + (isSuperAdmin ? "org. manager" : "channel manager")}
              icon="pi pi-plus"
              onClick={() => {
                setShowModal("create");
              }}
              severity="success"
              className="ml-5"
            />
          )
        }
      />

      <DataTable value={users.sort(sortByCreatedAt<JUser>).filter((u) => u.id !== user?.id) || []}>
        <Column field="email" header="Email"></Column>
        <Column field="firstName" header="First name"></Column>
        <Column field="lastName" header="Last name"></Column>
        <Column header="Status" body={statusCellRender}></Column>
        <Column field="type" header="Type" body={roleRender}></Column>
        {isSuperAdmin && <Column header="Organization" body={orgRender}></Column>}
        <Column field="createdAt" header="Created at" body={createdAtCellRender}></Column>
        <Column field="updatedAt" header="Updated at" body={updatedAtCellRender}></Column>
        <Column header="Operations" body={operationsCellRender} className="w-[300px]"></Column>
      </DataTable>
      {showModal && (
        <UserModal
          onClose={() => {
            setShowModal(null);
            setCurUser(null);
          }}
          formType={showModal}
          user={curUser}
        />
      )}
    </div>
  );
};

export default UserList;
