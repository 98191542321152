import React, { createContext, useContext } from "react";
import useUser from "./useUser";
import { JUserContext } from "./types";

export const UserContext = createContext(null as JUserContext | null);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

const UserContextProvider = ({ children }: React.PropsWithChildren) => {
  const data = useUser();
  return (
    <UserContext.Provider
      value={{
        ...data,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
