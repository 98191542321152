import ReactDOM from "react-dom/client";
import App from "./containers/App/App";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./theme/theme.css";
import "./theme/theme.scss";
import "primereact/resources/themes/bootstrap4-dark-purple/theme.css";
import "./config";

const root = document.getElementById("root");

root && ReactDOM.createRoot(root).render(<App />);
