import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useCallback, useEffect } from "react";
import Loader from "@/base/Loader";
import PageTitleWrapper from "@/components/PageTitleWrapper";
import { useUserContext } from "../Users/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconChevronLeft, IconChevronRight, IconSend } from "@tabler/icons-react";
import ImportTtnsFromCSV from "./ImportTtnsFromCSV";
import { Tooltip } from "primereact/tooltip";
import { Ttn } from "./types";
import { errorOrDefault, imageCellRender } from "@/utils";
import { useTalksContext } from "./TalksContext";
import { TtnPersonalMessageStatusReadable, TtnStatusReadable } from "./constants";

const TTnsList = () => {
  const { id: talkId } = useParams();
  const navigate = useNavigate();
  const { isFetching, ttns, getTtns, getTalk, talk, sendAllTtnsMessages, sendDirectTtnMessages } = useTalksContext();
  const { isChannelManager } = useUserContext();

  useEffect(() => {
    if (!talkId) {
      toast.error("Talk id is required");
      navigate("/talks");
      return;
    }

    getTalk(talkId);
    getTtns(talkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controlBodyTemplate = useCallback(
    (rowData: Ttn) => {
      return (
        <div className="group flex items-center gap-4">
          <>
            <Tooltip target="#send" position="bottom" content="Send notification" />
            <div id="send">
              <IconSend
                className="size-[16px] cursor-pointer text-[var(--color-fg-darker)] group-hover:visible"
                onClick={async () => {
                  try {
                    if (!talkId) {
                      toast.error("Talk id is required");
                      return;
                    }
                    await sendDirectTtnMessages(talkId, rowData.id);
                    toast.success("Messages sent successfully");
                    await getTtns(talkId);
                  } catch (e) {
                    toast.error(errorOrDefault(e, "Error sending messages"));
                  }
                }}
              ></IconSend>
            </div>
          </>
        </div>
      );
    },
    [getTtns, sendDirectTtnMessages, talkId]
  );

  return (
    <div>
      <Loader isLoading={isFetching} />

      <PageTitleWrapper
        row1={
          <span className="flex flex-row items-center justify-center gap-4">
            <span
              className="flex size-[40px] cursor-pointer items-center justify-center rounded-full border"
              onClick={() => {
                navigate("/talks");
              }}
            >
              <IconChevronLeft />
            </span>
            <span className="flex flex-row items-center justify-center gap-2 text-[40px]">
              Talk <IconChevronRight /> {talk?.title}
            </span>
          </span>
        }
        row2={
          !isChannelManager && (
            <span className="flex flex-row items-center gap-2">
              <ImportTtnsFromCSV />
              {ttns && ttns?.length > 0 && (
                <Button
                  label="Send all"
                  icon="pi pi-send"
                  onClick={() => {
                    if (!talkId) {
                      toast.error("Talk id is required");
                      return;
                    }

                    sendAllTtnsMessages(talkId);
                    toast.success("All messages sent");
                  }}
                  className="ml-5"
                />
              )}
            </span>
          )
        }
      />

      <DataTable value={ttns || []}>
        <Column
          field="destination.destinationData.name"
          header="Team mate name"
          body={(data: Ttn) => {
            const { imageUrl, name } = data.destination.destinationData;
            return (
              <span className="flex items-center gap-2">
                {imageCellRender({
                  imageUrl,
                  name,
                })}
                {name}
              </span>
            );
          }}
        ></Column>
        <Column field="destination.destinationData.email" header="Team mate email"></Column>
        <Column field="number" header="Tracking number"></Column>
        <Column field="provider" header="Provider"></Column>
        <Column header="Delivery status" body={(data: Ttn) => TtnStatusReadable[data.status]}></Column>
        <Column
          header="Message status"
          body={(data: Ttn) => TtnPersonalMessageStatusReadable[data.personalMessageStatus]}
        ></Column>
        <Column body={controlBodyTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default TTnsList;
