import { Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useHealthCheckContext from "./useHealthCheck";
import { useState } from "react";
import JInputText from "@/base/JInputText";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";
import { IconSettings } from "@tabler/icons-react";

export default function CustomControl() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { customQueueReset } = useHealthCheckContext();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  return (
    <div>
      <IconSettings
        className=" absolute bottom-[50px] right-[50px] z-50 size-[20px] cursor-pointer text-[var(--color-fg-darker)]"
        onClick={handleModalOpen}
      />
      <Formik
        initialValues={{
          time: "12:00",
        }}
        onSubmit={async (values) => {
          try {
            const timeSplit: [string, string] = values.time.split(":") as [string, string];
            const hours = parseInt(timeSplit[0]);
            const minutes = parseInt(timeSplit[1]);
            await customQueueReset({
              startHours: hours,
              startMinutes: minutes,
            });
            setShowModal(false);
            toast.success("Queue reset successfully");
          } catch (e) {
            toast.error(errorOrDefault(e, "Error resetting queue"));
          }
        }}
      >
        {({ submitForm }) => (
          <Dialog
            header="Custom Queue Settings"
            visible={showModal}
            onHide={handleModalClose}
            footer={() => (
              <div>
                <Button onClick={submitForm}>Start</Button>
              </div>
            )}
          >
            <JInputText className="w-[100px]" type="time" title="Time" id="time" />
          </Dialog>
        )}
      </Formik>
    </div>
  );
}
