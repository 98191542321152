import { useState } from "react";

const useTheme = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarIsOpen((sidebarIsOpen) => !sidebarIsOpen);
  };

  return {
    sidebarIsOpen,
    toggleSidebar,
  };
};

export default useTheme;
