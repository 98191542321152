import axios from "axios";
import { JDestinationPatchBody, SlackChannelsResponse } from "./types";

export default class DestinationService {
  public static get = async (page: number, limit: number, search: string) =>
    axios.get("/destinations", { params: { page, limit, search } });
  public static update = async (id: string, body: JDestinationPatchBody) => axios.patch(`/destinations/${id}`, body);
  public static importCSV = async (formData: FormData, channelId: string) =>
    axios.post("/destinations/import/" + channelId, formData);
  public static importBambooHr = async () => axios.post("/destinations/importBambooHr", {});
  public static getSlackChannelsInfo = async () =>
    axios.get<SlackChannelsResponse>("/destinations/getSlackChannelsInfo", {});
}
