import { Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import * as Yup from "yup";
import { OperationType } from "./EventsList";
import { toast } from "react-toastify";
import { getFormikFromRef } from "@/utils";
import JDropdown from "@/base/JDropdown/JDropdown";
import { CustomOccurrenceProps, FormValues, occurrenceTypesChoose } from "./types";
import { Calendar } from "primereact/calendar";
import { EventDTO } from "./types";
import { useEventsContext } from "./EventsContext";
import JInputText from "@/base/JInputText";
import { EventType, FeatureEventType, OccurrenceType } from "@/types";
import ChannelAutocompleteMultipleSelectInput from "@/components/ChannelAutocompleteMultipleSelectInput";
import OrganizationsMultipleSelectInput from "@/components/OrganizationsMultipleSelectInput";
import { useUserContext } from "../Users/UserContext";
import OccurrenceTypeCustomDates from "./OccurrenceTypeCustomDates";
import { getTime, setTime } from "../Settings/utils";

export const validationSchema = (isPersonalized: boolean) =>
  isPersonalized
    ? Yup.object().shape({
        description: Yup.string(),
        channelIds: Yup.array().of(Yup.string()).optional(),
        organizationIds: Yup.array().of(Yup.string()).optional(),
      })
    : Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string(),
        occurrenceType: Yup.string().required(),
        startDate: Yup.date().required(),
        repeatEvery: Yup.number().min(1).optional(),
        repeatOnWeekDays: Yup.array().of(Yup.number().min(0).max(6)).optional(),
        repeatType: Yup.string().optional(),
        repeatEachMonthDay: Yup.number().min(1).max(31).optional(),
        channelIds: Yup.array().of(Yup.string()).optional(),
        organizationIds: Yup.array().of(Yup.string()).optional(),
      });

const EventsModal = ({
  item,
  onClose,
  formType = "create",
}: {
  item?: EventDTO | null;
  onClose: () => void;
  formType?: OperationType;
}) => {
  const { isEventsFetching, addEvent, getEvents, updateEvent } = useEventsContext();
  const { user } = useUserContext();
  const isSuperAdmin = user?.isSuperAdmin;
  const isOrgManager = user?.isOrgManager;
  const allowEdit = isSuperAdmin || isOrgManager;
  const isNew = formType === "create";
  const isPersonalized = item?.type === EventType.PERSONAL;
  const isOnboardingEvent = item?.featureEventType === FeatureEventType.ONBOARDING;
  const isPersonalizedOrOnboarding = isPersonalized || isOnboardingEvent;

  const formikRef = useRef(null);

  const onSubmit = async (values: FormValues) => {
    try {
      const { startTime, ...restValues } = values;
      const { hour, minute } = getTime(startTime);
      const newValues = {
        ...restValues,
        startHour: hour ?? null,
        startMin: minute ?? null,
      };
      if (isNew) {
        await addEvent({ ...newValues });
        toast.success("Event added");
        getEvents({
          globalOnly: !isSuperAdmin,
        });
        onClose();
        return;
      }

      if (!item?.id) {
        throw new Error("Event id is not defined");
      }

      await updateEvent(item.id, { ...newValues });
      toast.success("Event updated");
      getEvents({
        globalOnly: !isSuperAdmin,
      });
      onClose();
    } catch (err) {
      console.error(err);
      const errorMessage = err ?? isNew ? "Error adding event" : "Error updating event";
      toast.error(errorMessage);
    }
  };

  const handleCustomOccurrencePropsChange = (props: Partial<CustomOccurrenceProps>) => {
    if (!isSuperAdmin && !isOrgManager) {
      return;
    }

    const formik = getFormikFromRef<FormValues>(formikRef);
    formik.setValues({
      ...formik.values,
      ...props,
    });
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        label={isNew ? "Add" : "Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = getFormikFromRef<FormValues>(formikRef);
          formik.submitForm();
        }}
        loading={isEventsFetching}
      />
    </div>
  );

  return (
    <Dialog header={isNew ? "Add new event" : "Edit event"} visible={true} onHide={onClose} footer={footerContent}>
      <Formik<FormValues>
        innerRef={formikRef}
        initialValues={{
          title: item?.title ?? "",
          description: item?.description || undefined,
          occurrenceType: item?.occurrenceType || undefined,
          startDate: item?.startDate || new Date(),
          channelIds: (isOrgManager && item?.eventChannels?.map((c) => c?.channel?.id).filter((c) => !!c)) || [],
          organizationIds: (isSuperAdmin && item?.organizations?.map((o) => o.id)) || [],
          repeatEvery: item?.repeatEvery || 1,
          repeatOnWeekDays: item?.repeatOnWeekDays || [],
          repeatType: item?.repeatType || "WEEK",
          repeatEachMonthDay: item?.repeatEachMonthDay || undefined,
          startTime: setTime({ hour: item?.startHour, minute: item?.startMin }),
        }}
        validationSchema={validationSchema(isPersonalizedOrOnboarding)}
        onSubmit={onSubmit}
      >
        {(props) =>
          isPersonalizedOrOnboarding ? (
            <>
              <div
                className="gap-4 py-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: "49% 49%",
                }}
              >
                <JInputText disabled={true} id="title" title="Title" placeholder="Title" className="w-full" />
                {isOnboardingEvent && (
                  <div className="flex flex-col gap-2 ">
                    <JInputText disabled={!isOrgManager} id="startTime" title="Send at" className="w-8" type="time" />
                  </div>
                )}
                {isOrgManager && (
                  <div
                    className="flex flex-col gap-2 "
                    style={{
                      gridColumnStart: 1,
                      gridColumnEnd: -1,
                    }}
                  >
                    <span className="font-bold">Select channel</span>
                    <ChannelAutocompleteMultipleSelectInput
                      currentChannelIds={props.values.channelIds}
                      onChange={(channelIds) => {
                        props.setFieldValue(
                          "channelIds",
                          channelIds.map((channel) => channel.id)
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                className="gap-4 py-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: "49% 49%",
                }}
              >
                <JInputText disabled={!allowEdit} id="title" title="Title" placeholder="Title" className="w-full" />
                <JInputText
                  disabled={!allowEdit}
                  id="description"
                  title="Description"
                  placeholder="Description"
                  className="w-full"
                />
                <JDropdown
                  disabled={!allowEdit}
                  id="occurrenceType"
                  title="Occurrence Type"
                  options={occurrenceTypesChoose}
                  optionLabel="name"
                  optionValue="code"
                  onChange={(e) => {
                    props.setFieldValue("occurrenceType", e.value);
                  }}
                />
                <div className="flex flex-col gap-2 ">
                  <span className="font-bold">Start date</span>
                  <Calendar
                    disabled={!allowEdit}
                    placeholder="Start date"
                    value={new Date(props.getFieldMeta("startDate").value as Date)}
                    onChange={(e) => {
                      if (!e.value) {
                        console.error("Invalid start time");
                        return;
                      }
                      const time = new Date(e.value);
                      time.setHours(12, 0, 0, 0);
                      props.setFieldValue("startDate", time);
                    }}
                    showTime={false}
                  />
                </div>
                {isSuperAdmin && (
                  <div className="flex flex-col justify-between gap-2 ">
                    <span className="font-bold">Select organization</span>
                    <OrganizationsMultipleSelectInput
                      currentOrgIds={props.values.organizationIds}
                      onChange={(orgIds) => {
                        props.setFieldValue(
                          "organizationIds",
                          orgIds.map((org) => org.id)
                        );
                      }}
                    />
                  </div>
                )}
                {isOrgManager && (
                  <div
                    className="flex flex-col gap-2 "
                    style={{
                      gridColumnStart: 1,
                      gridColumnEnd: -1,
                    }}
                  >
                    <span className="font-bold">Select channel</span>
                    <ChannelAutocompleteMultipleSelectInput
                      currentChannelIds={props.values.channelIds}
                      onChange={(channelIds) => {
                        props.setFieldValue(
                          "channelIds",
                          channelIds.map((channel) => channel.id)
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="px-4 pt-4">
                {props.values.occurrenceType === OccurrenceType.CUSTOM && (
                  <OccurrenceTypeCustomDates
                    initProps={{
                      repeatEvery: props.values.repeatEvery,
                      repeatOnWeekDays: props.values.repeatOnWeekDays,
                      repeatType: props.values.repeatType,
                      repeatEachMonthDay: props.values.repeatEachMonthDay,
                    }}
                    onChange={handleCustomOccurrencePropsChange}
                  />
                )}
              </div>
            </>
          )
        }
      </Formik>
    </Dialog>
  );
};

export default EventsModal;
