import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import useEvents from "./useEvents";

export const EventsContext = createContext<ReturnType<typeof useEvents> | null>(null);

export const useEventsContext = ({ onSetFirstEvent }: { onSetFirstEvent?: (eventId: string) => void } = {}) => {
  const context = useContext(EventsContext);

  useEffect(() => {
    onSetFirstEvent &&
      context?.getEvents({
        onSetFirstEvent,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!context) {
    throw new Error("useEventsContext must be used within a DestinationsContextProvider");
  }

  return context;
};

const EventsContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <EventsContext.Provider
      value={{
        ...useEvents(),
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export default EventsContextProvider;
