import { Form, Formik, FormikProps } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";
import JDropdown from "../../../../base/JDropdown/JDropdown";
import JInputText from "../../../../base/JInputText";
import { useUserContext } from "../../UserContext";
import { JUser, UserEditForm } from "../../types";
import { userSchemaAdd, userSchemaUpdate } from "../../../../schemas";
import { ModalOperationType } from "../../../../types";
import { toast } from "react-toastify";

const OrgModal = ({
  user,
  onClose,
  formType = "create",
}: {
  user: JUser | null;
  onClose: () => void;
  formType?: ModalOperationType;
}) => {
  const { isOrgsFetching, addUser, updateOrgManager, getOrgs, orgs, isSuperAdmin } = useUserContext();
  const isNew = formType === "create";

  const formikRef = useRef(null);

  const types = [
    {
      name: "Channel manager",
      code: "USERS",
    },
    ...(isSuperAdmin ? [{ name: "Org. manager", code: "ADMIN" }] : []),
  ];

  const onSubmit = async (values: UserEditForm) => {
    try {
      if (isNew) {
        await addUser({ ...values, email: values.email.trim() });
        toast.success("Org. manager added");
      }

      if (!isNew) {
        if (!user?.id) {
          throw new Error("User is not defined");
        }
        await updateOrgManager({
          ...user,
          ...values,
          email: values?.email?.trim() || user.email,
          organizationId: values.organizationId ?? user.organizationId,
        });
        toast.success("Org. manager updated");
      }
      onClose();
    } catch (err) {
      console.error(err);
      toast.error(isNew ? "Error adding org. manager" : "Error updating org. manager");
    }
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        label={isNew ? "Add" : "Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = formikRef?.current as FormikProps<UserEditForm> | null;
          if (formik) {
            formik.submitForm();
          }
        }}
        loading={isOrgsFetching}
      />
    </div>
  );

  useEffect(() => {
    isSuperAdmin && getOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const managerText = `${isSuperAdmin ? "org. manager" : "channel manager"}`;

  return (
    <Dialog
      header={isNew ? `Add new ${managerText}` : `Edit ${managerText}`}
      visible={true}
      onHide={onClose}
      footer={footerContent}
    >
      <Formik<UserEditForm>
        innerRef={formikRef}
        initialValues={{
          status: user?.status ?? "INACTIVE",
          type: user?.type ?? isSuperAdmin ? "ADMIN" : "USERS",
          firstName: user?.firstName ?? "",
          lastName: user?.lastName ?? "",
          email: user?.email ?? "",
          organizationId: user?.organization?.id ?? null,
        }}
        validationSchema={isNew ? userSchemaAdd(isSuperAdmin) : userSchemaUpdate(isSuperAdmin)}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="flex flex-col gap-4 py-4">
              <JInputText id="firstName" title="First name" className="w-full" />
              <JInputText id="lastName" title="Last name" className="w-full" />
              <JInputText id="email" title="Email" className="w-full" />
              <JDropdown
                id="type"
                title="Role"
                className="w-full"
                options={types.map((role) => ({ name: role.name, code: role.code }))}
                optionLabel="name"
                optionValue="code"
                showClear={false}
              />
              {isSuperAdmin && (
                <JDropdown
                  id="organizationId"
                  title="Organization"
                  className="w-full"
                  options={orgs.map((org) => ({ name: org.title, code: org.id }))}
                  optionLabel="name"
                  optionValue="code"
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default OrgModal;
