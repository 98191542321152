import { useEffect, useState } from "react";
import useHealthCheckContext from "./useHealthCheck";
import Loader from "@/base/Loader";
import HealthCheckReportAllNotifs from "./HealthCheckReportAllNotifs";
import clsx from "clsx";
import HealthCheckReportByHealthCheck from "./HealthCheckReportByHealthCheck";

type Props = {
  healthCheckId: string;
};

type ReportType = "byTeamMate" | "byHealthCheck";

export default function HealthCheckReport({ healthCheckId }: Props) {
  const { isFetching, getAllHealthCheckNotificationsDetailed, allHealthCheckNotificationsDetailed } =
    useHealthCheckContext();
  const [reportType, setReportType] = useState<ReportType>("byTeamMate");

  const toggleReportType = () => {
    setReportType((prev) => (prev === "byTeamMate" ? "byHealthCheck" : "byTeamMate"));
  };

  useEffect(() => {
    getAllHealthCheckNotificationsDetailed();
  }, [getAllHealthCheckNotificationsDetailed]);

  if (isFetching) {
    return <Loader isLoading={isFetching} />;
  }

  if (!allHealthCheckNotificationsDetailed) {
    return <div>No health checks</div>;
  }

  return (
    <div className="flex flex-col gap-6">
      <Loader isLoading={isFetching} />
      <div className="flex items-center gap-4">
        <div className="flex cursor-pointer items-center gap-2" onClick={() => toggleReportType()}>
          <div
            className={clsx(
              "rounded-full border border-transparent bg-[--color-dark] p-[2px]",
              reportType === "byHealthCheck" && "border-orange-500 "
            )}
          >
            <div
              className={clsx(
                "size-[20px] rounded-full  bg-[--color-dark] p-px",
                reportType === "byHealthCheck" && "bg-orange-500"
              )}
            ></div>
          </div>
          <span>By health check</span>
        </div>
        <div className="flex cursor-pointer items-center gap-2" onClick={() => toggleReportType()}>
          <div
            className={clsx(
              "rounded-full border border-transparent bg-[--color-dark] p-[2px]",
              reportType === "byTeamMate" && "border-orange-500 "
            )}
          >
            <div
              className={clsx(
                "size-[20px] rounded-full  bg-[--color-dark] p-px",
                reportType === "byTeamMate" && "bg-orange-500"
              )}
            ></div>
          </div>
          <span>By team mate</span>
        </div>
      </div>
      {reportType === "byTeamMate" && (
        <HealthCheckReportAllNotifs
          allHealthCheckNotificationsDetailed={allHealthCheckNotificationsDetailed}
          healthCheckId={healthCheckId}
        />
      )}
      {reportType === "byHealthCheck" && (
        <HealthCheckReportByHealthCheck
          allHealthCheckNotificationsDetailed={allHealthCheckNotificationsDetailed}
          healthCheckId={healthCheckId}
        />
      )}
    </div>
  );
}
