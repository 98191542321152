import { useMemo, useState } from "react";
import DashboardService from "./api";
import { JDashboard } from "./types";

const useDashboard = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<JDashboard["dashboard"] | null>(null);

  const getDashboard: JDashboard["getDashboard"] = async () => {
    try {
      setIsFetching(true);
      const response = await DashboardService.get();
      setDashboard(response.data);
    } finally {
      setIsFetching(false);
    }
  };

  return useMemo(
    () => ({
      isFetching,
      dashboard,
      getDashboard,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, dashboard]
  );
};

export default useDashboard;
