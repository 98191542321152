import { Destination, DestinationMode } from "@/types";

export interface HealthCheck {
  id: string;
  days: number[];
  format: HealthCheckAnswerType;
  prompt: string | null;
  destinations: Destination[];
  notifyHour: number;
  notifyMin: number;
  title: string;
  welcomeMessage: string | null;
  goodbyeMessage: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isActive: boolean;
}

export enum HealthCheckAnswerType {
  EMOJI = "emoji",
  TEXT = "text",
}

export type HealthCheckCreate = Omit<
  HealthCheck,
  "id" | "destinations" | "createdAt" | "deletedAt" | "updatedAt" | "isActive"
> & { destinationIds: string[] };
export type HealthCheckUpdate = Partial<HealthCheckCreate>;

export enum HealthCheckAnswerOptions {
  AWFUL = "awful",
  BAD = "bad",
  NORMAL = "normal",
  GOOD = "good",
  AWESOME = "awesome",
}

export enum HealthCheckStatus {
  ACTIVE = "active",
  COMPLETED = "completed",
  CANCELED = "canceled",
  PLANNED = "planned",
}

export enum HealthCheckAnswerStatus {
  pending = "pending",
  completed = "completed",
}

export interface HealthCheckAnswer {
  answer: HealthCheckAnswerOptions;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: HealthCheckAnswerStatus;
}

export type CustomQueueProps = { startHours?: number; startMinutes?: number };

export interface HealthCheckAnswerDetailed extends HealthCheckAnswer {
  destination: {
    id: string;
    mode: DestinationMode;
    messageSystemId: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    destinationData: {
      id: string;
      name: string;
      email: string;
      project: string;
      role: string;
      imageUrl: string;
      dateOfBirth: string;
    };
  };
}

export interface HealthCheckNotification {
  id: string;
  welcomeMessage: string;
  goodbyeMessage: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: HealthCheckStatus;
  format: HealthCheckAnswerType;
  startAt: string | null;
  endAt: string | null;
  healthCheck: HealthCheck;
  healthCheckAnswers: HealthCheckAnswer[];
}

export interface HealthCheckNotificationDetailed
  extends Omit<HealthCheckNotification, "healthCheckAnswers" | "healthCheck"> {
  healthCheckAnswers: HealthCheckAnswerDetailed[];
  healthCheck: Omit<HealthCheck, "destinations">;
}
