import { PropsWithChildren, createContext, useContext } from "react";
import useMessages from "./useMessage";
import { JMessages } from "./types";

export const MessagesContext = createContext(null as JMessages | null);

export const useMessagesContext = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error("useMessagesContext must be used within a useMessagesContextProvider");
  }
  return context;
};

const MessagesContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <MessagesContext.Provider
      value={{
        ...useMessages(),
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesContextProvider;
