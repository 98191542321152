import { HealthCheckNotificationDetailed } from "../HealthCheck.types";

type HealthCheckByRecipients = Record<string, Record<string, string>>;

export const hcReport = ({
  allHealthCheckNotificationsDetailed,
  healthCheckId,
}: {
  healthCheckId: string;
  allHealthCheckNotificationsDetailed: HealthCheckNotificationDetailed[];
}): [HealthCheckByRecipients, string[], Record<string, string>] => {
  if (!allHealthCheckNotificationsDetailed) {
    return [{}, [], {}];
  }

  let healthCheckNotificationByTitle = allHealthCheckNotificationsDetailed.filter(
    (notification) => notification.healthCheck.id === healthCheckId
  );

  if (!healthCheckNotificationByTitle || healthCheckNotificationByTitle.length === 0) {
    return [{}, [], {}];
  }

  /// Sort notif-s by startedAt
  /// -------------------------
  healthCheckNotificationByTitle = healthCheckNotificationByTitle.sort((a, b) => {
    if (!a?.startAt || !b?.startAt) {
      return 0;
    }

    return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
  });

  const dateFrom = new Date(healthCheckNotificationByTitle[0]?.startAt as string);
  const dateTo = new Date(healthCheckNotificationByTitle[healthCheckNotificationByTitle.length - 1]?.startAt as string);

  const daysBetweenDates = Math.ceil((dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24));

  const healthCheckByRecipients: HealthCheckByRecipients = {};
  const dates: string[] = [];
  const imageByRecipients: Record<string, string> = {};

  /// Fill userImageByRecipient with all possible recipients
  /// ------------------------------------------------------
  for (const notif of healthCheckNotificationByTitle) {
    for (const recipient of notif.healthCheckAnswers) {
      imageByRecipients[recipient.destination.destinationData.name] = recipient.destination.destinationData.imageUrl;
    }
  }

  /// Fill healthCheckByUser with all possible recipients
  /// ---------------------------------------------------
  for (const notif of healthCheckNotificationByTitle) {
    for (const recipient of notif.healthCheckAnswers) {
      healthCheckByRecipients[recipient.destination.destinationData.name] = {};
    }
  }

  /// Fill healthCheckByUser with with answers
  /// ----------------------------------------
  for (let day = 0; day <= daysBetweenDates; day++) {
    const date = new Date(dateFrom);
    date.setDate(date.getDate() + day);
    const dateWithoutTime = new Date(date);
    dateWithoutTime.setHours(0, 0, 0, 0);
    const dateWithoutTimeStr = dateWithoutTime.toLocaleString("en-US").split(",")[0] as string;
    dates.push(dateWithoutTimeStr);

    const notificationByDay = healthCheckNotificationByTitle.find(
      (notification) => new Date(notification?.startAt as string).getDate() === date.getDate()
    );

    for (const recipName of Object.keys(healthCheckByRecipients)) {
      if (!healthCheckByRecipients?.[recipName]) {
        healthCheckByRecipients[recipName] = {
          [dateWithoutTimeStr]: "",
        };
      }

      healthCheckByRecipients[recipName] = {
        ...healthCheckByRecipients[recipName],
        [dateWithoutTimeStr]:
          notificationByDay?.healthCheckAnswers?.find((a) => a.destination.destinationData.name === recipName)
            ?.answer || "",
      };
    }
  }

  return [healthCheckByRecipients, dates, imageByRecipients];
};
