import { Field, FieldInputProps, FieldMetaProps } from "formik";
import { InputText, InputTextProps } from "primereact/inputtext";
import { Password, PasswordProps } from "primereact/password";
import { classNames } from "primereact/utils";
import styles from "../base.module.scss";

interface JInputTextProps {
  noLabel?: boolean;
  sublabel?: string;
  maxChars?: number;
  id: string;
  title: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  type?: InputTextProps["type"];
  defaultValue?: string;
  obfuscateIfNoValue?: boolean;
  inputType?: "text" | "password";
  inputProps?: InputTextProps | PasswordProps;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  required?: boolean;
}

export default function JInputText(props: JInputTextProps) {
  const InputComponent = props.inputType === "password" ? Password : InputText;

  const inputProps =
    props.inputType === "password"
      ? { feedback: false, toggleMask: true, ...props.inputProps }
      : { ...props.inputProps };

  return (
    <Field name={props.id}>
      {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
        <div
          className={classNames(styles.input, "field", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.title && !props.noLabel && (
            <label htmlFor={props.id}>
              {props.title} {props.required && <span className="text-red-500">*</span>}
            </label>
          )}
          {props.sublabel && <span className={classNames("sublabel")}>{props.sublabel}</span>}
          <InputComponent
            id={props.id}
            placeholder={props.placeholder}
            disabled={props.disabled}
            type={props.type || "text"}
            className={props.className}
            defaultValue={props.defaultValue}
            autoComplete={props.autoComplete}
            {...field}
            {...(props.onChange && { onChange: props.onChange })}
            {...(inputProps as typeof InputText)}
            {...(props.obfuscateIfNoValue && !field.value && { placeholder: "👍🏻".repeat(1) })}
          />
          {props.maxChars && (
            <div className={classNames("charCount p-text-right")}>
              {props.maxChars - field.value.length} characters left
            </div>
          )}
          {meta.touched && meta.error && <sub className="p-1 text-pink-600">{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
