import { Button } from "primereact/button";
import { useContext } from "react";
import { context } from "./SetupWizard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  onNext?: () => Promise<void>;
  isValid?: boolean;
  noSkip?: boolean;
}

export default function Navigation({ onNext, isValid, noSkip }: Props) {
  const navigate = useNavigate();
  const { onNextStep, onClose, step, isFinalStep, isFirstStep, onPrevStep } = useContext(context);
  return (
    <div className="flex justify-between">
      {!isFinalStep && !isFirstStep && !noSkip ? (
        <Button
          label="Skip"
          text
          onClick={async () => {
            onNextStep && (await onNextStep());
          }}
        />
      ) : (
        <div> </div>
      )}
      <div className="flex gap-4">
        {!isFinalStep && step === 0 && <Button severity="success" label="Cancel" onClick={onClose} />}
        {!isFinalStep && step > 0 && <Button text label="Back" onClick={onPrevStep} />}
        {isFinalStep ? (
          <Button
            label="Done"
            onClick={async () => {
              try {
                onNext && (await onNext());
                onClose();
                navigate("/team");
              } catch (e) {
                toast.error(e);
              }
            }}
          />
        ) : (
          <Button
            label="Next"
            disabled={!isValid}
            onClick={async () => {
              return isValid && onNextStep(onNext);
            }}
          />
        )}
      </div>
    </div>
  );
}
