import { useCallback, useEffect, useMemo } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useUserContext } from "@/containers/Users/UserContext";
import { DropdownOption } from "@/types";

interface Props {
  onChange: (orgIds: DropdownOption[]) => void;
  currentOrgIds: string[];
}

export default function OrganizationsMultipleSelectInput({ onChange, currentOrgIds }: Props) {
  const { orgs, isOrgsFetching, user, getOrgs } = useUserContext();

  useEffect(
    () => {
      getOrgs();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isSuperAdmin = user?.isSuperAdmin;

  const mappedOrgs = useMemo(
    () => [
      ...orgs.map((org) => ({
        id: org.id,
        name: org.title,
      })),
    ],
    [orgs]
  );

  const curOrgs = useMemo(
    () => mappedOrgs.filter((org) => currentOrgIds.includes(org.id)),
    [mappedOrgs, currentOrgIds]
  );

  const handleChange = useCallback(
    (e: MultiSelectChangeEvent) => {
      const newSelectedOgs: DropdownOption[] = e.value;
      onChange(newSelectedOgs);
    },
    [onChange]
  );

  if (!isSuperAdmin) {
    return null;
  }

  if (isOrgsFetching) {
    return <ProgressSpinner />;
  }

  return (
    <MultiSelect
      className="w-full"
      value={curOrgs}
      options={mappedOrgs}
      maxSelectedLabels={20}
      onChange={handleChange}
      optionLabel="name"
      placeholder="Select organization"
      display="chip"
    />
  );
}
