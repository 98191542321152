import TypeSelector from "@/base/TypeSelector";
import { EventDTO } from "@/containers/Events/types";

interface Props {
  events: EventDTO[] | null;
  setEventId: (eventId: string) => void;
  eventId?: string | null;
}

export default function EventsNav({ events, setEventId: setType, eventId }: Props) {
  return (
    <div className="flex size-full flex-row items-center overflow-x-auto">
      {events?.map((event) => (
        <TypeSelector key={event.title} isActive={eventId === event.id} onClick={() => setType(event.id)}>
          {event.title}
        </TypeSelector>
      ))}
    </div>
  );
}
