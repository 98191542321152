import { Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { OperationType } from "../OrgList";
import { OrgAddForm, OrganizationDTO } from "../../../Users/types";
import { useUserContext } from "../../../Users/UserContext";
import JInputText from "../../../../base/JInputText";
import { toast } from "react-toastify";
import { getFormikFromRef } from "@/utils";
import { InputSwitch } from "primereact/inputswitch";
import { OrganizationSettingKey } from "@/types";

export const OrganizationSchema = Yup.object().shape({
  title: Yup.string().required(),
});

const OrgModal = ({
  org,
  onClose,
  formType = "create",
}: {
  org?: OrganizationDTO | null;
  onClose: () => void;
  formType?: OperationType;
}) => {
  const { isOrgsFetching, addOrg, updateOrg, getOrgById, saveOrgSettings } = useUserContext();
  const [curOrgData, setCurOrgData] = useState<OrganizationDTO | null>(null);

  const updateCurOrgData = () => {
    if (org?.id) {
      getOrgById(org.id).then((res) => {
        setCurOrgData(res);
      });
    }
  };

  useEffect(() => {
    updateCurOrgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.id]);

  const isNew = formType === "create";

  const formikRef = useRef(null);

  const toggleCustomEvents = async () => {
    if (!org?.id) {
      throw new Error("Organization id is not defined");
    }

    await saveOrgSettings(
      OrganizationSettingKey.FEATURE_FLAG_SHARED_CUSTOM_EVENT,
      (!curOrgData?.formattedOrgSettings.feature_flag_shared_custom_event).toString(),
      org.id
    );
    await updateCurOrgData();
  };

  const toggleHealthCheck = async () => {
    if (!org?.id) {
      throw new Error("Organization id is not defined");
    }

    await saveOrgSettings(
      OrganizationSettingKey.FEATURE_FLAG_HEALTH_CHECK,
      (!curOrgData?.formattedOrgSettings.feature_flag_health_check).toString(),
      org.id
    );
    await updateCurOrgData();
  };

  const toggleOnboardingMessages = async () => {
    if (!org?.id) {
      throw new Error("Organization id is not defined");
    }

    await saveOrgSettings(
      OrganizationSettingKey.FEATURE_FLAG_ONBOARDING_MESSAGE,
      (!curOrgData?.formattedOrgSettings.feature_flag_onboarding_message).toString(),
      org.id
    );
    await updateCurOrgData();
  };

  const toggleTtnMessages = async () => {
    if (!org?.id) {
      throw new Error("Organization id is not defined");
    }

    await saveOrgSettings(
      OrganizationSettingKey.FEATURE_FLAG_TTN,
      (!curOrgData?.formattedOrgSettings.feature_flag_ttn).toString(),
      org.id
    );
    await updateCurOrgData();
  };

  const togglePersonalizedMessages = async () => {
    if (!org?.id) {
      throw new Error("Organization id is not defined");
    }

    await saveOrgSettings(
      OrganizationSettingKey.FEATURE_FLAG_PERSONALIZED_MESSAGE,
      (!curOrgData?.formattedOrgSettings.feature_flag_personalized_message).toString(),
      org.id
    );
    await updateCurOrgData();
  };

  const toggleGlobalNotifications = async () => {
    if (!org?.id) {
      throw new Error("Organization id is not defined");
    }

    await saveOrgSettings(
      OrganizationSettingKey.IS_NOTIFICATIONS_ENABLED,
      (!curOrgData?.formattedOrgSettings.is_notifications_enabled).toString(),
      org.id
    );
    await updateCurOrgData();
  };

  const onSubmit = async (values: OrgAddForm) => {
    try {
      if (isNew) {
        await addOrg({ ...values });
        toast.success("Organization added");
        onClose();
        return;
      }

      if (!org?.id) {
        throw new Error("Organization id is not defined");
      }

      await updateOrg({ id: org.id, ...values } as OrganizationDTO);
      toast.success("Organization updated");
      onClose();
    } catch (err) {
      console.error(err);
      let errorMessage = isNew ? "Error adding organization" : "Error updating organization";

      if (typeof err === "string") {
        errorMessage = err;
      }

      toast.error(errorMessage);
    }
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        label={isNew ? "Add" : "Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = getFormikFromRef<OrgAddForm>(formikRef);
          formik.submitForm();
        }}
        loading={isOrgsFetching}
      />
    </div>
  );

  return (
    <Dialog
      header={isNew ? "Add new organization" : "Edit organization"}
      visible={true}
      onHide={onClose}
      footer={footerContent}
    >
      <Formik<OrgAddForm>
        innerRef={formikRef}
        initialValues={{
          title: org?.title || "",
          numberOfEmployees: org?.numberOfEmployees || 1,
        }}
        validationSchema={OrganizationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <div className="py-4">
            <JInputText id="title" title="Title" placeholder="Title" className="w-full" />
            {!isNew && (
              <div className="flex flex-col gap-4">
                <span className="py-4 text-xl ">Settings</span>
                <div className="grid grid-cols-2 grid-rows-2 justify-between gap-y-8 px-2 [&>div]:flex [&>div]:w-2/5 [&>div]:justify-between [&>div]:self-start">
                  <div className="flex gap-4">
                    <div className="w-max">Notification status</div>
                    <InputSwitch
                      tooltip="Notification status"
                      tooltipOptions={{ position: "left" }}
                      checked={!!curOrgData?.formattedOrgSettings.is_notifications_enabled}
                      onChange={toggleGlobalNotifications}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="w-max ">Custom events</div>
                    <InputSwitch
                      tooltip="Custom events"
                      tooltipOptions={{ position: "left" }}
                      checked={!!curOrgData?.formattedOrgSettings.feature_flag_shared_custom_event}
                      onChange={toggleCustomEvents}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="w-max ">Personalized messages</div>
                    <InputSwitch
                      tooltip="Personalized messages"
                      tooltipOptions={{ position: "left" }}
                      checked={!!curOrgData?.formattedOrgSettings.feature_flag_personalized_message}
                      onChange={togglePersonalizedMessages}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="w-max ">Health check</div>
                    <InputSwitch
                      tooltip="Health check"
                      tooltipOptions={{ position: "left" }}
                      checked={!!curOrgData?.formattedOrgSettings.feature_flag_health_check}
                      onChange={toggleHealthCheck}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="w-max ">Onboarding messages</div>
                    <InputSwitch
                      tooltip="Onboarding messages"
                      tooltipOptions={{ position: "left" }}
                      checked={!!curOrgData?.formattedOrgSettings.feature_flag_onboarding_message}
                      onChange={toggleOnboardingMessages}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="w-max ">Tracking Number messages</div>
                    <InputSwitch
                      tooltip="Trackgin Number messages"
                      tooltipOptions={{ position: "left" }}
                      checked={!!curOrgData?.formattedOrgSettings.feature_flag_ttn}
                      onChange={toggleTtnMessages}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
    </Dialog>
  );
};

export default OrgModal;
