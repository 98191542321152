import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface SlackApiChangeModalConfirmProps {
  onConfirm: () => void;
  onCancel: () => void;
  visible: boolean;
}

const SlackApiChangeModalConfirm: React.FC<SlackApiChangeModalConfirmProps> = ({
  onConfirm,
  onCancel,
  visible,
}: SlackApiChangeModalConfirmProps) => {
  return (
    <Dialog
      header="Confirmation"
      visible={visible}
      onHide={onCancel}
      footer={
        <div>
          <Button label="Yes" onClick={onConfirm} />
          <Button label="Cancel" onClick={onCancel} className="p-button-secondary" />
        </div>
      }
    >
      <p>
        You are changing Slack API key, all the setting for the existing space will be lost. Do you want to proceed?{" "}
      </p>
    </Dialog>
  );
};

export default SlackApiChangeModalConfirm;
