import { createContext, useCallback, useContext, useMemo, useState } from "react";
import {
  CustomQueueProps,
  HealthCheck,
  HealthCheckCreate,
  HealthCheckNotification,
  HealthCheckNotificationDetailed,
  HealthCheckStatus,
  HealthCheckUpdate,
} from "./HealthCheck.types";
import axios from "axios";

const api = {
  getAll: async () => axios.get<HealthCheck[]>(`/healthCheck`),
  notificationUpdate: async (id: string, notification: Partial<HealthCheckNotification>) =>
    axios.patch(`/healthCheck/notifications/${id}`, notification),
  notificationsGetAll: async (statuses?: HealthCheckStatus[]) =>
    axios.post<HealthCheckNotification[]>(`/healthCheck/notifications`, {
      statuses,
    }),
  notificationsGetAllDetailed: async (statuses?: HealthCheckStatus[]) =>
    axios.post<HealthCheckNotificationDetailed[]>(`/healthCheck/notifications/getAllDetailed`, {
      statuses,
    }),
  notificationById: async (id: string) =>
    axios.get<HealthCheckNotificationDetailed>(`/healthCheck/notifications/${id}`),
  notificationsForceCreateAndQueue: async (id: string) => axios.post(`/healthCheck/notifications/${id}`),
  notificationDelete: async (id: string) => axios.delete(`/healthCheck/notifications/${id}`),
  get: async (id: string) => axios.get<HealthCheck>(`/healthCheck/${id}`),
  create: async (healthCheck: HealthCheckCreate) => axios.post(`/healthCheck`, healthCheck),
  update: async (id: string, healthCheck: HealthCheckUpdate) => axios.patch(`/healthCheck/${id}`, healthCheck),
  delete: async (id: string) => axios.delete(`/healthCheck/${id}`),
  addPersons: async (id: string, personIds: string[]) => axios.post(`/healthCheck/${id}/addPersons`, personIds),
  removePersons: async (id: string, personIds: string[]) => axios.post(`/healthCheck/${id}removePersons`, personIds),
  sendHealthCheckMessages: async (notificationId: string) => axios.post(`/healthCheck/sendMessages/${notificationId}`),
  customQueueReset: async (props: CustomQueueProps) => axios.post(`/healthCheck/resetQueue`, props),
  deleteHealthCheck: async (id: string) => axios.delete(`/healthCheck/${id}`),
};

export const useHealthCheckContext = () => {
  const context = useContext(healthCheckContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

const useHealthCheck = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [healthCheck, setHealthCheck] = useState<HealthCheck | null>(null);
  const [allHealthChecks, setAllHealthChecks] = useState<HealthCheck[] | null>(null);
  const [allHealthCheckNotifications, setAllHealthCheckNotifications] = useState<HealthCheckNotification[] | null>(
    null
  );
  const [allHealthCheckNotificationsDetailed, setAllHealthCheckNotificationsDetailed] = useState<
    HealthCheckNotificationDetailed[] | null
  >(null);

  const getHealthCheckNotificationById = useCallback(async (id: string) => {
    try {
      setIsFetching(true);
      const notification = await api.notificationById(id);
      return notification.data;
    } finally {
      setIsFetching(false);
    }
  }, []);

  const deleteHealthCheck = useCallback(async (id: string) => {
    try {
      setIsFetching(true);
      await api.deleteHealthCheck(id);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const deleteHealthCheckNotification = useCallback(async (id: string) => {
    try {
      setIsFetching(true);
      await api.notificationDelete(id);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const customQueueReset = useCallback(async (props: CustomQueueProps) => {
    try {
      setIsFetching(true);
      await api.customQueueReset(props);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const sendHealthCheckMessages = useCallback(async (notificationId: string) => {
    try {
      setIsFetching(true);
      await api.sendHealthCheckMessages(notificationId);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getAllHealthCheckNotificationsDetailed = useCallback(async () => {
    try {
      setIsFetching(true);
      const notifications = await api.notificationsGetAllDetailed();
      setAllHealthCheckNotificationsDetailed(notifications.data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getAllHealthChecks = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await api.getAll();
      setAllHealthChecks(response.data);
      return response.data;
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getHealthCheck = useCallback(async (id: string) => {
    try {
      setIsFetching(true);
      const response = await api.get(id);
      setHealthCheck(response.data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const updateHealthCheck = useCallback(
    async (id: string, healthCheck: Partial<HealthCheck>) => {
      try {
        setIsFetching(true);
        await api.update(id, healthCheck);
        await getHealthCheck(id);
      } finally {
        setIsFetching(false);
      }
    },
    [getHealthCheck]
  );

  const createHealthCheck = useCallback(async (healthCheck: HealthCheckCreate) => {
    try {
      setIsFetching(true);
      await api.create(healthCheck);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getHealthCheckNotifications = useCallback(async (statuses?: HealthCheckStatus[]) => {
    try {
      setIsFetching(true);
      const notifications = await api.notificationsGetAll(statuses);
      setAllHealthCheckNotifications(notifications.data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const addPersons = useCallback(
    async (id: string, personIds: string[]) => {
      try {
        setIsFetching(true);
        await api.addPersons(id, personIds);
        await getHealthCheck(id);
      } finally {
        setIsFetching(false);
      }
    },
    [getHealthCheck]
  );

  const removePersons = useCallback(
    async (id: string, personIds: string[]) => {
      try {
        setIsFetching(true);
        await api.removePersons(id, personIds);
        await getHealthCheck(id);
      } finally {
        setIsFetching(false);
      }
    },
    [getHealthCheck]
  );

  const createHealthCheckNotification = useCallback(async (id: string) => {
    try {
      setIsFetching(true);
      await api.notificationsForceCreateAndQueue(id);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const notificationUpdate = useCallback(async (id: string, notification: Partial<HealthCheckNotification>) => {
    try {
      setIsFetching(true);
      await api.notificationUpdate(id, notification);
    } finally {
      setIsFetching(false);
    }
  }, []);

  return useMemo(
    () => ({
      notificationUpdate,
      createHealthCheckNotification,
      getAllHealthChecks,
      addPersons,
      removePersons,
      getHealthCheck,
      sendHealthCheckMessages,
      isFetching: isFetching,
      healthCheck,
      updateHealthCheck,
      createHealthCheck,
      allHealthChecks,
      getHealthCheckNotifications,
      allHealthCheckNotifications,
      getAllHealthCheckNotificationsDetailed,
      allHealthCheckNotificationsDetailed,
      customQueueReset,
      deleteHealthCheck,
      deleteHealthCheckNotification,
      getHealthCheckNotificationById,
    }),
    [
      getHealthCheckNotificationById,
      deleteHealthCheckNotification,
      deleteHealthCheck,
      customQueueReset,
      allHealthCheckNotificationsDetailed,
      notificationUpdate,
      createHealthCheckNotification,
      getAllHealthChecks,
      addPersons,
      removePersons,
      getHealthCheck,
      sendHealthCheckMessages,
      isFetching,
      healthCheck,
      updateHealthCheck,
      createHealthCheck,
      allHealthChecks,
      getHealthCheckNotifications,
      allHealthCheckNotifications,
      getAllHealthCheckNotificationsDetailed,
    ]
  );
};

const healthCheckContext = createContext<ReturnType<typeof useHealthCheck> | null>(null);

export const HealthCheckProvider = ({ children }: { children: React.ReactNode }) => {
  const healthCheck = useHealthCheck();
  return <healthCheckContext.Provider value={healthCheck}>{children}</healthCheckContext.Provider>;
};

export default useHealthCheckContext;
