import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useCallback, useEffect, useRef, useState } from "react";
import { useUserContext } from "../Users/UserContext";
import { toast } from "react-toastify";
import JTextArea from "../../base/JTextArea/JTextArea";
import { errorOrDefault } from "@/utils";
import { ChannelDTO, ChannelUpdateProps } from "./types";
import { ModalOperationType } from "@/types";
import { useDestinationsContext } from "../Destinations";
import Label from "@/base/Label";
import JInputText from "@/base/JInputText";
import { getNotificationTime, setChannelNotificationsValues } from "../Settings/utils";
import { JUser } from "../Users/types";
import Loader from "@/base/Loader";
import CustomNotificationSchedule from "../Settings/CustomNotificationSchedule";
import { Checkbox } from "primereact/checkbox";
import SlackChannelAutocompleteInput from "../../components/SlackChannelAutocompleteInput";
import ChannelManagersAutocompleteMultipleSelectInput from "../../components/ChannelManagersAutocompleteMultipleSelectInput";

interface FormikProps {
  id?: string;
  description?: string;
  channelId: string;
  channelTitle: string;
  isActive?: boolean;
  schedule_notification_from: string;
  schedule_notification_to: string;
  schedule_notification_update: string;
  managers: {
    id: string;
    name: string;
  }[];
  isNotificationsGrouped: boolean;
  useTemplatesForGroupCongrats: boolean;
  customChannelId: string;
  customChannelTitle: string;
  rebroadcastNotifyOnly?: boolean;
  rebroadcastSlackChannelId?: string | null;
}

const ChannelAddEditModal = ({
  data,
  onClose,
  formType = "create",
}: {
  data?: ChannelDTO | null;
  onClose: () => void;
  formType: ModalOperationType;
}) => {
  const { isChannelFetching, updateChannel, addChannel, sendRandomMessage, orgSettings, getOrgManagerList, user } =
    useUserContext();
  const { getSlackChannelsInfo } = useDestinationsContext();

  const [isLoading, setIsLoading] = useState(true);

  const isChannelManager = user?.type === "USERS";

  const isNew = formType === "create";

  const formikRef = useRef(null);

  const { schedule_notification_from, schedule_notification_to, schedule_notification_update } = getNotificationTime({
    fromHour: data?.notifyFromHour,
    fromMin: data?.notifyFromMin,
    toHour: data?.notifyToHour,
    toMin: data?.notifyToMin,
    updateHour: data?.notifyUpdateHour,
    updateMin: data?.notifyUpdateMin,
    orgSettings,
  });

  const onSubmit = async (values: FormikProps) => {
    try {
      const notifyValues = setChannelNotificationsValues({
        schedule_notification_from: values.schedule_notification_from,
        schedule_notification_to: values.schedule_notification_to,
        schedule_notification_update: values.schedule_notification_update,
      });

      if (isNew) {
        await addChannel({
          ...notifyValues,
          description: values?.description,
          channelId: values.customChannelId || values.channelId,
          channelTitle: values.customChannelTitle || values.channelTitle,
          managers: values.managers.map((manager) => ({ id: manager.id })) as JUser[],
          isNotificationsGrouped: values.isNotificationsGrouped,
          useTemplatesForGroupCongrats: values.useTemplatesForGroupCongrats,
          rebroadcastSlackChannelId: values?.rebroadcastSlackChannelId || null,
          rebroadcastNotifyOnly: values?.rebroadcastNotifyOnly,
        });
        toast.success("Channel added");
        onClose();
        return;
      }

      if (isChannelManager) {
        await updateChannel({
          ...notifyValues,
          id: (data as ChannelUpdateProps).id,
          description: values?.description,
          isNotificationsGrouped: values?.isNotificationsGrouped,
          useTemplatesForGroupCongrats: values.useTemplatesForGroupCongrats,
          rebroadcastSlackChannelId: values?.rebroadcastSlackChannelId || null,
          rebroadcastNotifyOnly: values?.rebroadcastNotifyOnly,
        });
        toast.success("Channel updated");
        onClose();
        return;
      }

      await updateChannel({
        ...notifyValues,
        id: (data as ChannelUpdateProps).id,
        description: values?.description,
        isActive: values?.isActive,
        channelId: values?.customChannelId || values.channelId,
        channelTitle: values.customChannelTitle || values?.channelTitle,
        managers: values.managers.map((manager) => ({ id: manager.id })) as JUser[],
        isNotificationsGrouped: values?.isNotificationsGrouped,
        useTemplatesForGroupCongrats: values.useTemplatesForGroupCongrats,
        rebroadcastSlackChannelId: values?.rebroadcastSlackChannelId || null,
        rebroadcastNotifyOnly: values?.rebroadcastNotifyOnly,
      });
      toast.success("Channel updated");
      onClose();
    } catch (err) {
      toast.error(errorOrDefault(err, "Error saving channel"));
    }
  };

  const footerContentRender = useCallback(
    (dirty: boolean, submitForm: () => void) => {
      return (
        <div className="flex justify-between">
          {data && (
            <div className="flex gap-4">
              <Button
                onClick={async () => {
                  try {
                    await sendRandomMessage(data.id);
                    toast.success("Message sent");
                  } catch (err) {
                    toast.error(errorOrDefault(err, "Error sending random message"));
                  }
                }}
              >
                Send test message
              </Button>
              <CustomNotificationSchedule channelId={data.id} />
            </div>
          )}
          <div>
            <Button label={dirty ? "Cancel" : "Close"} icon="pi pi-times" onClick={onClose} className="p-button-text" />
            <Button
              tooltipOptions={{ position: "top" }}
              tooltip={isNew && !dirty ? "Select channel to save" : ""}
              label={"Save"}
              icon="pi pi-check"
              type="submit"
              disabled={!isNew && !dirty}
              onClick={() => {
                if (!dirty) return;

                submitForm();
              }}
              loading={isChannelFetching}
            />
          </div>
        </div>
      );
    },
    [data, isChannelFetching, isNew, onClose, sendRandomMessage]
  );

  useEffect(() => {
    async function fn() {
      try {
        await getSlackChannelsInfo();
        await getOrgManagerList();
      } catch (e) {
        toast.error(errorOrDefault(e, "Failed to get channels or org. managers list. Please try again later."));
      } finally {
        setIsLoading(false);
      }
    }

    if (isChannelManager) {
      setIsLoading(false);

      return;
    }

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik<FormikProps>
      innerRef={formikRef}
      initialValues={
        !data
          ? {
              description: "",
              channelId: "",
              channelTitle: "",
              schedule_notification_from,
              schedule_notification_to,
              schedule_notification_update,
              managers: [],
              isNotificationsGrouped: false,
              useTemplatesForGroupCongrats: false,
              customChannelId: "",
              customChannelTitle: "",
              rebroadcastSlackChannelId: null,
              rebroadcastNotifyOnly: false,
            }
          : {
              id: (data as ChannelUpdateProps)?.id,
              description: data?.description,
              channelId: data.channelId,
              channelTitle: data.channelTitle,
              schedule_notification_from,
              schedule_notification_to,
              schedule_notification_update,
              managers:
                data?.managers.map((m) => ({
                  id: m.id,
                  name: m.email,
                })) ?? [],
              isNotificationsGrouped: data.isNotificationsGrouped,
              useTemplatesForGroupCongrats: data.useTemplatesForGroupCongrats,
              customChannelId: "",
              customChannelTitle: "",
              rebroadcastSlackChannelId: data?.rebroadcastSlackChannelId,
              rebroadcastNotifyOnly: data?.rebroadcastNotifyOnly,
            }
      }
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: Record<string, string> = {};

        if (!values.channelId && !values.customChannelId) {
          errors.customChannelId = "Channel ID is required";
          errors.channelId = "Channel ID is required";
        }

        if (values.customChannelId && !values.customChannelTitle) {
          errors.customChannelTitle = "Channel title is required";
        }

        return errors;
      }}
    >
      {({ values, setFieldValue, submitForm, dirty }) => (
        <Dialog
          header={isNew ? "Create channel" : "Edit channel"}
          visible={true}
          onHide={onClose}
          footer={footerContentRender(dirty, submitForm)}
        >
          <Loader isLoading={isLoading} />
          <Form>
            <div className="flex flex-col gap-0 py-4">
              <div className="flex w-full gap-4">
                {!isChannelManager &&
                  (formType === "create" ? (
                    <Label title="Channel" className="w-full">
                      <div className="flex w-full flex-col gap-4">
                        <SlackChannelAutocompleteInput
                          id="channelId"
                          currentChannelId={values.channelId}
                          initialChannelData={{
                            channelId: data?.channelId ?? "",
                            channelTitle: data?.channelTitle ?? "",
                            description: data?.description ?? "",
                          }}
                          setChannelData={(channelData) => {
                            setFieldValue("channelId", channelData.channelId);
                            setFieldValue("channelTitle", channelData.channelTitle);
                            setFieldValue("description", channelData.description);
                            setFieldValue("customChannelId", "");
                            setFieldValue("customChannelTitle", "");
                          }}
                        />
                        <div className="flex w-full gap-2">
                          <JInputText
                            className="mb-0 w-full"
                            title=""
                            id="customChannelId"
                            placeholder="Add channel ID manually, if haven’t found it in the list"
                            onChange={(e) => {
                              setFieldValue("customChannelId", e.target.value);
                              setFieldValue("channelId", "");
                              setFieldValue("channelTitle", "");
                            }}
                          />
                          <JInputText
                            title=""
                            id="customChannelTitle"
                            placeholder="Add title for manually added channel"
                            className="mb-0 w-full"
                            onChange={(e) => {
                              setFieldValue("customChannelTitle", e.target.value);
                              setFieldValue("channelId", "");
                              setFieldValue("channelTitle", "");
                            }}
                          />
                        </div>
                      </div>
                    </Label>
                  ) : (
                    <Label title="Channel" className="w-full">
                      <div className="flex w-full gap-2 py-4">
                        <span>{values.channelTitle}</span>
                      </div>
                    </Label>
                  ))}
                <div className="flex w-[200px] items-center gap-2 py-4">
                  <span className="">Allow grouping</span>
                  <Checkbox
                    inputId="isNotificationsGrouped"
                    name="isNotificationsGrouped"
                    value={true}
                    checked={values.isNotificationsGrouped}
                    onChange={(e) => {
                      const isChecked = e.checked;
                      setFieldValue("isNotificationsGrouped", isChecked);
                    }}
                  />
                </div>
                <div className="flex w-[200px] items-center gap-2 py-4">
                  <span className="">Use templates</span>
                  <Checkbox
                    inputId="useTemplatesForGroupCongrats"
                    name="useTemplatesForGroupCongrats"
                    checked={values.useTemplatesForGroupCongrats}
                    onChange={(e) => {
                      const isChecked = e.checked;
                      setFieldValue("useTemplatesForGroupCongrats", isChecked);
                    }}
                  />
                </div>
              </div>

              {!isChannelManager && (
                <Label title="Resend message to additional channel" className="w-full">
                  <div className="flex w-full gap-5">
                    <SlackChannelAutocompleteInput
                      id="channelId"
                      currentChannelId={values?.rebroadcastSlackChannelId ?? ""}
                      initialChannelData={{
                        channelId: data?.rebroadcastSlackChannelId ?? "",
                      }}
                      setChannelData={(channelData) => {
                        setFieldValue("rebroadcastSlackChannelId", channelData.channelId);
                      }}
                    />
                    <div className="flex w-[200px] items-center gap-2">
                      <span className="text-nowrap">Send only to this channel</span>
                      <Checkbox
                        inputId="rebroadcastNotifyOnly"
                        name="rebroadcastNotifyOnly"
                        checked={!!values.rebroadcastNotifyOnly}
                        onChange={(e) => {
                          const isChecked = e.checked;
                          setFieldValue("rebroadcastNotifyOnly", isChecked);
                        }}
                      />
                    </div>
                  </div>
                </Label>
              )}

              <JTextArea id="description" title="Description" className="w-full" />
              <div className="flex w-full items-end gap-4">
                {!isChannelManager && (
                  <div className="w-full max-w-[400px]">
                    <ChannelManagersAutocompleteMultipleSelectInput
                      currentManagerIds={values.managers.map((m) => m.id)}
                      onChange={(managers) => {
                        setFieldValue("managers", managers);
                      }}
                    />
                  </div>
                )}
                <div className="flex items-end gap-2">
                  <JInputText id="schedule_notification_from" title="Show from" className="mb-0 " type="time" />
                  <JInputText id="schedule_notification_to" title="Show to" className="mb-0 " type="time" />
                  <JInputText id="schedule_notification_update" title="Update at" className="mb-0" type="time" />
                </div>
              </div>
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default ChannelAddEditModal;
