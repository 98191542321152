import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="  flex h-screen">
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="wrapper"
      >
        <h2 className="text-red-700">Oops!</h2>
        <p className="text-red-500">There was an error.</p>
        <NavLink className={"text-blue-800"} to={"/"}>
          Go to Home
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
