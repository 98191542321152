import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useState } from "react";
import { useUserContext } from "../Users/UserContext";
import { userSendResetPassLinkSchema } from "../../schemas";
import JInputText from "../../base/JInputText";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Forgot = () => {
  const { sendResetPassLink, isAuthFetching } = useUserContext();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  async function handleResetPass({ email }: { email: string }) {
    setError(null);

    try {
      await sendResetPassLink(email.trim());
      toast.success("Reset password link sent");
      navigate("/auth");
    } catch (err) {
      console.error(err);
      toast.error("Wrong email");
      setError("Wrong email");
    }
  }

  return (
    <>
      <Formik<{ email: string }>
        initialValues={{
          email: "",
        }}
        validationSchema={userSendResetPassLinkSchema}
        onSubmit={handleResetPass}
      >
        {() => (
          <Form>
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <h1 className="mb-4 text-left text-[30px] ">Reset password</h1>
            <JInputText
              id="email"
              title="E-mail"
              placeholder="Enter email to send reset password link"
              className="w-full"
              noLabel
            />
            {!!error && (
              <>
                {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                <div className="field">
                  <Message severity="error" text={error} />
                </div>
              </>
            )}
            <div className="mt-[40px] text-left">
              <Button label="Send reset link" type="submit" loading={isAuthFetching} />
            </div>
            <div className="mt-4 text-left text-[--color-fg]">
              <Link to="/auth">Return to Sign In</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Forgot;
