import * as yup from "yup";

export const userSignInSchema = yup.object().shape({
  username: yup.string().trim().required(),
  password: yup.string().required(),
});

export const userSendResetPassLinkSchema = yup.object().shape({
  email: yup.string().trim().email().required(),
});

export const passwordValidationSchema = yup.object().shape({
  password: yup.string().required("Password is required").min(8, "Password must be at least 8 characters long"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export const userSchemaAdd = (isSuperAdmin: boolean) =>
  yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().trim().email("Invalid email").required("Required"),
    ...(isSuperAdmin && { organizationId: yup.string().required("Required") }),
  });

export const userSchemaUpdate = (isSuperAdmin: boolean) =>
  yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().trim().email("Invalid email").required("Required"),
    ...(isSuperAdmin && { organizationId: yup.string().required("Required") }),
  });

export const promptUpdateSchema = yup.object().shape({
  prompt: yup.string().required("Required"),
  title: yup.string().required("Required"),
  eventId: yup.string().required("Required"),
});

export const talkCreateSchema = yup.object().shape({
  title: yup.string().required("Required"),
  type: yup.string().required("Required"),
});

export const channelAddEditSchema = yup.object({
  // description: yup.string().optional(),
  // channelId: yup.string().when("customChannelId", (customChannelId, schema) => {
  //   if (!customChannelId && !yup.ref("channelId")) return schema.required("Custom channel ID required");
  //   return schema;
  // }),
  // customChannelId: yup.string().when("channelId", (channelId, schema) => {
  //   if (!channelId && (!yup.ref("customChannelId") || (yup.ref("customChannelId") as unknown as string) === ""))
  //     return schema.required("Custom channel ID required");
  //   return schema;
  // }),
  // customChannelTitle: yup.string().when("customChannelId", (customChannelId, schema) => {
  //   if (customChannelId && !yup.ref("customChannelTitle")) return schema.required("Custom channel name required");
  //   return schema;
  // }),
});
