import { useEffect } from "react";
import useHealthCheckContext from "./useHealthCheck";
import CustomControl from "./CustomControl";
import Loader from "@/base/Loader";
import HealthCheckItem from "./HealthCheckItem";
import { useUserContext } from "../Users/UserContext";

export default function HealthCheckList() {
  const { getAllHealthChecks, allHealthChecks, isFetching } = useHealthCheckContext();
  const { isChannelManager } = useUserContext();

  useEffect(() => {
    if (isChannelManager) return;
    getAllHealthChecks();
  }, [getAllHealthChecks, isChannelManager]);

  if (!allHealthChecks) {
    return null;
  }

  return (
    <div>
      <Loader isLoading={isFetching} />
      {/* 
      <DataTable sortField="createdAt" sortOrder={-1} value={allHealthChecks}>
        <Column field="title" header="Title"></Column>
        <Column field="days" header="Days" body={daysBodyTemplate}></Column>
        <Column className="max-w-[300px]" header="Prompt/Message" body={promptMessageTemplate}></Column>
        <Column header="Start at" body={startAtBodyTemplate}></Column>
        <Column className="w-[530px]" header="Recipients" body={recipientAvatarsBody}></Column>
        <Column field="isActive" header="Active" body={isActiveBodyTemplate}></Column>
        <Column
          body={controlBodyTemplate}
          header={
            <Button
              label="Add new health check"
              icon="pi pi-plus"
              onClick={() => {
                setShowCreateModal("create");
              }}
            />
          }
        ></Column>
      </DataTable> 
      */}
      <div className="flex flex-col gap-4">
        {allHealthChecks.map((healthCheck) => (
          <HealthCheckItem key={healthCheck.id} healthCheck={healthCheck} />
        ))}
      </div>
      <CustomControl />
    </div>
  );
}
