import clsx from "clsx";
import Ellips from "@/assets/ellips.svg?react";

interface Props {
  status: string;
}

export default function Status({ status }: Props) {
  const classes = "text-sm font-bold uppercase flex gap-1 items-center";
  const hollowCircle = <Ellips className="size-[14px]" />;

  if (status === "active") {
    return <span className={clsx("text-blue-500", classes)}>{hollowCircle}Active</span>;
  }

  if (status === "pending") {
    return <span className={clsx("text-yellow-500", classes)}>{hollowCircle} Pending</span>;
  }

  if (status === "planned") {
    return <span className={clsx("text-yellow-500", classes)}>{hollowCircle} Planned</span>;
  }

  if (status === "completed") {
    return <span className={clsx("!text-[#2ADB1B]", classes)}>{hollowCircle} Completed</span>;
  }

  if (status === "canceled") {
    return <span className={clsx("text-gray-500", classes)}>{hollowCircle} Cancelled</span>;
  }

  return null;
}
