import { Formik } from "formik";
import { OrganizationSettingKey, OrganizationSettingsRecord } from "@/types";
import { useUserContext } from "../Users/UserContext";
import Loader from "@/base/Loader";
import { useState } from "react";
import { batchOrgSettingsUpdate } from "./utils";
import { Fieldset } from "primereact/fieldset";
import JTextArea from "@/base/JTextArea/JTextArea";
import { Button } from "primereact/button";

type SettingsSuperAdminProps = Pick<OrganizationSettingsRecord, "system_prompt">;

export const SettingsSuperAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { saveOrgSettings, getUserData, orgSettings } = useUserContext();

  const handleSaveChanges = async (formValues: SettingsSuperAdminProps) => {
    await batchOrgSettingsUpdate({
      getUserData,
      setIsLoading,
      saveOrgSettings,
      values: formValues as Record<OrganizationSettingKey, string>,
      orgSettings,
      showSuccessToast: true,
    });
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="mb-4 flex items-center justify-center">
        <span className="text-[40px]">Settings</span>
      </div>
      <Formik<SettingsSuperAdminProps>
        initialValues={{
          ...orgSettings,
        }}
        onSubmit={handleSaveChanges}
        enableReinitialize
      >
        {({ dirty, submitForm }) => (
          <div className="flex flex-col gap-4">
            <div className="mx-auto flex w-[900px] flex-col gap-4">
              <div className="flex flex-col gap-4">
                <Fieldset legend="Slack settings">
                  <div className="mb-4 flex flex-col gap-2">
                    <JTextArea
                      autoComplete="off"
                      id="system_prompt"
                      rows={5}
                      title="System prompt"
                      placeholder="Plesae enter system prompt that will be used during each AI answer generation process"
                      className="w-full "
                    />
                  </div>
                </Fieldset>
                <div className="mx-auto flex gap-4">
                  <Button
                    disabled={!dirty}
                    label="Save changes"
                    onClick={async () => {
                      await submitForm();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SettingsSuperAdmin;
