import { moodToEmojiMap } from "./utils";
import { HealthCheckNotificationDetailed } from "./HealthCheck.types";
import { hcReport } from "./utils/hcReport";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import clsx from "clsx";
import { imageCellRender } from "@/utils";

const HealthCheckReportAllNotifs = ({
  healthCheckId,
  allHealthCheckNotificationsDetailed,
}: {
  healthCheckId: string;
  allHealthCheckNotificationsDetailed: HealthCheckNotificationDetailed[];
}) => {
  const [teamMateSearch, setTeamMateSearch] = useState("");
  const [focusedTeamMate, setFocusedTeamMate] = useState<string[]>([]);
  const [curHealthCheckReport, curDates, imageByRecipients] = hcReport({
    allHealthCheckNotificationsDetailed,
    healthCheckId: healthCheckId,
  });

  const toggleFocusTeamMate = (name: string) => {
    if (focusedTeamMate.includes(name)) {
      setFocusedTeamMate(focusedTeamMate.filter((n) => n !== name));
    } else {
      setFocusedTeamMate([...focusedTeamMate, name]);
    }
  };

  const showHealthCheckReport = curHealthCheckReport && curDates.length > 0;

  const cellClassName = "w-[80px] h-[50px] justify-center items-center flex";
  const titleClassName = `w-[200px] flex justify-start text-left items-center px-3 `;

  return (
    <>
      {showHealthCheckReport && (
        <div className="max-w-[full] overflow-auto">
          <div className="flex w-max flex-col gap-0 overflow-auto">
            <div className="mb-2 flex gap-0 rounded-[8px] bg-[var(--color-dark)]">
              <div className={clsx(cellClassName)}></div>
              <div className={`${titleClassName} font-semibold`}>
                <span className="p-input-icon-right pt-[10px]">
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Search team mate"
                    value={teamMateSearch}
                    onChange={(e) => {
                      setTeamMateSearch(e.target.value);
                    }}
                    className=""
                  />
                </span>
              </div>
              <div className="flex gap-0">
                {healthCheckId &&
                  curDates.map((date) => {
                    return (
                      <div key={date} className={`font-xs flex justify-center font-semibold ${cellClassName}`}>
                        {date}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="max-h-[600px] overflow-auto">
              {showHealthCheckReport &&
                healthCheckId &&
                Object.keys(curHealthCheckReport).map((name) => {
                  const repByDates = curHealthCheckReport[name];

                  if (!repByDates) {
                    return null;
                  }

                  if (teamMateSearch && !name.toLowerCase().includes(teamMateSearch.toLowerCase())) {
                    return null;
                  }

                  const isDimmed = focusedTeamMate.length > 0 && !focusedTeamMate.includes(name);

                  return (
                    <div key={name} className="flex gap-0 border-b border-b-[var(--color-dark)]">
                      <div className={`${cellClassName} px-2`}>
                        <Checkbox checked={focusedTeamMate.includes(name)} onChange={() => toggleFocusTeamMate(name)} />
                      </div>
                      <div className={clsx(`${titleClassName} gap-2 px-4`, isDimmed && "opacity-30")}>
                        {imageCellRender({
                          imageUrl: imageByRecipients[name],
                          name,
                        })}{" "}
                        {name}
                      </div>
                      <div className={clsx("flex gap-0", isDimmed && "opacity-30")}>
                        {Object.keys(repByDates).map((date) => {
                          const classNamesToMood: Record<string, string> = {
                            awesome: "bg-[#2ADB1B]",
                            good: "bg-green-400",
                            normal: "bg-[#DBB11B]",
                            bad: "bg-[#DB6C1B]",
                            awful: "bg-[#DB1B1B]",
                          };

                          const curMood = repByDates[date] as string;

                          if (!moodToEmojiMap[curMood]) {
                            return (
                              <div key={date} className={`${cellClassName} flex items-center justify-center p-[6px]`}>
                                <div
                                  className={`flex size-full items-center justify-center rounded-md bg-[var(--color-dark)] text-[20px]`}
                                >
                                  {moodToEmojiMap[curMood]}
                                </div>
                              </div>
                            );
                          }

                          return (
                            <div key={date} className={`${cellClassName} flex items-center justify-center p-[6px]`}>
                              <div
                                className={`${classNamesToMood[curMood]} flex size-full items-center justify-center rounded-md text-[20px]`}
                              >
                                {moodToEmojiMap[curMood]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HealthCheckReportAllNotifs;
