import { useState } from "react";
import { HealthCheckNotificationDetailed } from "./HealthCheck.types";
import clsx from "clsx";
import { moodToEmojiMap } from "./utils";

interface Props {
  healthCheckId: string;
  allHealthCheckNotificationsDetailed: HealthCheckNotificationDetailed[];
}

const ProgressBar = ({ value, total }: { value: number; total: number }) => {
  return (
    <div className="relative h-4 w-full min-w-[500px] rounded-full bg-[--color-dark]">
      <div
        className="absolute h-4 rounded-full bg-[--color-orange]"
        style={{ width: `${(value / total) * 100}%` }}
      ></div>
    </div>
  );
};

const MoodProgressBars = ({ report }: { report: HealthCheckNotificationDetailed }) => {
  const total = report.healthCheckAnswers.length;

  return (
    <div className="flex flex-col gap-[10px]">
      {Object.keys(moodToEmojiMap).map((mood) => {
        const answers = report.healthCheckAnswers.filter((a) => a.answer === mood).length;

        return (
          <div className="flex flex-col gap-2">
            <div className="flex shrink-0 items-center gap-4">
              <span className="text-[24px]">{moodToEmojiMap[mood]}</span>
              <span>{answers}</span>
              <span className="">
                <ProgressBar value={answers} total={total} />
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default function HealthCheckReportByHealthCheck({ allHealthCheckNotificationsDetailed }: Props) {
  const [curReport, setCurReport] = useState<HealthCheckNotificationDetailed | null>(null);

  if (!allHealthCheckNotificationsDetailed) {
    return <div>No health checks</div>;
  }

  const total = curReport?.healthCheckAnswers.length || 0;
  const answered = curReport?.healthCheckAnswers.filter((a) => a.status === "completed").length || 0;
  const pending = curReport?.healthCheckAnswers.filter((a) => a.status === "pending").length || 0;

  return (
    <div className="flex gap-4">
      <div className="flex max-h-[400px] flex-col gap-2 overflow-auto border border-[--color-dark]">
        {allHealthCheckNotificationsDetailed.map((report) => {
          const isSelected = curReport?.id === report.id;

          return (
            <div
              className={clsx(
                "flex h-[44px] shrink-0 cursor-pointer items-center gap-2 p-4 ",
                isSelected ? "bg-[--color-dark] font-bold text-white opacity-100" : "opacity-30 hover:bg-slate-600"
              )}
              onClick={() => setCurReport(report)}
            >
              <div className="text-[14px]">{new Date(report.createdAt).toLocaleString()}</div>
            </div>
          );
        })}
      </div>
      <div className="pl-10">
        {curReport && (
          <div className="flex gap-4">
            <div className="flex flex-col gap-[3px]">
              <div className="flex h-[110px] w-[223px] flex-col items-center justify-center gap-1 rounded-t-2xl bg-[--color-dark] ">
                <span className="text-[32px]"> {total}</span>
                <span className="text-[14px]">Recipients</span>
              </div>
              <div className="flex gap-1">
                <div className="flex size-[110px] flex-col items-center justify-center gap-1 rounded-bl-2xl bg-[--color-dark] ">
                  <span className="text-[32px] text-[--color-red]">{answered}</span>
                  <span className="text-[14px]">Answered</span>
                </div>
                <div className="flex size-[110px] flex-col items-center justify-center gap-1 rounded-br-2xl bg-[--color-dark] ">
                  <span className="text-[32px] text-[--color-orange]">{pending}</span>
                  <span className="text-[14px]">Pending</span>
                </div>
              </div>
            </div>
            <MoodProgressBars report={curReport} />
          </div>
        )}
      </div>
    </div>
  );
}
