import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import EventModal from "./EventsModal";
import Loader from "@/base/Loader";
import { EventDTO, occurrenceTypesChoose } from "./types";
import { useEventsContext } from "./EventsContext";
import { errorOrDefault, startDateCellRender } from "@/utils";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import { confirmPopup } from "primereact/confirmpopup";
import { toast } from "react-toastify";
import { useUserContext } from "../Users/UserContext";
import { Navigate } from "react-router-dom";
import PageTitleWrapper from "@/components/PageTitleWrapper";

export type OperationType = "create" | "update" | null;

const EventsList = () => {
  const [showModal, setShowModal] = useState<OperationType>(null);
  const [curItem, setCurItem] = useState<EventDTO | null>(null);

  const { events, getEvents, isEventsFetching, deleteEvent } = useEventsContext();
  const { user } = useUserContext();
  const isSuperAdmin = user?.isSuperAdmin;
  const isChannelManager = user?.isChannelManager;

  useEffect(() => {
    getEvents({
      globalOnly: !isSuperAdmin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orgsRender = (rawData: EventDTO) => {
    return (
      <div className="flex flex-col gap-0 text-sm">
        {rawData?.organizations?.map((org) => <div key={org.id}>{org.title}</div>)}
      </div>
    );
  };

  const channelsRender = (rawData: EventDTO) => {
    return (
      <div className="flex flex-col gap-0 text-sm">
        {rawData?.eventChannels?.map((channel) => <div key={channel.id}>{channel?.channel?.channelTitle}</div>)}
      </div>
    );
  };

  const operationsCellRender = (rawData: EventDTO) => (
    <>
      <div className="flex gap-4">
        <Edit
          className="cursor-pointer"
          onClick={() => {
            setCurItem(rawData);
            setShowModal("update");
          }}
        />

        <Trash className="cursor-pointer" onClick={() => confirm(rawData.id)} />
      </div>
    </>
  );

  const confirm = (id: string) => {
    const confirmationMessageRef = document.getElementById("confirmMessage");
    if (!confirmationMessageRef) {
      console.error("Confirmation message is not defined");
      return;
    }
    confirmPopup({
      target: confirmationMessageRef,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await deleteEvent(id);
          toast.success("Event deleted");
        } catch (err) {
          console.error(err);
          toast.error(errorOrDefault(err, "Error deleting event"));
        } finally {
          await getEvents({
            globalOnly: !isSuperAdmin,
          });
        }
      },
      reject: () => {
        return;
      },
    });
  };

  if (!isSuperAdmin && user?.formattedOrgSettings?.feature_flag_shared_custom_event !== true) {
    return <Navigate to={"/"} state={{ returnUrl: window.location.pathname }} replace />;
  }

  return (
    <div>
      <Loader isLoading={isEventsFetching} />
      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Events</span>
          </>
        }
        row2={
          !isChannelManager && (
            <Button
              label="Add new event"
              icon="pi pi-plus"
              onClick={() => {
                setCurItem(null);
                setShowModal("create");
              }}
              className="ml-5"
            />
          )
        }
      />

      <DataTable<EventDTO[]> value={events ?? []} sortField="title" sortOrder={1}>
        <Column field="title" header="Title" sortable></Column>
        <Column field="description" header="Description"></Column>
        {/** 
        <Column
          field="type"
          header="Type"
          body={({ type }) => eventTypesChoose.find((e) => e.code === type)?.name}
        ></Column> 
        */}
        <Column
          field="occurrenceType"
          header="Occurrence Type"
          body={({ occurrenceType }) => occurrenceTypesChoose.find((e) => e.code === occurrenceType)?.name}
        ></Column>
        {isSuperAdmin ? (
          <Column header="Orgs" body={orgsRender}></Column>
        ) : (
          <Column header="Channels" body={channelsRender}></Column>
        )}
        <Column field="startDate" body={startDateCellRender} header="Started at"></Column>
        {/**
        <Column field="createdAt" header="Created at" body={createdAtCellRender}></Column>
        <Column field="updatedAt" header="Updated at" body={updatedAtCellRender}></Column> 
        */}
        <Column header="Operations" body={operationsCellRender}></Column>
      </DataTable>

      {showModal && (
        <EventModal
          onClose={() => {
            setShowModal(null);
            setCurItem(null);
          }}
          formType={showModal}
          item={curItem}
        />
      )}
    </div>
  );
};

export default EventsList;
