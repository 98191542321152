import { TalkType, TtnPersonalMessageStatus, TtnStatus } from "./types";

export const TalkTypeReadble: Record<TalkType, string> = {
  [TalkType.OTHER]: "Other",
  [TalkType.TTN]: "Tracking Nunmber",
  [TalkType.HEALTH_CHECK]: "Health Check",
};

export const TtnStatusReadable: Record<TtnStatus, string> = {
  [TtnStatus.RECEIVED]: "Received",
  [TtnStatus.SHIPPED]: "Shipped",
};

export const TtnPersonalMessageStatusReadable: Record<TtnPersonalMessageStatus, string> = {
  [TtnPersonalMessageStatus.NEW]: "New",
  [TtnPersonalMessageStatus.SENT]: "Sent",
};
