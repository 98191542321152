import axios from "axios";
import { AddEvent, UpdateEvent } from "./types";

export default class EventsService {
  public static getAll = async () => axios.get("/events");
  public static getAllGlobal = async () => axios.get("/eventsGlobal");
  public static getById = async (id: string) => axios.get(`/event/${id}`);
  public static add = async (event: AddEvent) => axios.post("/event", event);
  public static update = async (id: string, event: UpdateEvent) => axios.patch(`/event/${id}`, event);
  public static delete = async (id: string) => axios.delete(`/event/${id}`);
}
