import { HealthCheckStatus } from "@/containers/HealthCheck/HealthCheck.types";
import HealthCheckNotificationsList from "@/containers/HealthCheck/HealthCheckNotificationsList";
import clsx from "clsx";
import { useState } from "react";

const filters = [HealthCheckStatus.PLANNED, HealthCheckStatus.ACTIVE];

export default function HealthCheckPlannedNotifications() {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex cursor-pointer items-center gap-4" onClick={handleCollapse}>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <span className={clsx("pi !text-[var(--color-orange)]", isCollapsed ? "pi-plus" : "pi-minus")} />
        <span className="font-bold">Today's Health Check</span>
      </div>
      {!isCollapsed && <HealthCheckNotificationsList filterStatus={filters} />}
    </div>
  );
}
