import { EventChannelJoinDTO, EventType, FeatureEventType, OccurrenceType, PersonalizedEventType } from "@/types";
import { OrganizationDTO } from "../Users/types";

export interface EventDTO {
  id: string;
  title: string;
  description?: string;
  type: EventType;
  featureEventType?: FeatureEventType | null;
  personalizedEventType?: PersonalizedEventType;
  occurrenceType?: OccurrenceType | null;
  startDate: Date;
  endDate?: Date;
  isActive?: boolean;
  eventChannels: EventChannelJoinDTO[];
  organizations: OrganizationDTO[];
  repeatType?: CustomOccurrenceRepeatType;
  repeatOnWeekDays?: number[];
  repeatEvery?: number;
  repeatEachMonthDay?: number;
  startHour?: number | null;
  startMin?: number | null;
}

export type EventTypeChoose = {
  code: EventType;
  name: string;
};

export const eventTypesChoose: EventTypeChoose[] = [
  { code: EventType.GLOBAL, name: "Global" },
  { code: EventType.PERSONAL, name: "Personal" },
];

export type PersonalizedEventTypesChoose = {
  code: PersonalizedEventType | "";
  name: string;
};

export const personalizedEventTypesChoose: PersonalizedEventTypesChoose[] = [
  { code: PersonalizedEventType.BIRTHDAY, name: "Birthday" },
  { code: PersonalizedEventType.ANNIVERSARY, name: "Anniversary" },
  { code: "", name: "<none>" },
];

export type OccurrenceTypeChoose = {
  code: OccurrenceType | "";
  name: string;
};

export const occurrenceTypesChoose: OccurrenceTypeChoose[] = [
  { code: OccurrenceType.ANNUAL, name: "Annual" },
  { code: OccurrenceType.CUSTOM, name: "Custom" },
  { code: OccurrenceType.MONTHLY, name: "Monthly" },
  { code: OccurrenceType.DAILY, name: "Daily" },
  { code: OccurrenceType.ONCE, name: "Once" },
  { code: "", name: "<none>" },
];

export type FormValues = {
  title: string;
  description?: string;
  occurrenceType?: OccurrenceType;
  startDate: Date;
  endDate?: Date;
  isActive?: boolean;
  channelIds: string[];
  organizationIds: string[];
  repeatType?: CustomOccurrenceRepeatType;
  repeatOnWeekDays?: number[];
  repeatEvery?: number;
  repeatEachMonthDay?: number;
  startTime: string;
};

export interface AddEvent {
  title: string;
  description?: string;
  occurrenceType?: OccurrenceType | null;
  featuredEventType?: FeatureEventType | null;
  startDate?: Date;
  endDate?: Date;
  isActive?: boolean;
  organizationIds?: string[];
  channelIds?: string[];
  repeatOnWeekDays?: number[];
  repeatEvery?: number;
  repeatType?: CustomOccurrenceRepeatType;
  repeatEachMonthDay?: number;
}

export type UpdateEvent = Partial<AddEvent>;

export type CustomOccurrenceRepeatType = "WEEK" | "MONTH" | "YEAR" | "DAY";

export interface CustomOccurrenceProps {
  repeatType: CustomOccurrenceRepeatType;
  repeatOnWeekDays: number[];
  repeatEvery: number;
  repeatEachMonthDay?: number;
}
