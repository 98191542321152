import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Fieldset } from "primereact/fieldset";
import { useUserContext } from "../Users/UserContext";
import { Checkbox } from "primereact/checkbox";
import Label from "@/base/Label";
import { Tooltip } from "primereact/tooltip";

interface CustomNotificationScheduleProps {
  channelId: string;
}

const defaultWeekends = [0, 6];

export default function CustomNotificationSchedule({ channelId }: CustomNotificationScheduleProps) {
  const { resetScheduler, orgSettings } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const defFromDate = new Date();
  defFromDate.setHours(orgSettings?.schedule_notification_from_hour || 0);
  defFromDate.setMinutes(orgSettings?.schedule_notification_from_minute || 0);

  const [fromTime, setFromTime] = useState<Date | null>(defFromDate);
  const [isCleanEverything, setIsCleanEverything] = useState(false);
  const [weekends, setWeekends] = useState(defaultWeekends);

  const setWeekend = (day: number) => {
    if (weekends.includes(day)) {
      setWeekends(weekends.filter((d) => d !== day));
    } else {
      setWeekends([...weekends, day]);
    }
  };

  const defToDate = new Date();
  defToDate.setHours(orgSettings?.schedule_notification_to_hour || 0);
  defToDate.setMinutes(orgSettings?.schedule_notification_to_minute || 0);

  const [toTime, setToTime] = useState(defToDate);
  const [showDialog, setShowDialog] = useState(false);

  const isDisabled = orgSettings.is_notifications_enabled === false;

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const fromTimeHours = fromTime?.getHours();
      const fromTimeMinutes = fromTime?.getMinutes();
      const toTimeHours = toTime.getHours();
      const toTimeMinutes = toTime.getMinutes();

      if (
        fromTimeHours === undefined ||
        fromTimeMinutes === undefined ||
        toTimeHours === undefined ||
        toTimeMinutes === undefined
      ) {
        console.error("Invalid time");
        console.error({
          fromTimeHours,
          fromTimeMinutes,
          toTimeHours,
          toTimeMinutes,
        });
        return;
      }

      await resetScheduler({
        fromHour: fromTimeHours,
        fromMinute: fromTimeMinutes,
        toHour: toTimeHours,
        toMinute: toTimeMinutes,
        channelId,
        cleanPrevNotifications: isCleanEverything,
        weekends,
      });
      setShowDialog(false);
      toast.success("notification schedule saved");
    } catch (err) {
      console.error(err);
      toast.error(err as string);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFromTime(defFromDate);
    setToTime(defToDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  return (
    <>
      <Button
        tooltipOptions={{ position: "bottom" }}
        tooltip={isDisabled ? "Please enable notifications" : undefined}
        onClick={() => {
          if (isDisabled) {
            return;
          }
          setShowDialog(true);
        }}
      >
        Custom notification schedule
      </Button>
      <Dialog visible={showDialog} onHide={() => setShowDialog(false)} header="Set custom notification time">
        <div className="flex flex-col gap-4">
          <div className="flex gap-2">
            <Fieldset legend="Start time" className="flex flex-col gap-2">
              <Calendar
                value={fromTime}
                placeholder="Click to select time"
                onChange={(e) => {
                  if (!e.value) {
                    console.error("Invalid start time");
                    return;
                  }
                  setFromTime(e.value);
                }}
                timeOnly
              />
            </Fieldset>
            <Fieldset legend="End time" className="flex flex-col gap-2">
              <Calendar
                placeholder="Click to select time"
                value={toTime}
                onChange={(e) => {
                  if (!e.value) {
                    console.error("Invalid start time");
                    return;
                  }
                  setToTime(e.value);
                }}
                timeOnly
              />
            </Fieldset>
            <Fieldset legend="Other" className="">
              <div className="flex items-center gap-2">
                <Tooltip
                  target="#cleanEverything123"
                  position="bottom"
                  content="Clean all notif-s including one in WAITING status"
                />
                <Label
                  id="cleanEverything123"
                  className="w-[100px]"
                  htmlFor="cleanEverything"
                  title="Clean all WAITING"
                />
                <Checkbox
                  inputId="cleanEverything"
                  value="cleanEverything"
                  onChange={() => {
                    setIsCleanEverything(!isCleanEverything);
                  }}
                  checked={isCleanEverything}
                />
              </div>
            </Fieldset>
          </div>
          <div className="flex gap-2">
            <Fieldset legend="Weekends" className="">
              <div className="grid max-w-[400px] grid-cols-3 gap-4">
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Monday" />
                  <Checkbox
                    inputId="monday"
                    value="monday"
                    onChange={() => {
                      setWeekend(1);
                    }}
                    checked={weekends.includes(1)}
                  />
                </div>
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Tuesday" />
                  <Checkbox
                    inputId="tuesday"
                    value="tuesday"
                    onChange={() => {
                      setWeekend(2);
                    }}
                    checked={weekends.includes(2)}
                  />
                </div>
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Wednesday" />
                  <Checkbox
                    inputId="wednesday"
                    value="wednesday"
                    onChange={() => {
                      setWeekend(3);
                    }}
                    checked={weekends.includes(3)}
                  />
                </div>
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Thursday" />
                  <Checkbox
                    inputId="thursday"
                    value="thursday"
                    onChange={() => {
                      setWeekend(4);
                    }}
                    checked={weekends.includes(4)}
                  />
                </div>
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Friday" />
                  <Checkbox
                    inputId="friday"
                    value="friday"
                    onChange={() => {
                      setWeekend(5);
                    }}
                    checked={weekends.includes(5)}
                  />
                </div>
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Saturday" />
                  <Checkbox
                    inputId="saturday"
                    value="saturday"
                    onChange={() => {
                      setWeekend(6);
                    }}
                    checked={weekends.includes(6)}
                  />
                </div>
                <div className="flex gap-2">
                  <Label htmlFor="weekends" title="Sunday" />
                  <Checkbox
                    inputId="sunday"
                    value="sunday"
                    onChange={() => {
                      setWeekend(0);
                    }}
                    checked={weekends.includes(0)}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          <div className="flex gap-2">
            <Button label="Restart" disabled={isLoading} onClick={handleSave} />
            <Button label="Cancel" onClick={() => setShowDialog(false)} />
          </div>
        </div>
      </Dialog>
    </>
  );
}
