import JInputText from "@/base/JInputText";
import Loader from "@/base/Loader";
import { errorOrDefault } from "@/utils";
import { Formik } from "formik";
import NavigationButtons from "./NavigationButtons";
import { useUserContext } from "@/containers/Users/UserContext";
import { useContext, useEffect, useState } from "react";
import { OrganizationSettingKey } from "@/types";
import { toast } from "react-toastify";
import { context } from "./SetupWizard";
import { useDestinationsContext } from "@/containers/Destinations";
import * as Yup from "yup";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Bamboo() {
  const { setModelTitle } = useContext(context);
  const { user, saveOrgSettings, getUserData, orgSettings } = useUserContext();
  const { bamboo_api_key, bamboo_workspace_id } = orgSettings;
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importTeamMembers, setImportTeamMembers] = useState(false);
  const { importDestinationsFromBambooHr } = useDestinationsContext();

  useEffect(() => {
    setModelTitle("Bamboo API Key");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveChanges = async (formValue: { bamboo_api_key: string; bamboo_workspace_id: string }) => {
    const { bamboo_api_key, bamboo_workspace_id } = formValue;

    setIsLoading(true);
    try {
      await saveOrgSettings(OrganizationSettingKey.BAMBOO_API_KEY, bamboo_api_key);
      await saveOrgSettings(OrganizationSettingKey.BAMBOO_WORKSPACE_ID, bamboo_workspace_id);
      if (importTeamMembers) {
        setIsImporting(true);
        await importDestinationsFromBambooHr();
      }
      await getUserData();
    } catch (error) {
      toast.error(errorOrDefault(error, "Something went wrong"));
    } finally {
      setIsLoading(false);
      setIsImporting(false);
    }
  };

  if (isImporting) {
    return (
      <div className="left-0 top-0 flex size-full flex-col items-center justify-center gap-4 bg-[--color-bg]">
        <ProgressSpinner />
        <div className="w-[533px] text-center text-[40px] ">Importing team members from BambooHR...</div>
      </div>
    );
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Formik
        initialValues={{
          bamboo_api_key,
          bamboo_workspace_id,
        }}
        onSubmit={handleSaveChanges}
        enableReinitialize
        validateOnMount
        validationSchema={Yup.object({
          bamboo_api_key: Yup.string().min(1).required("Required"),
          bamboo_workspace_id: Yup.string().min(1).required("Required"),
        })}
      >
        {({ submitForm, isValid }) => (
          <>
            <div className="flex flex-col gap-2">
              <JInputText
                obfuscateIfNoValue={user?.isBambooAvailable}
                id="bamboo_api_key"
                title="BambooHR API key"
                placeholder="BambooHR API key"
                className="w-full"
                inputType="password"
                inputProps={{
                  toggleMask: false,
                }}
              />
              <JInputText
                id="bamboo_workspace_id"
                title="BambooHR workspace title"
                placeholder="MyCompany"
                className="w-full"
              />
              <div className="flex items-center gap-2">
                <input
                  value={importTeamMembers.toString()}
                  type="checkbox"
                  className="size-[16px]"
                  onChange={(e) => {
                    setImportTeamMembers(e.target.checked);
                  }}
                />
                <div className="">Import team members from BambooHR</div>
              </div>
            </div>
            <NavigationButtons isValid={isValid} onNext={submitForm} />
          </>
        )}
      </Formik>
    </>
  );
}
