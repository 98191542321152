import { useCallback, useEffect, useMemo } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { DropdownOption } from "@/types";
import { useDestinationsContext } from "@/containers/Destinations";

interface Props {
  onChange: (channelIds: DropdownOption[]) => void;
  currentChannelIds: string[];
}

export default function SlackChannelAutocompleteMultipleSelectInput({ onChange, currentChannelIds }: Props) {
  const { getSlackChannelsInfo, channels, isFetching } = useDestinationsContext();

  useEffect(() => {
    getSlackChannelsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedChannels = useMemo(
    () => [
      ...channels.map((channel) => ({
        id: channel.id,
        name: channel.name,
      })),
    ],
    [channels]
  );

  const curChannels = useMemo(
    () => mappedChannels.filter((channel) => currentChannelIds.includes(channel.id)),
    [currentChannelIds, mappedChannels]
  );

  const handleChange = useCallback(
    (e: MultiSelectChangeEvent) => {
      const newSelectedChannels = e.value;
      onChange(newSelectedChannels);
    },
    [onChange]
  );

  if (isFetching) {
    return <ProgressSpinner />;
  }

  return (
    <MultiSelect
      className="w-full"
      value={curChannels}
      options={mappedChannels}
      maxSelectedLabels={20}
      onChange={handleChange}
      optionLabel="name"
      placeholder="Add Slack channels"
      display="chip"
    />
  );
}
