import { useCallback, useMemo, useState } from "react";
import { Talk, TalkAdd, TalkUpdate, Ttn } from "./types";
import api from "./api";

const useTalks = () => {
  const [talks, setTalks] = useState<Talk[] | null>([]);
  const [talk, setTalk] = useState<Talk | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [ttns, setTtns] = useState<Ttn[] | null>([]);

  const addTalk = useCallback(
    async (addTalk: TalkAdd) => {
      try {
        setIsFetching(true);
        const newTalksResp = await api.add(addTalk);
        setTalks([...(talks ?? []), newTalksResp.data]);
      } finally {
        setIsFetching(false);
      }
    },
    [talks]
  );

  const updateTalk = useCallback(
    async (id: string, updateTalk: TalkUpdate) => {
      try {
        setIsFetching(true);
        const updatedTalkResp = await api.update(id, updateTalk);
        setTalks((talks ?? []).map((talk) => (talk.id === id ? updatedTalkResp.data : talk)));
      } finally {
        setIsFetching(false);
      }
    },
    [talks]
  );

  const deleteTalk = useCallback(
    async (id: string) => {
      try {
        setIsFetching(true);
        await api.delete(id);
        setTalks((talks ?? []).filter((talk) => talk.id !== id));
      } finally {
        setIsFetching(false);
      }
    },
    [talks]
  );

  const getTalks = useCallback(async () => {
    try {
      setIsFetching(true);
      const newTalksResp = await api.getAll();
      setTalks(newTalksResp.data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getTalk = useCallback(async (id: string) => {
    try {
      setIsFetching(true);
      const newTalkResp = await api.getById(id);
      setTalk(newTalkResp.data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getTtns = useCallback(async (talkId: string) => {
    try {
      setIsFetching(true);
      const newTtnsResp = await api.getTtns(talkId);
      setTtns(newTtnsResp.data);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const updateTtn = useCallback(
    async (talkId: string, id: string, ttn: Ttn) => {
      try {
        setIsFetching(true);
        const updatedTtnResp = await api.updateTtn({ talkId, id, ttn });
        setTtns((ttns ?? []).map((ttn) => (ttn.id === id ? updatedTtnResp.data : ttn)));
      } finally {
        setIsFetching(false);
      }
    },
    [ttns]
  );

  const sendAllTtnsMessages = useCallback(async (talkId: string) => {
    try {
      setIsFetching(true);
      await api.sendAllTtnsMessages(talkId);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const sendDirectTtnMessages = useCallback(async (talkId: string, ttnId: string) => {
    try {
      setIsFetching(true);
      await api.sendDirectTtnMessages(talkId, ttnId);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const importTtnsFromCsv = useCallback(async (talkId: string, formData: FormData) => {
    try {
      setIsFetching(true);
      await api.importTtnsFromCsv(talkId, formData);
    } finally {
      setIsFetching(false);
    }
  }, []);

  return useMemo(
    () => ({
      isFetching,
      importTtnsFromCsv,
      addTalk,
      getTalks,
      updateTalk,
      deleteTalk,
      talks,
      getTalk,
      getTtns,
      updateTtn,
      talk,
      ttns,
      sendAllTtnsMessages,
      sendDirectTtnMessages,
    }),
    [
      ttns,
      addTalk,
      deleteTalk,
      importTtnsFromCsv,
      getTalk,
      getTalks,
      getTtns,
      isFetching,
      sendAllTtnsMessages,
      sendDirectTtnMessages,
      talk,
      talks,
      updateTalk,
      updateTtn,
    ]
  );
};

export default useTalks;
