import axios from "axios";
import { JMessageAddParams, JMessageUpdateParams, MessageGetAllResponse } from "./types";

export default class MessagesService {
  public static get = async (eventId: string, page: number, limit: number, search: string) =>
    axios.get<MessageGetAllResponse>("/messages", { params: { eventId, page, limit, search } });
  public static add = async (message: JMessageAddParams) => axios.post(`/messages`, message);
  public static edit = async (message: JMessageUpdateParams) => axios.patch(`/messages/${message.id}`, message);
  public static delete = async (id: string) => axios.delete(`/messages/${id}`);
}
