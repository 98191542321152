import clsx from "clsx";

interface Props {
  onChangeRepeatOnWeekDays: (day: number) => void;
  repeatOnWeekDays: number[];
}

const Item = ({ label, checked, onChange }: { label: string; checked: boolean; onChange: () => void }) => {
  return (
    <div className="flex gap-2" onClick={onChange}>
      <div
        className={clsx(
          "flex cursor-pointer items-center justify-center rounded-full border-0 p-[10px] text-[10px] font-bold",
          checked ? "bg-white text-black" : "bg-[--color-dark] text-white"
        )}
      >
        {label.toUpperCase()}
      </div>
    </div>
  );
};

export default function WeekDaysCheckboxes({ repeatOnWeekDays, onChangeRepeatOnWeekDays }: Props) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <div className="flex flex-wrap gap-x-2">
          <Item
            label="MO"
            checked={repeatOnWeekDays.includes(1)}
            onChange={() => {
              onChangeRepeatOnWeekDays(1);
            }}
          />
          <Item
            label="TU"
            checked={repeatOnWeekDays.includes(2)}
            onChange={() => {
              onChangeRepeatOnWeekDays(2);
            }}
          />
          <Item
            label="WE"
            checked={repeatOnWeekDays.includes(3)}
            onChange={() => {
              onChangeRepeatOnWeekDays(3);
            }}
          />
          <Item
            label="TH"
            checked={repeatOnWeekDays.includes(4)}
            onChange={() => {
              onChangeRepeatOnWeekDays(4);
            }}
          />
          <Item
            label="FR"
            checked={repeatOnWeekDays.includes(5)}
            onChange={() => {
              onChangeRepeatOnWeekDays(5);
            }}
          />
          <Item
            label="SA"
            checked={repeatOnWeekDays.includes(6)}
            onChange={() => {
              onChangeRepeatOnWeekDays(6);
            }}
          />
          <Item
            label="SU"
            checked={repeatOnWeekDays.includes(0)}
            onChange={() => {
              onChangeRepeatOnWeekDays(0);
            }}
          />
          {/* <div className="flex gap-2">
            <Label htmlFor="weekends" title="Monday" />
            <Checkbox
              inputId="monday"
              value="monday"
              onChange={() => {
                onChangeRepeatOnWeekDays(1);
              }}
              checked={repeatOnWeekDays.includes(1)}
            />
          </div>
          <div className="flex gap-2">
            <Label htmlFor="weekends" title="Tuesday" />
            <Checkbox
              inputId="tuesday"
              value="tuesday"
              onChange={() => {
                onChangeRepeatOnWeekDays(2);
              }}
              checked={repeatOnWeekDays.includes(2)}
            />
          </div>
          <div className="flex gap-2">
            <Label htmlFor="weekends" title="Wednesday" />
            <Checkbox
              inputId="wednesday"
              value="wednesday"
              onChange={() => {
                onChangeRepeatOnWeekDays(3);
              }}
              checked={repeatOnWeekDays.includes(3)}
            />
          </div>
          <div className="flex gap-2">
            <Label htmlFor="weekends" title="Thursday" />
            <Checkbox
              inputId="thursday"
              value="thursday"
              onChange={() => {
                onChangeRepeatOnWeekDays(4);
              }}
              checked={repeatOnWeekDays.includes(4)}
            />
          </div>
          <div className="flex gap-2">
            <Label htmlFor="weekends" title="Friday" />
            <Checkbox
              inputId="friday"
              value="friday"
              onChange={() => {
                onChangeRepeatOnWeekDays(5);
              }}
              checked={repeatOnWeekDays.includes(5)}
            />
          </div>
          <div className="flex gap-2">
            <Label htmlFor="weekends" title="Saturday" />
            <Checkbox
              inputId="saturday"
              value="saturday"
              onChange={() => {
                onChangeRepeatOnWeekDays(6);
              }}
              checked={repeatOnWeekDays.includes(6)}
            />
          </div>
          <div className="flex gap-2">
            <Label htmlFor="weekends" title="Sunday" />
            <Checkbox
              inputId="sunday"
              value="sunday"
              onChange={() => {
                onChangeRepeatOnWeekDays(0);
              }}
              checked={repeatOnWeekDays.includes(0)}
            />
          </div>*/}
        </div>
      </div>
    </div>
  );
}
