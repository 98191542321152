import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useMemo, useRef } from "react";
import { PromptAddValues, PromptDTO, PromptUpdateValues } from "./type";
import { EventType, ModalOperationType } from "../../types";
import { useUserContext } from "../Users/UserContext";
import { toast } from "react-toastify";
import { promptUpdateSchema } from "../../schemas";
import JInputText from "../../base/JInputText";
import JTextArea from "../../base/JTextArea/JTextArea";
import { errorOrDefault, getFormikFromRef } from "@/utils";
import JDropdown from "@/base/JDropdown/JDropdown";
import { useEventsContext } from "../Events/EventsContext";
import { EventDTO } from "../Events/types";
import { Checkbox } from "primereact/checkbox";

type FormikValues = {
  title: string;
  prompt: string;
  eventId: string;
  role: string | null;
  isGroup: boolean;
};

const PromptAddEditModal = ({
  prompt,
  onClose,
}: {
  prompt?: PromptDTO | null;
  onClose: () => void;
  formType?: ModalOperationType;
}) => {
  const { isPromptFetching, updatePrompt, getPrompts, addPrompt } = useUserContext();
  const { user } = useUserContext();
  const isSuperAdmin = user?.isSuperAdmin;
  const { events, getEvents } = useEventsContext({
    onSetFirstEvent: () => {
      return;
    },
  });

  const isNew = !prompt?.id;

  const formikRef = useRef(null);

  useEffect(() => {
    getEvents({
      globalOnly: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: FormikValues) => {
    try {
      if (isNew) {
        await addPrompt({ ...values });
        toast.success("Prompt added");
        onClose();
        return;
      }

      await updatePrompt({ ...values, id: prompt.id });
      toast.success("Prompt updated");
      onClose();
    } catch (err) {
      console.error(err);
      toast.error(errorOrDefault(err, "Error updating prompt"));
    }
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        label={"Save"}
        icon="pi pi-check"
        type="submit"
        onClick={() => {
          const formik = getFormikFromRef<PromptAddValues | PromptUpdateValues>(formikRef);
          formik.submitForm();
        }}
        loading={isPromptFetching}
      />
    </div>
  );

  const eventOptions: EventDTO[] = useMemo(() => {
    const newEvents = (isSuperAdmin ? events : events?.filter((e) => e.type !== EventType.PERSONAL)) ?? [];

    return newEvents;
  }, [events, isSuperAdmin]);

  useEffect(() => {
    getPrompts({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik<FormikValues>
      innerRef={formikRef}
      initialValues={
        prompt
          ? {
              title: prompt.title,
              prompt: prompt.prompt,
              eventId: prompt.event.id,
              role: prompt.role,
              isGroup: prompt.isGroup,
            }
          : {
              title: "",
              prompt: "",
              eventId: "",
              role: null,
              isGroup: false,
            }
      }
      validationSchema={promptUpdateSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Dialog header={isNew ? "Create prompt" : "Edit prompt"} visible={true} onHide={onClose} footer={footerContent}>
          <Form>
            <div className="py-4">
              <JInputText id="title" title="Title" className="w-full" />
              <JTextArea id="prompt" title="Prompt" className="w-full" rows={4} />
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="font-bold">Group message</span>
                  <Checkbox
                    inputId="isGroup"
                    name="isGroup"
                    value={true}
                    checked={props.values.isGroup}
                    onChange={(e) => {
                      const isChecked = e.checked;
                      props.setFieldValue("isGroup", isChecked);
                      props.setFieldValue("role", null);
                    }}
                  />
                </div>
                {!props.values.isGroup && isSuperAdmin && <JInputText id="role" title="Role" className="w-full" />}
              </div>
              <JDropdown
                id="eventId"
                title="Event"
                options={eventOptions}
                optionLabel="title"
                optionValue="id"
                onChange={(e) => {
                  props.setFieldValue("eventId", e.value);
                }}
              />
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default PromptAddEditModal;
