import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useCallback, useMemo } from "react";
import { useUserContext } from "../containers/Users/UserContext";

interface Props {
  currentManagerIds: string[];
  onChange: (managers: string[]) => void;
}

export default function ChannelManagersAutocompleteMultipleSelectInput({ currentManagerIds, onChange }: Props) {
  const { users, user } = useUserContext();

  const isChannelManager = user?.type === "USERS";

  const mappedUsers = useMemo(
    () => [
      ...users
        .filter((u) => u.type === "USERS")
        .map((user) => ({
          id: user.id,
          name: user.email,
        })),
    ],
    [users]
  );

  const handleChange = useCallback(
    (e: MultiSelectChangeEvent) => {
      const newSelected = e.value;
      onChange(newSelected);
    },
    [onChange]
  );

  const currentValue = useMemo(() => {
    return currentManagerIds.map((id) => mappedUsers.find((u) => u.id === id)).filter((u) => !!u);
  }, [currentManagerIds, mappedUsers]);

  if (isChannelManager) {
    return null;
  }

  return (
    <MultiSelect
      className="w-full"
      value={currentValue}
      options={mappedUsers}
      maxSelectedLabels={20}
      onChange={handleChange}
      optionLabel="name"
      placeholder="Select managers"
      display="chip"
    />
  );
}
