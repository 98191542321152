import Loader from "@/base/Loader";
import { errorOrDefault } from "@/utils";
import { Formik } from "formik";
import { useUserContext } from "@/containers/Users/UserContext";
import { useContext, useEffect, useState } from "react";
import { OrganizationSettingKey } from "@/types";
import { toast } from "react-toastify";
import { context } from "./SetupWizard";
import * as Yup from "yup";
import SlackIntegrationButton from "@/components/SlackIntegrationButton";
import SlackSyncSvg from "./slackSync.svg?react";
import { Button } from "primereact/button";

export default function SlackApiKey() {
  const { setModelTitle, onClose } = useContext(context);
  const { saveOrgSettings, getUserData, orgSettings } = useUserContext();
  const { slack_api_key } = orgSettings;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setModelTitle("Slack");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveChanges = async (formValue: { slack_api_key: string }) => {
    const { slack_api_key } = formValue;

    setIsLoading(true);
    try {
      await saveOrgSettings(OrganizationSettingKey.SLACK_API_KEY, slack_api_key);
      await getUserData();
    } catch (error) {
      toast.error(errorOrDefault(error, "Something went wrong"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Formik
        initialValues={{
          slack_api_key,
        }}
        onSubmit={handleSaveChanges}
        enableReinitialize
        validateOnMount
        validationSchema={Yup.object({
          slack_api_key: Yup.string().min(1).required("Required"),
        })}
      >
        {() => (
          <>
            <div className="flex h-full flex-col items-center justify-center gap-2">
              {/* 
                <JInputText
                obfuscateIfNoValue={user?.isSlackAvailable}
                inputType="password"
                inputProps={{
                  toggleMask: false,
                }}
                id="slack_api_key"
                title="Slack API token"
                placeholder="Please enter token"
                className="w-full"
                /> 
                */}
              <SlackSyncSvg />
              <div className="mt-2 w-[400px] text-[#969595]">
                API tokens are the keys to the Slack platform. Tokens tie together all the scopes and permissions your
                app has obtained, allowing it to read, write, and interact.
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <Button
                text
                label="Cancel"
                onClick={() => {
                  onClose();
                }}
              />
              <SlackIntegrationButton />
            </div>
            {/* <NavigationButtons isValid={isValid} onNext={submitForm} /> */}
          </>
        )}
      </Formik>
    </>
  );
}
