import Loader from "@/base/Loader";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { IconCircleCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../Users/UserContext";
import { useDebouncedCallback } from "use-debounce";

export default function SlackRedirect() {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { getUserData } = useUserContext();
  const navigate = useNavigate();

  const slackRedirectDebounced = useDebouncedCallback(async () => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (!code) {
      toast.error("No code found in the url");
      setTimeout(() => {
        navigate("/");
      }, 1000);
      return;
    }
    setLoading(true);
    return await axios
      .get(`/slackIntegration?code=${code}`)
      .then(() => {
        setSuccess(true);
        getUserData();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 5000);
      });
  }, 1000);

  useLayoutEffect(() => {
    slackRedirectDebounced();
  }, [slackRedirectDebounced]);

  return (
    <div>
      <Loader isLoading={loading} />
      {
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {success && (
            <>
              <IconCircleCheck size={"32rem"} color="#fa961d" />
              <div
                style={{
                  fontSize: "2rem",
                  color: "#fa961d",
                  marginTop: "2rem",
                }}
              >
                Slack Integration Successful
              </div>
            </>
          )}
          {error && (
            <div
              style={{
                fontSize: "2rem",
              }}
            >
              Something went wrong, please try again later
            </div>
          )}
        </div>
      }
    </div>
  );
}
