import PlusIcon from "./plus.svg?react";
import MinusIcon from "./minus.svg?react";
import HealthCheckIcon from "@/assets/events/healthCheck.svg?react";
import SettingsIcon from "@/assets/edit-settings.svg?react";
import TrashIcon from "@/assets/trash.svg?react";
import { HealthCheck } from "./HealthCheck.types";
import { errorOrDefault, getDayName } from "@/utils";
import useHealthCheckContext from "./useHealthCheck";
import { useState } from "react";
import { IconSend } from "@tabler/icons-react";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { toast } from "react-toastify";
import { recipientAvatarsBody } from "./utils";
import { ModalOperationType } from "@/types";
import { Dialog } from "primereact/dialog";
import { HealthCheckCreate } from "./HealthCheckCreate";
import HealthCheckItemDetails from "./HealthCheckItemDetails";

interface Props {
  healthCheck: HealthCheck;
}

export default function HealthCheckItem({ healthCheck }: Props) {
  const { updateHealthCheck, deleteHealthCheck, getAllHealthChecks, createHealthCheckNotification } =
    useHealthCheckContext();

  const [showUpdateModal, setShowUpdateModal] = useState<ModalOperationType>(null);
  const [curModalData, setCurModalData] = useState<HealthCheck | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleActive = async (id: string, isActive: boolean) => {
    await updateHealthCheck(id, { isActive: !isActive });
    await getAllHealthChecks();
  };

  const handleModalClose = () => {
    setShowUpdateModal(null);
    getAllHealthChecks();
  };

  const controlBodyTemplate = (healthCheck: HealthCheck) => {
    const isActive = healthCheck.isActive;
    return (
      <div className="group flex items-center gap-4">
        <Tooltip target="#edit" position="bottom" content="Edit" />
        <SettingsIcon
          id="edit"
          className="cursor-pointer"
          onClick={() => {
            setCurModalData(healthCheck);
            setTimeout(() => setShowUpdateModal("update"), 100);
          }}
        />
        {isActive && (
          <>
            <Tooltip target="#send" position="bottom" content="Add to queue" />
            <IconSend
              id="send"
              className="size-[16px] cursor-pointer text-[var(--color-fg-darker)] group-hover:visible"
              onClick={() => {
                try {
                  createHealthCheckNotification(healthCheck.id);
                  toast.success("Notification created successfully");
                } catch (e) {
                  toast.error(errorOrDefault(e, "Error creating notification"));
                }
              }}
            ></IconSend>
          </>
        )}
        <Tooltip target="#delete" position="bottom" content="Delete" />
        <TrashIcon
          id="delete"
          className="cursor-pointer"
          onClick={async () => {
            try {
              if (window.confirm("Are you sure you want to delete this health check?")) {
                await deleteHealthCheck(healthCheck.id);
                toast.success("Health check deleted successfully");
                await getAllHealthChecks();
              }
            } catch (e) {
              toast.error(errorOrDefault(e, "Error deleting health check"));
            }
          }}
        />
      </div>
    );
  };

  const isActiveBodyTemplate = ({ isActive, id }: HealthCheck) => {
    return (
      <div className="flex items-center justify-start">
        <Tooltip target={`#active-${id}`} position="bottom" content={isActive ? "Deactivate" : "Activate"} />
        <InputSwitch id={`active-${id}`} checked={isActive} onChange={() => toggleActive(id, isActive)} />
      </div>
    );
  };

  // const healthDetailsTemplate = ({ destinations }: { destinations: Destination[] }) => {
  //   if (!destinations) {
  //     return null;
  //   }

  //   return (
  //     <Panel className="w-[500px]" header="Recipients" toggleable collapsed>
  //       <DataTable sortField="name" sortOrder={1} value={destinations || []}>
  //         <Column sortable header="Name" body={nameBodyTemplate}></Column>
  //       </DataTable>
  //     </Panel>
  //   );
  // };

  // const daysBodyTemplate = ({ days }: HealthCheck) => {
  //   const daysMap: Record<number, string> = {
  //     1: "Mon",
  //     2: "Tue",
  //     3: "Wed",
  //     4: "Thu",
  //     5: "Fri",
  //     6: "Sat",
  //     7: "Sun",
  //   };
  //   return days.map((day) => daysMap[day]).join(", ");
  // };

  // const startAtBodyTemplate = ({ notifyMin, notifyHour }: HealthCheck) => {
  //   return (
  //     <div>
  //       {notifyHour.toString().padStart(2, "0")}:{notifyMin.toString().padStart(2, "0")}
  //     </div>
  //   );
  // };

  // const promptMessageTemplate = ({ prompt, welcomeMessage }: HealthCheck) => {
  //   if (prompt) {
  //     return (
  //       <div>
  //         <b>Prompt: </b>
  //         {prompt}
  //       </div>
  //     );
  //   }

  //   return (
  //     <div>
  //       <b>Message: </b>
  //       {welcomeMessage}
  //     </div>
  //   );
  // };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full items-center">
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="mr-[20px] flex size-[56px] cursor-pointer items-center justify-center bg-[--color-dark]"
        >
          {isCollapsed ? <PlusIcon /> : <MinusIcon />}
        </div>
        <div className="flex w-full">
          <div className="flex min-w-[320px] gap-4">
            <HealthCheckIcon className="size-[56px]" />
            <div className="flex flex-col gap-2">
              <span className="text-[20px] font-bold">{healthCheck.title}</span>
              <div className="flex flex-row gap-1">
                {healthCheck.days.map((day, index) => (
                  <span className="">
                    {getDayName(day)}
                    {index === healthCheck.days.length - 1 ? "" : ", "}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="mx-[26px] h-[56px] w-[2px] shrink-0 bg-[--color-dark]"> </div>
          <div className="flex min-w-[80px] max-w-[80px] justify-center text-[32px] font-extralight">
            {healthCheck.notifyHour}:{healthCheck.notifyMin}
          </div>
          <div className="mx-[26px] h-[56px] w-[2px] shrink-0 bg-[--color-dark]"> </div>
          <div className="flex w-full min-w-[300px] justify-start">{recipientAvatarsBody(healthCheck)}</div>
          <div className="mx-[26px] h-[56px] w-[2px] shrink-0 bg-[--color-dark]"> </div>
          <div className="flex justify-center">{isActiveBodyTemplate(healthCheck)}</div>
          <div className="mx-[26px] h-[56px] w-[2px] shrink-0 bg-[--color-dark]"> </div>
          <div className="flex min-w-[120px] justify-end">{controlBodyTemplate(healthCheck)}</div>
        </div>
      </div>
      {showUpdateModal === "update" && (
        <Dialog header="Update Health Check" visible onHide={handleModalClose}>
          <HealthCheckCreate type="update" onClose={handleModalClose} healthCheck={curModalData} />
        </Dialog>
      )}
      {!isCollapsed && <HealthCheckItemDetails healthCheck={healthCheck} />}
    </div>
  );
}
