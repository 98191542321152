import { Field, FieldInputProps, FieldMetaProps } from "formik";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import styles from "./JDropdown.module.scss";

interface JDropdownProps extends DropdownProps {
  noLabel?: boolean;
  sublabel?: string;
  id: string;
  title: string;
  options: unknown[];
}

export default function JDropdown(props: JDropdownProps) {
  return (
    <Field name={props.id}>
      {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
        <div
          className={classNames(styles.root, "field", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.title && !props.noLabel && (
            <label className="font-bold" htmlFor={props.id}>
              {props.title}
            </label>
          )}
          {props.sublabel && <span className={classNames("sublabel")}>{props.sublabel}</span>}
          <Dropdown
            showClear
            placeholder={props.placeholder}
            disabled={props.disabled}
            {...field}
            {...props}
            className="w-full"
          />
          {meta.touched && meta.error && <sub className="p-1 text-pink-600">{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
