import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ModalOperationType } from "../../types";
import { PromptDTO } from "./type";
import { useUserContext } from "../Users/UserContext";
import PromptAddEditModal from "./PromptAddEditModal";
import Loader from "@/base/Loader";
import { useEventsContext } from "../Events/EventsContext";
import { confirmPopup } from "primereact/confirmpopup";
import { toast } from "react-toastify";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import PageTitleWrapper from "@/components/PageTitleWrapper";

const PromptList = () => {
  const [showModal, setShowModal] = useState<ModalOperationType>(null);
  const [curPrompt, setCurPrompt] = useState<PromptDTO | null>(null);

  const { user, getPrompts, prompts, isPromptFetching, isChannelManager, deletePrompt } = useUserContext();
  const { events } = useEventsContext({
    onSetFirstEvent: () => {
      return;
    },
  });

  const isSuperAdmin = user?.isSuperAdmin;
  /// const isPromptCreatingAllowed = (events?.filter((e) => !prompts?.find((p) => p.event.id === e.id)) ?? [])?.length > 0;
  const isPromptCreatingAllowed = true;

  useEffect(() => {
    getPrompts({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirm = (id: string) => {
    const confirmationMessageRef = document.getElementById("confirmMessage");
    if (!confirmationMessageRef) {
      console.error("Confirmation message is not defined");
      return;
    }
    confirmPopup({
      target: confirmationMessageRef,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await deletePrompt(id);
          toast.success("Message deleted");
        } catch (err) {
          console.error(err);
          toast.error("Error deleting message");
        } finally {
          await getPrompts({});
        }
      },
      reject: () => {
        return;
      },
    });
  };

  const statusCellRender = (rawData: PromptDTO) => {
    return <>{events?.find((e) => e.id === rawData.event.id)?.title ?? ""}</>;
  };

  const operationsBodyTemplate = (rawData: PromptDTO) => (
    <>
      <div className="flex gap-4">
        <Edit
          className="cursor-pointer"
          onClick={() => {
            setCurPrompt(rawData);
            setShowModal("update");
          }}
        />

        <Trash className="cursor-pointer" onClick={() => confirm(rawData.id)} />
      </div>
    </>
  );

  if (!isSuperAdmin && user?.formattedOrgSettings?.feature_flag_shared_custom_event !== true) {
    return <Navigate to={"/"} state={{ returnUrl: window.location.pathname }} replace />;
  }

  return (
    <div>
      <Loader isLoading={isPromptFetching} />

      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Prompts</span>
          </>
        }
        row2={
          !isChannelManager && (
            <Button
              tooltip={!isPromptCreatingAllowed ? "All messages are already created" : ""}
              tooltipOptions={{ position: "left" }}
              label="Add new prompt"
              icon="pi pi-plus"
              onClick={() => {
                if (!isPromptCreatingAllowed) return;

                setShowModal("create");
              }}
              className="ml-5"
            />
          )
        }
      />

      <DataTable value={prompts || []}>
        <Column field="title" header="Title"></Column>
        <Column field="prompt" header="Prompt"></Column>
        {isSuperAdmin && (
          <Column
            field="isGroup"
            header="Group"
            body={(rawData) => {
              return rawData?.isGroup ? "Yes" : "No";
            }}
          ></Column>
        )}
        {isSuperAdmin && <Column field="role" header="Role"></Column>}
        <Column header="Event" body={statusCellRender}></Column>
        <Column header="Operations" body={operationsBodyTemplate}></Column>
      </DataTable>
      {showModal && (
        <PromptAddEditModal
          onClose={() => {
            setShowModal(null);
            setCurPrompt(null);
          }}
          formType={showModal}
          prompt={curPrompt}
        />
      )}
    </div>
  );
};

export default PromptList;
