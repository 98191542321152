import dayjs from "dayjs";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useMemo, useState } from "react";
import { confirmPopup } from "primereact/confirmpopup";
import MessageModal from "./MessageModal/MessageModal";
import { JMessage } from "../types";
import useDebounce from "../../../utils/useDebounce";
import { useMessagesContext } from "../MessagesContext";
import { toast } from "react-toastify";
import Loader from "@/base/Loader/Loader";
import { useUserContext } from "@/containers/Users/UserContext";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import { useEventsContext } from "@/containers/Events/EventsContext";
import EventsNav from "@/components/EventsNav";
import PageTitleWrapper from "@/components/PageTitleWrapper";
import { MultiSelect } from "primereact/multiselect";
import { DropdownOption } from "@/types";
import { isGroupableEvent } from "@/utils";

const MessageList = () => {
  const [eventId, setEventId] = useState<string>();
  const [page] = useState<number>(0);
  const [limit] = useState<number>(200);
  const [search] = useState<string>("");
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [message, setMessage] = useState<JMessage | null>(null);

  const { isEventsFetching, events } = useEventsContext({
    onSetFirstEvent: setEventId,
  });
  const { messages, getMessages, deleteMessage, isFetching, alreadyUsed } = useMessagesContext();
  const { isSuperAdmin, orgSettings } = useUserContext();
  const [selectedRoles, setSelectedRoles] = useState<DropdownOption[]>([]);
  // const [showGrouped, setShowGrouped] = useState<boolean>(false);
  // const [showPersonal, setShowPersonal] = useState<boolean>(false);

  const isEditable = (message: JMessage): boolean => isSuperAdmin || !message?.isShared;

  let isMessagesLimitReached = alreadyUsed >= orgSettings.messages_free_limit;

  if (isSuperAdmin) {
    isMessagesLimitReached = false;
  }

  const debouncedSearch = useDebounce<string>(search, 500);

  const curEvent = useMemo(() => events?.find((e) => e.id === eventId), [events, eventId]);
  const isGroupableEventMemo = useMemo(() => isGroupableEvent(events, curEvent?.id), [curEvent?.id, events]);

  useEffect(() => {
    const fn = async () => {
      if (!events || !eventId) {
        return;
      }

      try {
        await getMessages(eventId, page, limit, search);
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, debouncedSearch, eventId, events, search]);

  /**
   const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onPaginatorChange = (e: PaginatorPageChangeEvent) => {
    setLimit(e.rows);
    setPage(e.page);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={() => setSearch("")} />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={search} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </span>
      </div>
    );
  }; 
  */

  const rolesOptions = useMemo(
    () => messages?.map((m) => ({ id: m.role, name: m.role })).filter((r) => !!r.id) ?? [],
    [messages]
  );

  const createdBodyTemplate = ({ createdAt }: { createdAt: string }) => renderDate(createdAt);
  const updatedBodyTemplate = ({ updatedAt }: { updatedAt: string }) => renderDate(updatedAt);
  const renderDate = (dateStr?: string) => {
    if (!dateStr) {
      console.error("Date string is not defined");
      return null;
    }
    return dayjs(dateStr).format("DD MMM YYYY");
  };

  const operationsBodyTemplate = (rawData: JMessage) => (
    <>
      {isEditable(rawData) && (
        <div className="flex gap-4">
          <Edit
            className="cursor-pointer"
            onClick={() => {
              setMessage(rawData);
              setShowMessageModal(true);
            }}
          />

          <Trash className="cursor-pointer" onClick={() => confirm(rawData.id)} />
        </div>
      )}
    </>
  );

  const confirm = (id: string) => {
    const confirmationMessageRef = document.getElementById("confirmMessage");
    if (!confirmationMessageRef) {
      console.error("Confirmation message is not defined");
      return;
    }
    confirmPopup({
      target: confirmationMessageRef,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        if (!eventId) {
          console.error("Event ID is not defined");
          return;
        }

        try {
          await deleteMessage(id);
          toast.success("Message deleted");
        } catch (err) {
          console.error(err);
          toast.error("Error deleting message");
        } finally {
          await getMessages(eventId);
        }
      },
      reject: () => null,
    });
  };

  const filteredMessages = useMemo(() => {
    if (!selectedRoles.length) {
      return messages;
    }

    return messages?.filter((m) => selectedRoles.find((f) => f.id === m.role)) ?? [];
  }, [messages, selectedRoles]);

  return (
    <div className="flex flex-col gap-4">
      <Loader isLoading={isFetching || isEventsFetching} />
      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Messages</span>
            <EventsNav events={events} setEventId={setEventId} eventId={eventId} />
          </>
        }
        row2={
          <>
            <Button
              tooltipOptions={{ position: "bottom" }}
              tooltip={isMessagesLimitReached ? "You have reached your limit. Please reach out to support" : ""}
              label="Add new message"
              icon="pi pi-plus"
              onClick={() => {
                if (isMessagesLimitReached) {
                  return;
                }
                setShowMessageModal(true);
              }}
            />
            {!isSuperAdmin && (
              <div className="text-sm">
                Already used message: {alreadyUsed}/{orgSettings.messages_free_limit}
              </div>
            )}
          </>
        }
      />
      <div>
        {isGroupableEventMemo && (
          <MultiSelect
            value={selectedRoles}
            options={rolesOptions}
            maxSelectedLabels={10}
            className="w-full max-w-[300px]"
            onChange={(e) => {
              const newSelectedRoles = e.value;
              setSelectedRoles(newSelectedRoles);
            }}
            optionLabel="name"
            placeholder="Select roles"
            display="chip"
          />
        )}
      </div>

      <DataTable
        className="w-full"
        /** 
        header={renderHeader()}
        selection={selectedRows}
        onSelectionChange={(e: DataTableSelectionChangeEvent<any>) => setSelectedRows(e.value as JMessage[])} 
        */
        value={filteredMessages || []}
        sortOrder={-1}
        sortField="createdAt"
      >
        {/** <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column> */}
        <Column field="title" header="Title" sortable></Column>
        <Column field="message" header="Text"></Column>
        {isGroupableEventMemo && <Column field="role" header="Role"></Column>}
        {isGroupableEventMemo && (
          <Column
            field="isGroup"
            header="Group"
            body={(rawData) => {
              return rawData?.isGroup ? "Yes" : "No";
            }}
          ></Column>
        )}
        <Column
          field="createdAt"
          header="Created"
          sortable
          body={createdBodyTemplate}
          style={{ whiteSpace: "nowrap" }}
        ></Column>
        <Column
          field="updatedAt"
          header="Updated"
          sortable
          body={updatedBodyTemplate}
          style={{ whiteSpace: "nowrap" }}
        ></Column>
        <Column header="Operations" body={operationsBodyTemplate}></Column>
      </DataTable>

      {/** 
      <Paginator
        rows={limit}
        totalRecords={messages?.total ?? 0}
        rowsPerPageOptions={[20, 50, 100, 200]}
        onPageChange={onPaginatorChange}
      /> 
      */}

      {showMessageModal && eventId && (
        <MessageModal
          message={
            message
              ? {
                  ...message,
                  eventId: eventId,
                }
              : null
          }
          eventId={eventId}
          onClose={(eventId?: string) => {
            setShowMessageModal(false);
            setMessage(null);
            if (eventId) {
              setEventId(eventId);
            }
          }}
        />
      )}
    </div>
  );
};

export default MessageList;
