import SuccessIcon from "@/assets/success.svg?react";
import NavigationButtons from "./NavigationButtons";
import { useContext, useEffect } from "react";
import { context } from "./SetupWizard";
import { useDestinationsContext } from "@/containers/Destinations";

export default function Success() {
  const { setModelTitle } = useContext(context);
  const { getDestinations } = useDestinationsContext();

  useEffect(() => {
    setModelTitle("Done");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex size-full flex-col items-center justify-center gap-2">
        <SuccessIcon />
        <div className="text-[40px] ">Success!</div>
        <div className="text-[#B5B5B5]">Everything is set up.</div>
      </div>
      <NavigationButtons
        onNext={async () => {
          await getDestinations();
        }}
      />
    </>
  );
}
