import clsx from "clsx";

export default function TypeSelector({
  children,
  isActive,
  onClick,
}: {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <span
      className={clsx(
        "flex h-[40px] w-max cursor-pointer items-center justify-center whitespace-nowrap rounded-[40px] border-2 border-transparent px-[20px] ",
        isActive && "border-2 !border-[#FA961D]"
      )}
      onClick={onClick}
    >
      {children}
    </span>
  );
}
