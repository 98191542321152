import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ReactEventHandler, useEffect, useRef, useState } from "react";
import { useAssetContext } from "../../AssetContext";
import { Message } from "primereact/message";
import { toast } from "react-toastify";
import EventsNav from "@/components/EventsNav";
import { useEventsContext } from "@/containers/Events/EventsContext";

export interface UploadError {
  file: string;
  error: string;
}
interface Props {
  onClose: (eventId?: string) => void;
  initEventId: string;
}

const MAX_FILE_SIZE = 2 * 1024 * 1024; /// TODO: Move to config

const ACCEPT_TYPES = ["image/gif", "image/jpg", "image/jpeg", "image/png"];

function toMb(bytes: number) {
  return (bytes / 1024 / 1024).toFixed(2);
}

const AssetModal = ({ onClose, initEventId }: Props) => {
  const { isAssetsFetching, addAsset, getAssets } = useAssetContext();
  const [eventId, setEventId] = useState<string | undefined>(initEventId);
  const { events } = useEventsContext();
  const ref = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [, setWrapperWidth] = useState(0);

  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<UploadError[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(0);
  const images = files.length;

  const onFileChange = (event: {
    target: { files: FileList | null };
    preventDefault: ReactEventHandler<HTMLFormElement>;
  }) => {
    const selectedFiles: FileList | null = event?.target?.files;

    if (!selectedFiles || !selectedFiles.length) {
      console.error("No files selected");
      return;
    }

    const newFiles: File[] = [];
    const newErrors: UploadError[] = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const curFile = selectedFiles[i];
      if (!curFile) {
        console.error(`File ${i} is undefined`);
        continue;
      }
      const fileSize = curFile.size;
      const fileName = curFile.name;
      const fileType = curFile.type;

      if (!ACCEPT_TYPES.includes(fileType)) {
        newErrors.push({ file: fileName, error: "Only GIF, JPG, JPEG, PNG files are allowed" });
        continue;
      }

      if (fileSize > MAX_FILE_SIZE) {
        newErrors.push({ file: fileName, error: `Asset size exceeds ${toMb(MAX_FILE_SIZE)}MB limit` });
        continue;
      }

      newFiles.push(curFile);
    }

    setFiles([...files, ...newFiles]);
    setErrors([...errors, ...newErrors]);
    const refCur = ref?.current;
    if (refCur) {
      refCur.value = "";
    }
  };

  const onFormSubmit = async (event: { preventDefault: () => void }) => {
    if (!files.length || !eventId) {
      return;
    }

    const uploadingArr = [];

    try {
      setIsUploading(true);
      for (const file of files) {
        event.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("eventId", eventId);
        uploadingArr.push(
          addAsset(formData)
            .then(() => {
              setFileLoaded((prev) => prev + 1);
            })
            .catch((error) => {
              const errorMsg = `There was an error uploading the file ${file.name}`;
              console.error(errorMsg + `. Error: `, error?.message ?? error);
              toast.error(errorMsg);
            })
        );
      }
      await Promise.allSettled(uploadingArr);
      toast.success(`${images} asset added successfully`);
    } catch (error) {
      console.error(error);
      toast.error("Network error, please try again later");
      return;
    } finally {
      setIsUploading(false);
      getAssets(eventId);
    }

    onClose(eventId);
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => onClose(eventId)} className="p-button-text" />
      <Button label={"Add"} icon="pi pi-check" type="submit" loading={isAssetsFetching} onClick={onFormSubmit} />
    </div>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const curWrapperRef = wrapperRef?.current;
    if (curWrapperRef) {
      const width = curWrapperRef.getBoundingClientRect().width;
      setWrapperWidth(width);
    }
  });

  useEffect(() => {
    setFiles([]);
    setErrors([]);
    const refCur = ref?.current;
    if (!refCur) {
      console.error("Ref is undefined");
      return;
    }
    ref.current.value = "";
  }, [eventId]);

  return (
    <>
      <input ref={ref} type="file" hidden onChange={onFileChange} accept={ACCEPT_TYPES.join(",")} multiple />

      <Dialog header={"Add new assets"} visible={true} onHide={onClose} footer={footerContent}>
        <div>
          <div className="flex w-full justify-between overflow-hidden">
            <EventsNav events={events} setEventId={setEventId} eventId={eventId} />
            {files?.length > 0 && (
              <div className="flex gap-2">
                <Button
                  label="Add more assets"
                  className=""
                  raised
                  onClick={() => {
                    const refCur = ref?.current;
                    if (!refCur) {
                      console.error("Ref is undefined");
                      return;
                    }
                    ref.current.click();
                  }}
                />
                <Button
                  label="Clear"
                  className="mr-2 "
                  raised
                  onClick={() => {
                    setFiles([]);
                    const refCur = ref?.current;
                    if (!refCur) {
                      console.error("Ref is undefined");
                      return;
                    }
                    ref.current.value = "";
                    setErrors([]);
                  }}
                />
              </div>
            )}
          </div>
          <div className="py-4">
            <div className="relative flex h-full max-h-[40vh] min-h-[40vh] flex-col items-center justify-center gap-4 border-0 p-2">
              {isUploading && (
                <div className="absolute z-50 flex size-full flex-col items-center justify-center gap-2 bg-white/90">
                  <div>
                    Uploading {fileLoaded} of {images} assets
                  </div>
                  <div className="h-[20px] w-full max-w-[500px] rounded-full bg-gray-200">
                    <div
                      className="h-full rounded-full bg-green-500"
                      style={{ width: `${(fileLoaded / images) * 100}%` }}
                    ></div>
                  </div>
                </div>
              )}
              {files?.length > 0 && (
                <div ref={wrapperRef} className="grid size-full gap-4 overflow-auto py-4">
                  {errors?.length > 0 && (
                    <div className="flex w-full flex-col gap-2 px-2">
                      {errors.map((error) => {
                        return <Message severity="error" text={`File ${error.file}: ${error.error}`} />;
                      })}
                    </div>
                  )}
                  {files.map((file, index) => {
                    return (
                      <div key={index} className="group relative">
                        <div className="invisible absolute right-0 top-0 cursor-pointer rounded-md bg-white p-2 group-hover:visible">
                          <i
                            // eslint-disable-next-line tailwindcss/no-custom-classname
                            className="pi pi-trash text-2xl  text-red-500"
                            onClick={() => {
                              const newFiles = [...files];
                              newFiles.splice(index, 1);
                              setFiles(newFiles);
                            }}
                          ></i>
                        </div>
                        <Image
                          src={URL.createObjectURL(file)}
                          key={file.name}
                          alt={file.name}
                          width="200px"
                          height="200px"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {files?.length === 0 && !isUploading && (
                <Button
                  label="Upload image"
                  className=""
                  raised
                  onClick={() => {
                    const refCur = ref?.current;
                    if (!refCur) {
                      return;
                    }
                    refCur.click();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AssetModal;
