import { createContext, useContext } from "react";
import { TalksContextProps } from "./types";
import useTalks from "./useTalks";

export const TalkConetxt = createContext<TalksContextProps | null>(null);

export const useTalksContext = () => {
  const context = useContext(TalkConetxt);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

export const TalksContextProvider = ({ children }: React.PropsWithChildren) => {
  const data = useTalks();
  return (
    <TalkConetxt.Provider
      value={{
        ...data,
      }}
    >
      {children}
    </TalkConetxt.Provider>
  );
};
