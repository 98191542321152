import { PropsWithChildren, createContext, useContext } from "react";
import useDestination from "./useDestination";

export const DestinationContext = createContext<ReturnType<typeof useDestination> | null>(null);

export const useDestinationsContext = () => {
  const context = useContext(DestinationContext);
  if (!context) {
    throw new Error("useDestinationsContext must be used within a DestinationsContextProvider");
  }
  return context;
};

const DestinationContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <DestinationContext.Provider
      value={{
        ...useDestination(),
      }}
    >
      {children}
    </DestinationContext.Provider>
  );
};

export default DestinationContextProvider;
