import { useEffect, useState } from "react";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { DropdownOption } from "@/types";
import clsx from "clsx";
import { IconX } from "@tabler/icons-react";

export default function AutocompleteDropdown({
  classes,
  options,
  setValue: onSetValue,
  value: defaultValue,
}: {
  classes?: {
    root?: string;
    input?: string;
  };
  value: DropdownOption;
  options: DropdownOption[];
  setValue: (value: DropdownOption) => void;
}) {
  const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>([]);
  const [value, setValue] = useState<DropdownOption>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const search = (event: AutoCompleteCompleteEvent) => {
    let _filteredOptions;
    if (!event.query.trim().length) {
      _filteredOptions = [...options];
    } else {
      _filteredOptions = options.filter((option) => {
        return option.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setFilteredOptions(_filteredOptions);
  };

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={clsx("card flex items-center justify-center gap-0", classes?.root)}
    >
      <AutoComplete
        placeholder="Select channel"
        className={classes?.input}
        value={value}
        title="Select channel"
        suggestions={filteredOptions}
        completeMethod={search}
        onChange={(e) => setValue(e?.value)}
        onSelect={(e) => {
          onSetValue(e.value);
        }}
        dropdown
        field="name"
      />
      <IconX
        onClick={() => {
          setValue({ id: "", name: "" });
          onSetValue({ id: "", name: "" });
        }}
        className="cursor-pointer"
      />
    </div>
  );
}
