import React, { createContext, useEffect, useState } from "react";
import SlackApiKey from "./SlackApiKey";
import { Button } from "primereact/button";
import Modal from "./Modal";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";
import SlackChannelsAndNotificationTime from "./SlackChannelsAndNotificationTime";
import Bamboo from "./Bamboo";
import Success from "./Success";
import { useUserContext } from "@/containers/Users/UserContext";
import SlackIntegration from "./SlackIntegration";

interface Context {
  visible: boolean;
  onClose: () => void;
  onNextStep: (cb?: () => Promise<void>) => Promise<void>;
  onPrevStep: () => void;
  step: number;
  setModelTitle: (title: string) => void;
  isFinalStep?: boolean;
  isFirstStep?: boolean;
}

export const context = createContext<Context>({
  setModelTitle: () => null,
  onNextStep: () => Promise.resolve(),
  onPrevStep: () => null,
  onClose: () => null,
  step: 0,
  visible: false,
  isFinalStep: false,
});

const ContextProvider = ({
  children,
  onClose,
  visible,
  onNextStep,
  onPrevStep,
  step,
  isFinalStep,
  isFirstStep,
}: {
  children: React.ReactNode;
} & Omit<Context, "setModelTitle">) => {
  const [modelTitle, setModelTitle] = useState("");
  return (
    <context.Provider
      value={{
        visible,
        setModelTitle,
        onNextStep,
        onPrevStep,
        onClose,
        step,
        isFinalStep,
        isFirstStep,
      }}
    >
      <Modal title={modelTitle} visible={visible} onClose={onClose} className="!h-[485px] !w-[640px]">
        {children}
      </Modal>
    </context.Provider>
  );
};

export default function SetupWizard({ initStep = 0 }: { initStep?: number }) {
  const [isSetupWizard, setIsSetupWizard] = useState(initStep > 0);
  const { user, getUserData } = useUserContext();
  const [step, setStep] = useState(user?.isSlackAvailable ? 1 : initStep);

  const handleSetupWizardOpen = () => {
    setIsSetupWizard(true);
  };
  const handleSetupWizardClose = () => {
    setIsSetupWizard(false);
  };

  const finalStep = 4;

  const handleNextStep = async (cb?: () => Promise<void>) => {
    if (step === finalStep) return;
    try {
      await cb?.();
      setStep((prev) => prev + 1);
    } catch (error) {
      toast.error(errorOrDefault(error, "Something went wrong"));
    }
  };
  const handlePreviousStep = () => {
    if (step === 0) return;
    setStep((prev) => prev - 1);
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStep(user?.isSlackAvailable ? 1 : initStep);
  }, [initStep, isSetupWizard, user?.isSlackAvailable]);

  return (
    <>
      <ContextProvider
        step={step}
        onClose={handleSetupWizardClose}
        visible={isSetupWizard}
        onNextStep={handleNextStep}
        onPrevStep={handlePreviousStep}
        isFinalStep={step === finalStep}
        isFirstStep={step === 0}
      >
        {step === 0 && <SlackApiKey />}
        {step === 1 && <SlackChannelsAndNotificationTime />}
        {step === 2 && <SlackIntegration />}
        {step === 3 && <Bamboo />}
        {step === 4 && <Success />}
      </ContextProvider>

      <Button label="Setup JoeyBot" onClick={handleSetupWizardOpen} />
    </>
  );
}
