import { classNames } from "primereact/utils";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AppTopMenu from "../AppTopMenu/AppTopMenu";
import "./Layout.scss";
import { useThemeContext } from "../../../theme/ThemeContext";
import { useUserContext } from "../../Users/UserContext";
import BottomBgElemSmall from "./bottomBgElemSmall.svg?react";

const LayoutApp = () => {
  const { user, isUserDataFetching } = useUserContext();
  const { sidebarIsOpen, toggleSidebar } = useThemeContext();
  const location = useLocation();

  useEffect(() => {
    if (sidebarIsOpen) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, sidebarIsOpen]);

  if (!isUserDataFetching && !user) {
    return <Navigate to={"auth"} state={{ returnUrl: location.pathname }} replace />;
  }

  return (
    <>
      <div className={classNames("relative main-app z-10", { sidebarIsOpen })}>
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="h-4rem z-200 fixed w-full"
        >
          <AppTopMenu />
        </div>
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="main-app-content min-h-screen p-4 pt-8"
        >
          <Outlet />
        </div>
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="layout-mask fixed left-0 top-0 size-full"
          onClick={toggleSidebar}
        ></div>
      </div>
      <BottomBgElemSmall className="pointer-events-none fixed bottom-0 right-0 z-0" />
    </>
  );
};

export default LayoutApp;
