import { SetStateAction, useMemo, useState } from "react";
import NotificationsService from "./api";
import { JNotification, JNotificationPatchBody } from "./types";

const useNotifications = () => {
  const [notifications, setNotifications] = useState<{ data: JNotification[] } | null>(null);
  const [status, setStatus] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getNotifications = async () => {
    try {
      setIsFetching(true);
      const response = await NotificationsService.get();
      setNotifications(response as SetStateAction<{ data: JNotification[] } | null>);
    } finally {
      setIsFetching(false);
    }
  };

  const getNotificationStatus = async () => {
    try {
      setIsFetching(true);
      const response = await NotificationsService.getNotificationStatus();
      setStatus(response?.data);
    } finally {
      setIsFetching(false);
    }
  };

  const setNotificationStatus = async (status: boolean) => {
    try {
      setIsFetching(true);
      await NotificationsService.setNotificationStatus(status);
      setStatus(status);
    } finally {
      setIsFetching(false);
    }
  };

  const setCustomOrgNotificationStatus = async (params: { status: boolean; orgId: string }) => {
    try {
      setIsFetching(true);
      await NotificationsService.setCustomOrgNotificationStatus(params.status, params.orgId);
    } finally {
      setIsFetching(false);
    }
  };

  const cancelNotification = async (id: string, channelId: string) => {
    try {
      setIsFetching(true);
      await NotificationsService.cancelNotification(id, channelId);
    } finally {
      setIsFetching(false);
    }
  };

  const updateNotification = async (id: string, body: JNotificationPatchBody) => {
    try {
      await setIsFetching(true);
      await NotificationsService.updateNotification(id, body);
    } finally {
      setIsFetching(false);
    }
  };

  return useMemo(
    () => ({
      setCustomOrgNotificationStatus,
      notifications,
      getNotifications,
      status,
      getNotificationStatus,
      setNotificationStatus,
      cancelNotification,
      updateNotification,
      isFetching,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, notifications, status]
  );
};

export default useNotifications;
