import React, { createContext, useContext } from "react";
import useDashboard from "./useDashboard";
import { JDashboard } from "./types";

export const DashboardContext = createContext(null as JDashboard | null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboardContext must be used within a DashboardContextProvider");
  }
  return context;
};

const DashboardContextProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <DashboardContext.Provider
      value={{
        ...useDashboard(),
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
