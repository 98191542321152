import { useMemo, useState } from "react";
import EventService from "./api";
import { AddEvent, EventDTO } from "./types";
import { EventType } from "@/types";

const useEvents = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [events, setEvents] = useState<EventDTO[] | null>(null);

  const getEvents = async ({
    globalOnly,
    onSuccess,
    onSetFirstEvent,
  }: {
    globalOnly?: boolean;
    onSuccess?: (events: EventDTO[]) => void;
    onSetFirstEvent?: (e: string) => void;
  } = {}) => {
    try {
      setEvents([]);
      setIsFetching(true);
      const response = globalOnly ? await EventService.getAllGlobal() : await EventService.getAll();
      setEvents([...response.data]);
      onSuccess && onSuccess([...response.data]);
      onSetFirstEvent && response?.data?.[0]?.id && onSetFirstEvent(response?.data?.[0]?.id);
    } finally {
      setIsFetching(false);
    }
  };

  const addEvent = async (event: AddEvent) => {
    try {
      setIsFetching(true);
      const response = await EventService.add(event);
      setEvents((events) => [...(events || []), response.data]);
    } finally {
      setIsFetching(false);
    }
  };

  const updateEvent = async (id: string, event: Partial<EventDTO>) => {
    try {
      setIsFetching(true);
      const response = await EventService.update(id, event);
      setEvents((events) => [...(events || [])].map((e) => (e.id === response.data.id ? response.data : e)));
    } finally {
      setIsFetching(false);
    }
  };

  const deleteEvent = async (id: string) => {
    try {
      setIsFetching(true);
      await EventService.delete(id);
      setEvents((events) => [...(events || [])].filter((e) => e.id !== id));
    } finally {
      setIsFetching(false);
    }
  };

  return useMemo(
    () => ({
      getEvents,
      isEventsFetching: isFetching,
      events,
      eventsGlobal: events?.filter((e) => e.type === EventType.GLOBAL) || [],
      addEvent,
      updateEvent,
      deleteEvent,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, events]
  );
};

export default useEvents;
