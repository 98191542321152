import React, { createContext, useContext } from "react";
import useNotifications from "./useNotification";
import { JNotifications } from "./types";

export const NotificationContext = createContext<JNotifications | null>(null);

export const useNotificationsContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotificationContext must be used within a NotificationContextProvider");
  }
  return context;
};

const NotificationContextProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <NotificationContext.Provider
      value={{
        ...useNotifications(),
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
