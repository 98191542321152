import PageTitleWrapper from "@/components/PageTitleWrapper";
import { useUserContext } from "../Users/UserContext";
import { Navigate } from "react-router-dom";
import HealthCheckList from "./HealthCheckList";
import { useState } from "react";
import { ModalOperationType } from "@/types";
import useHealthCheckContext from "./useHealthCheck";
import { HealthCheckCreate } from "./HealthCheckCreate";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const HealthCheck = () => {
  const { orgSettings, isChannelManager } = useUserContext();
  const [showCreateModal, setShowCreateModal] = useState<ModalOperationType>(null);
  const { getAllHealthChecks } = useHealthCheckContext();

  const handleCreateModalClose = () => {
    setShowCreateModal(null);
    getAllHealthChecks();
  };

  if (isChannelManager || orgSettings.feature_flag_health_check === false) {
    return <Navigate to="/" />;
  }

  return (
    <div className="text-white">
      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Health Check</span>
          </>
        }
        row2={
          <Button
            label="Add new health check"
            icon="pi pi-plus"
            onClick={() => {
              setShowCreateModal("create");
            }}
            className="ml-5"
          />
        }
      />
      <HealthCheckList />
      {showCreateModal === "create" && (
        <Dialog header="Create Health Check" visible onHide={handleCreateModalClose}>
          <HealthCheckCreate onClose={handleCreateModalClose} />
        </Dialog>
      )}
    </div>
  );
};
