import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import OrgModal from "./OrgModal/OrgModal";
import { Navigate } from "react-router-dom";
import { OrganizationDTO } from "../../Users/types";
import { useUserContext } from "../../Users/UserContext";
import { createdAtCellRender, sortByCreatedAt } from "../../../utils";
import Loader from "@/base/Loader";
import Edit from "@/assets/edit.svg?react";

export type OperationType = "create" | "update" | null;

const OrgList = () => {
  const [showOrgModal, setShowOrgModal] = useState<OperationType>(null);
  const [curOrg, setCurOrg] = useState<OrganizationDTO | null>(null);

  const { getOrgs, orgs, isOrgsFetching, isSuperAdmin } = useUserContext();

  useEffect(() => {
    getOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operationsCellRender = (rawData: OrganizationDTO) => (
    <>
      <Edit
        className="cursor-pointer"
        onClick={() => {
          setCurOrg(rawData);
          setShowOrgModal("update");
        }}
      ></Edit>
    </>
  );

  if (!isSuperAdmin) {
    return <Navigate to={"/"} state={{ returnUrl: window.location.pathname }} replace />;
  }

  return (
    <div>
      <Loader isLoading={isOrgsFetching} />
      <div className="flex w-full justify-end pb-3">
        <div> </div>
        <Button
          label="Add new organization"
          icon="pi pi-plus"
          onClick={() => {
            setShowOrgModal("create");
          }}
          className="ml-5"
        />
      </div>

      <DataTable<OrganizationDTO[]> value={orgs.sort(sortByCreatedAt<OrganizationDTO>)}>
        <Column field="title" header="Title"></Column>
        <Column field="createdAt" header="Created at" body={createdAtCellRender}></Column>
        {/** <Column field="updatedAt" header="Updated at" body={updatedAtCellRender}></Column> */}
        <Column header="Operations" body={operationsCellRender}></Column>
      </DataTable>

      {showOrgModal && (
        <OrgModal
          onClose={() => {
            setShowOrgModal(null);
            setCurOrg(null);
          }}
          formType={showOrgModal}
          org={curOrg}
        />
      )}
    </div>
  );
};

export default OrgList;
