import { useEffect, useMemo, useState } from "react";
import {
  HealthCheckAnswerOptions,
  HealthCheckAnswerStatus,
  HealthCheckNotificationDetailed,
} from "./HealthCheck.types";
import { moodToEmojiMap } from "./utils";
import useHealthCheckContext from "./useHealthCheck";

interface Props {
  reportData: HealthCheckNotificationDetailed;
}

export default function HealthCheckNotificationsReport({ reportData }: Props) {
  const [notifData, setNotifData] = useState<HealthCheckNotificationDetailed | null>(null);
  const { getHealthCheckNotificationById } = useHealthCheckContext();

  useEffect(() => {
    if (reportData) {
      getHealthCheckNotificationById(reportData.id).then((data) => {
        setNotifData(data);
      });
    }
  }, [reportData, getHealthCheckNotificationById]);

  const [formatHealthCheckReport, recipients, answered] = useMemo(() => {
    if (!notifData) {
      return [{}, 0, 0];
    }

    let report: Record<string, string[]> = {} as Record<HealthCheckAnswerOptions, string[]>;
    let recip = 0;
    let answ = 0;

    if (!notifData.startAt) {
      return [{}, 0, 0];
    }

    // get date rounded to the day
    const date = new Date(notifData.startAt).toLocaleString();

    if (!date) {
      return [{}, 0, 0];
    }

    recip = notifData?.healthCheckAnswers?.length || 0;
    answ = notifData?.healthCheckAnswers?.filter((a) => a.status === HealthCheckAnswerStatus.completed)?.length || 0;
    report = {
      [HealthCheckAnswerOptions.AWFUL]: [],
      [HealthCheckAnswerOptions.BAD]: [],
      [HealthCheckAnswerOptions.NORMAL]: [],
      [HealthCheckAnswerOptions.GOOD]: [],
      [HealthCheckAnswerOptions.AWESOME]: [],
    };

    const answers = notifData.healthCheckAnswers;

    if (!answers) {
      return [{}, 0, 0];
    }

    for (const answer of answers) {
      let answerType: string = answer.answer;
      if (answer.status === HealthCheckAnswerStatus.pending) {
        answerType = "pending";
      }

      if (!report?.[answerType]) {
        report[answerType] = [];
      }

      report[answerType]?.push(answer?.destination?.destinationData?.name);
    }
    return [report, recip, answ];
  }, [notifData]);

  if (!notifData) {
    return null;
  }

  return (
    <div>
      <div key={notifData.createdAt} className="flex flex-col gap-2">
        <div className="text-2xl">
          ✅ {notifData?.healthCheck?.title} 📆 {new Date(notifData?.startAt ?? "").toLocaleString()}
        </div>
        <div>🧑‍🤝‍🧑 Number of recipients: {recipients}</div>
        <div>🔊 Number of responses: {answered} </div>
        <div>
          🤔 Pending responses: {recipients - answered} ({formatHealthCheckReport["pending"]?.join(", ")})
        </div>
        <div>📈 Statistics</div>
        {Object.entries(formatHealthCheckReport).map(([mood, people]) => {
          if (mood === "pending") {
            return null;
          }

          return (
            <div className="flex items-baseline gap-2" key={mood}>
              <span>
                <span style={{ fontSize: `${14 + people.length * 2}px` }}>
                  {moodToEmojiMap[mood as HealthCheckAnswerOptions]}
                </span>{" "}
                ({people.length})
              </span>
              <div>{people.join(", ")}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
